import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {AppContext, DispatchContext, PEOPLE_LIST_FAILURE, PEOPLE_LIST_SUCCESS, SNACKBAR_OPEN} from '../../store';
import {deletePeople, peopleList, userActivation} from '../../utils/api';
import {Box, CircularProgress, createMuiTheme, Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {SearchFilter} from '../shared/search';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PeopleIcon from '@material-ui/icons/People';
import Menu from '@material-ui/core/Menu';
import {useHistory} from "react-router-dom";
import {PersonAdd as PersonAddIcon} from '@material-ui/icons';
import TablePagination from "@material-ui/core/TablePagination";
import Switch from '@material-ui/core/Switch';
import DeleteIcon from "@material-ui/icons/Delete";
import SharedDialog from "../shared/dialog";

const useStyles = makeStyles({
    root: {
        borderRadius: 4,
    },
    headerContent: {
        textAlign: 'left',
        paddingBottom: '24px'
    },
    table: {
        minWidth: 650,
    },
    statusBtn: {
        textAlign: 'right',
        paddingBottom: '16px',
    },
    submit: {
        textAlign: 'right',
        marginTop: '8px'
    },
    title: {
        verticalAlign: 'sub',
        marginRight: '4px',
        marginBottom: 6,
    },
});

const statusFilters = [{filter: '', label: 'All'}, {filter: true, label: 'Active'}, {filter: false, label: 'Inactive'}];

function TabPanel(props) {
    const {children, value, index, activeRole, ...other} = props;
    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `people-tab-${index}`,
        'aria-controls': `people-tabpanel-${index}`,
    };
}


export const PeopleListPagination = ({meta, page, rowsPerPage, handlePageChange, handleRowsPerPageChange}) => {
    return (<TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
    />);
};

export const PeopleList = () => {
    const classes = useStyles();
    const {peopleStatus, people, roles} = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const dispatch = useContext(DispatchContext);
    const anchorRef = React.useRef(null);
    const history = useHistory();
    const [statusFilter, setStatusFilter] = React.useState(1);
    const [activeRole, setActiveRole] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [searching, setSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [activate, setActivate] = useState('false');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [id, setId] = useState('');
    const handleClick = () => {
        console.info(`You clicked ${statusFilters[statusFilter]}`);
    };

    const handleMenuItemClick = (event, index) => {
        setStatusFilter(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        (async function () {
            await fetchPeople()
        })();
    }, [activeRole, statusFilter, searchQuery, page, rowsPerPage, activate]);

    const fetchPeople = () => {
        return peopleList({
            roles: [activeRole],
            status: statusFilters[statusFilter].filter,
            q: searchQuery,
            page,
            per_page: rowsPerPage
        })
    };

    const handleTabChange = (event, newValue) => {
        setPage(1);
        setActiveRole(newValue);
    };

    /*const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };*/

    const handlePush = () => {
        history.push('/invite');
    };

    const searchPeople = (query) => {
        setSearching(true);
        setSearchQuery(query);
    };

    const onPageChange = (event, page) => {
        setPage(page + 1);
    };
    let onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
    };
    const handleActiveChange = async (event, id) => {
        await userActivation({id: id, status: event}).then(_ => {
            setActivate(event);
        });
    };
    const handleDelete = async (result, event) => {
        setConfirmationOpen(false);
        if (result) {
            await deletePeople(id).then(response => {
                fetchPeople();
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'User deleted successfully'}});
            }).catch(err => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };
    const setDelete = (id) => {
        setId(id);
        setConfirmationOpen(true);
    };

    const userDetail = (id) => {
        history.push(`/profile/${id}`);
    };

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#2C3E50"
            },
        },
    });
    const themee = createMuiTheme({
        palette: {
            primary: {
                main: "#2C3E50"
            },
        },
    });

    let View;
    switch (peopleStatus) {
        case  PEOPLE_LIST_FAILURE:
            View = <Box minHeight={200}><Typography variant='h4'>Failed to load people</Typography></Box>;
            break;
        case PEOPLE_LIST_SUCCESS:

            View = (
                <div> {!!roles.length ? (
                    <ThemeProvider theme={themee}>
                        <Tabs indicatorColor='primary' textColor='primary' value={activeRole}
                              onChange={handleTabChange}>
                            {
                                roles.map((role, index) => (
                                    <Tab label={role.name} {...a11yProps(index)} key={index} value={role.id}/>
                                ))
                            }
                        </Tabs>
                    </ThemeProvider>
                ) : (<CircularProgress/>)}

                    {roles.map((role, index) => (
                        <TabPanel value={role.id} index={activeRole} key={index}>
                            <Grid container direction='column'>
                                <Grid item xs={12} className={classes.statusBtn}>
                                    <ButtonGroup ref={anchorRef}
                                                 aria-label='split button'
                                    >
                                        <Button onClick={handleClick}
                                                style={{backgroundColor: "#2C3E50", color: "white"}}
                                        >{statusFilters[statusFilter].label}</Button>
                                        <Button
                                            color='primary'
                                            size='small'
                                            aria-controls={open ? 'split-button-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-label='select merge strategy'
                                            aria-haspopup='menu'
                                            onClick={handleToggle}
                                            className="background-header"
                                        >
                                            <ArrowDropDownIcon/>
                                        </Button>
                                    </ButtonGroup>
                                    <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose}>
                                        {statusFilters.map((option, index) => (
                                            <MenuItem
                                                key={option.label}
                                                disabled={index === statusFilter}
                                                selected={index === statusFilter}
                                                onClick={event => handleMenuItemClick(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table className={classes.table} aria-label='simple table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                {/*{['Parent', 'Staff'].includes(role.name) &&
                                                <TableCell align="left">Designation</TableCell>}*/}
                                                {/*{['Teacher', 'Staff'].includes(role.name) &&
                                                <TableCell align="left">Department</TableCell>}*/}
                                                <TableCell align="left">Email</TableCell>
                                                <TableCell align="left">Phone</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Action</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        <TableBody>
                                            {people.data.length ? people.data.map(({
                                                                                       id,
                                                                                       email,
                                                                                       active,
                                                                                       profile_attributes
                                                                                   }) => (
                                                <TableRow key={id} className="hand-cursor">
                                                    <TableCell component="th" scope="row"
                                                               onClick={() => userDetail(id)}>
                                                        {profile_attributes && profile_attributes.contact_person_attributes
                                                        && profile_attributes.contact_person_attributes.first_name} {profile_attributes && profile_attributes.contact_person_attributes && profile_attributes.contact_person_attributes.last_name}
                                                    </TableCell>
                                                    {/*{['Parent', 'Staff'].includes(role.name) &&
                                                    <TableCell
                                                        align="left">{contact_person_attributes.designation}</TableCell>}*/}
                                                    {/*{['Teacher'].includes(role.name) &&
                                                    <TableCell
                                                        align="left"
                                                        onClick={() => userDetail(id)}>{profile_attributes && profile_attributes.department_name}</TableCell>}*/}
                                                    <TableCell align="left"
                                                               onClick={() => userDetail(id)}>{email}</TableCell>
                                                    <TableCell onClick={() => userDetail(id)}
                                                               align="left">{profile_attributes && profile_attributes.contact_person_attributes && profile_attributes.contact_person_attributes.phone}</TableCell>
                                                    <TableCell align="left">
                                                        <ThemeProvider theme={theme}>
                                                            <Switch
                                                                checked={active}
                                                                onChange={(event) => handleActiveChange(event.target.checked, id)}
                                                                color={active ? "primary" : "secondary"}
                                                                inputProps={{'aria-label': 'active'}}
                                                            />
                                                        </ThemeProvider>
                                                        {active ? 'Active' : 'Inactive'}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Button aria-label='delete'
                                                                color='secondary'
                                                                onClick={() => setDelete(id)}
                                                                variant="outlined">
                                                            <DeleteIcon/>Remove
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )) : (<TableRow>
                                                <TableCell component="th" scope="row" colSpan={4}>
                                                    <Typography align={"center"} variant={"h6"} component="div">
                                                        No data to display
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>)}
                                        </TableBody>

                                    </Table>
                                    {!!people.data.length && <PeopleListPagination handlePageChange={onPageChange}
                                                                                   page={page}
                                                                                   meta={people.meta}
                                                                                   rowsPerPage={rowsPerPage}
                                                                                   handleRowsPerPageChange={onRowsPerPageChange}
                                    />}
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))
                    }
                    <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>

                </div>
            );
            break;
        default:
            View = <CircularProgress/>
    }
    return (
        <div style={{marginTop: 70}}>
            <Grid container className={classes.headerContent}>
                <Grid item xs={12} className="mt-2">
                    <h2><PeopleIcon className={classes.title}/>People</h2>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <SearchFilter callback={searchPeople}/>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.submit}>
                    <Button variant='contained'
                            color='primary'
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            onClick={() => handlePush()} startIcon={<PersonAddIcon/>}
                            className="background-header"
                    >
                        Invite
                    </Button>
                    {/*<Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        {
                            roles.map((role, index) => (
                                <MenuItem key={index} component={Link}
                                          to={`/invite?role=${role.name}`}>{role.name}</MenuItem>
                            ))
                        }
                    </Menu>*/}
                </Grid>
            </Grid>
            <Paper elevation={0} className={classes.root}>
                {View}
            </Paper>
        </div>
    );
};
