import React from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from '@material-ui/core/FormGroup';
import {Form, Formik} from "formik";
import Divider from "@material-ui/core/Divider";
import ChipInput from 'material-ui-chip-input'
import Button from "@material-ui/core/Button";

export const CreateSubject = () => {

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });

    const handleChange = name => event => {
        setState({...state, [name]: event.target.checked});
    };
    return (
        <div>
            <h3 style={{fontSize: 30, textAlign: 'left', marginTop: 50, marginBottom: 50}}>Create Subject</h3>
            <Formik>
                <Form style={{marginTop: 50}}>
                    <FormGroup row>
                        <Grid container>
                            <Grid item xs={12} md={12} style={{textAlign: 'left'}}>
                                <ChipInput
                                    defaultValue={['English', 'Dzongkha', 'Mathematics']}
                                    onChange={(chips) => handleChange(chips)}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Button variant="contained" color='primary' style={{marginTop: 40, marginBottom: 10}}>Submit</Button>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </Form>
            </Formik>
        </div>
    );
};



