import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme =>({
    root: {
        textAlign: 'left',
        paddingBottom: '24px'
    },
    title: {
        verticalAlign: 'sub',
        marginRight: '4px'
    },
    pullRight: {
        float: 'right'
    },
    btnMargin: {
        marginLeft: '12px',
        marginTop: '16px',
        marginBottom: '16px'
    },
    addQualification: {
        marginTop: '16px',
        marginBottom: '16px'
    },
    marginTopBottom: {
        marginTop: '0',
        marginBottom: '4px'
    },
    fullWidth: {
        width: '100%'
    },
    search: {
        background: 'white',
        width: 'inherit'
    },
    searchWrapper: {
        position: 'relative',
        width: '100%'
    },
    searchFilterBox: {
        position: 'absolute',
        left: 0,
        top: '60px',
        background: 'white',
        boxShadow: '0px 1px 6px -2px rgba(0, 0, 0, 0.4)',
        borderRadius: '4px',
        padding: '16px',
        zIndex: '2',
        width: 'inherit'
    },
    studentList: {
        padding: '12px 4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(0, 0, 0, 0.02)'
        },
        margin: '0 0 12px'
    },
    studentSelected: {
        backgroundColor: 'rgb(0, 0, 0, 0.02)'
    },
    formControl: {
        minWidth: 120,
        width: '100%'
    }
}));

export default useStyles;