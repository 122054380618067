import EventDetail from "../pages/event/event-detail/event-detail";
import EventCreate from "../pages/event/event-create/event-create";
import PeopleCreate from "../pages/people/create/create";
import {CreateClass} from "../pages/grade/create/create-class";
import {CreateSubject} from "../pages/subjects/create/create-subjects";
import {Assignment} from "../pages/people/student/assignment/student-assignment";
import {AssignmentDetail} from "../pages/people/student/assignment/detail/detail";
import {SubjectTeacher} from "../pages/people/teacher/assign-subject/subject-teacher";
import {TeacherAssignment} from "../pages/people/teacher/assignments/dashboard/teacher-dashboard";
import {AssignmentTeacher} from "../pages/people/teacher/assignments/assignment-teacher";
import {AssignHomework} from "../pages/people/teacher/homework/homework";
import AssignmentPost from "../pages/people/teacher/posted-work/assignment";
import InvitationAll from "../pages/people/create/invitation/invitation";
import HomeworkPost from "../pages/people/teacher/posted-work/homework";
import PastAssignment from "../pages/people/student/assigned-task/assignment";
import PastHomework from "../pages/people/student/assigned-task/homework";
import CreateDepartment from "../pages/preferences/department/create";
import CreateModule from "../pages/preferences/subject/create";
import Role from "../pages/preferences/role/create";
import {AssignSubject} from "../pages/grade/create/assigning-subject";
import {TeachersAssignmentDetail} from "../pages/people/teacher/posted-work/details/assignment-detail";
import TeachersHomeworkDetail from "../pages/people/teacher/posted-work/details/homework-detail";
import StudentHomeworkDetail from "../pages/people/student/assignment/homework/homework-detail";
import StudentAssignmentDetail from "../pages/people/student/assignment/detail/assignment-detail";
import ClosedAssignment from "../pages/people/teacher/assignments/dashboard/closed";
import {StudentWork} from "../pages/people/teacher/students-work/students-work";
import {StudentHomeworkList} from "../pages/people/teacher/students-work/student-homework";
import ClosedHomework from "../pages/people/teacher/homework/closed";
import {AnswerForm} from "../pages/people/student/assignment/answer-form";
import Current from "../pages/people/teacher/assignments/dashboard/current";
import HomeworkCurrent from "../pages/people/teacher/homework/current";
import {Subjects} from "../pages/people/student/subject/subject";
import CurrentStudent from "../pages/people/student/assignment/current";
import {CurrentStudentHomework} from "../pages/people/student/assignment/homework/current";
import {SectionSubjects} from "../pages/grade/sections/sections";

export const Routes = [
    {
        primaryText: "Event Details",
        icon: "event",
        path: '/events/details/:id',
        visible: true,
        authorized: ['Admin', 'Student', 'Teacher', 'Staff', 'Parent', 'Principal'],
        component: EventDetail
    },
    {
        primaryText: "Event Create",
        icon: "event",
        path: '/events/new',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: EventCreate
    },
    {
        primaryText: "Edit event",
        icon: "event",
        path: '/events/edit/:id',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: EventCreate
    },
    {
        primaryText: "Event Categories",
        icon: "event",
        path: '/event-categories',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: EventCreate
    },

    {
        primaryText: "Event Categories Create",
        icon: "event",
        path: '/event-categories/create',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: EventCreate
    },

    {
        primaryText: "People Invite",
        icon: "people",
        path: '/people/create',
        visible: true,
        authorized: ['Admin'],
        component: PeopleCreate
    },
    {
        primaryText: "Create Class",
        icon: "classroom",
        path: '/grade/create',
        visible: true,
        authorized: ['Admin'],
        component: CreateClass
    },
    {
        primaryText: "Create Subject",
        icon: "classroom",
        path: '/subjects/create',
        visible: true,
        authorized: ['Admin'],
        component: CreateSubject
    },
    {
        primaryText: "Invite",
        icon: "people",
        path: '/invite',
        visible: true,
        authorized: ['Teacher', 'Admin'],
        component: InvitationAll
    },
    {
        primaryText: "Assignment",
        icon: "assignment",
        path: '/assignment/:id',
        visible: true,
        authorized: ['Teacher', 'Admin', 'Student', 'Parent'],
        component: Assignment
    },
    {
        primaryText: "StudentWork",
        icon: "assignment",
        path: '/student-work/:id/:assignId',
        visible: true,
        authorized: ['Teacher', 'Admin'],
        component: StudentWork
    },
    {
        primaryText: "StudentHomeWork",
        icon: "assignment",
        path: '/student-homework/:id/:homeId',
        visible: true,
        authorized: ['Teacher', 'Admin'],
        component: StudentHomeworkList
    },
    {
        primaryText: "Assignment",
        icon: "assignment",
        path: '/detail',
        visible: true,
        authorized: ['Teacher', 'Admin', 'Student', 'Parent'],
        component: AssignmentDetail
    },
    {
        primaryText: "subjectTeacher",
        icon: "people",
        path: '/assign',
        visible: true,
        authorized: ['Teacher', 'Admin'],
        component: SubjectTeacher
    },
    {
        primaryText: "TeacherAssignment",
        icon: "assignment",
        path: '/assign-detail/:id',
        visible: true,
        authorized: ['Teacher', 'Admin'],
        component: TeacherAssignment
    },
    {
        primaryText: "Assignments",
        icon: "assignment",
        path: '/assigning-task/:id',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: AssignmentTeacher

    },
    {
        primaryText: "EditingAssignments",
        icon: "assignment",
        path: '/assigning-task/:id/:assignId',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: AssignmentTeacher

    },
    {
        primaryText: "AssignHomework",
        icon: "assignment",
        path: '/homework',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: AssignHomework

    },
    {
        primaryText: "AssignmentPosted",
        icon: "assignment",
        path: '/posted-assignment',
        visible: true,
        authorized: ['Admin', 'Teacher', 'Student', 'Parent'],
        component: AssignmentPost
    },
    {
        primaryText: "HomeworkPosted",
        icon: "assignment",
        path: '/posted-homework',
        visible: true,
        authorized: ['Admin', 'Teacher', 'Student', 'Parent'],
        component: HomeworkPost
    },
    {
        primaryText: "PastAssignment",
        icon: "assignment",
        path: '/past-assignment',
        visible: true,
        authorized: ['Admin', 'Teacher', 'Student', 'Parent'],
        component: PastAssignment
    },
    {
        primaryText: "PastAssignment",
        icon: "assignment",
        path: '/teachers-assignment',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: TeachersAssignmentDetail
    },
    {
        primaryText: "currentAssignment",
        icon: "assignment",
        path: '/current-assignment/:subId/:assignId',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: Current
    },
    {
        primaryText: "currentHomework",
        icon: "assignment",
        path: '/current-homework/:subId/:homeId',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: HomeworkCurrent
    },
    {
        primaryText: "currentStudentAssignment",
        icon: "assignment",
        path: '/current-student-assignment/:id/:assignId',
        visible: true,
        authorized: ['Admin', 'Student', 'Parent'],
        component: CurrentStudent
    },
    {
        primaryText: "currentStudentHomework",
        icon: "assignment",
        path: '/current-student-homework/:id/:homeId',
        visible: true,
        authorized: ['Admin', 'Student', 'Parent'],
        component: CurrentStudentHomework
    },
    {
        primaryText: "PastHomework",
        icon: "assignment",
        path: '/teachers-homework',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: TeachersHomeworkDetail
    },
    {
        primaryText: "AssignedAssignment",
        icon: "assignment",
        path: '/assigned-assignment/:id',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: ClosedAssignment
    },
    {
        primaryText: "AssignedHomework",
        icon: "assignment",
        path: '/assigned-homework/:id',
        visible: true,
        authorized: ['Admin', 'Teacher'],
        component: ClosedHomework
    },
    {
        primaryText: "Answerform",
        icon: "assignment",
        path: '/answer/:subId/:id',
        visible: true,
        authorized: ['Admin', 'Student'],
        component: AnswerForm
    },
    {
        primaryText: "AnswerformEdit",
        icon: "assignment",
        path: '/answer/edit/:subId/:id',
        visible: true,
        authorized: ['Admin', 'Student'],
        component: AnswerForm
    },
    {
        primaryText: "PastStudentHomework",
        icon: "assignment",
        path: '/homework-detail/:id/:homeId',
        visible: true,
        authorized: ['Admin', 'Student', 'Parent'],
        component: StudentHomeworkDetail
    },
    {
        primaryText: "PastStudentAssignment",
        icon: "assignment",
        path: '/student-assignment/:id/:assignId',
        visible: true,
        authorized: ['Admin', 'Student', 'Parent'],
        component: StudentAssignmentDetail
    },
    {
        primaryText: "PastHomework",
        icon: "assignment",
        path: '/past-homework',
        visible: true,
        authorized: ['Admin', 'Teacher', 'Student', 'Parent'],
        component: PastHomework
    },
    {
        primaryText: "CreateDepartment",
        icon: "assignment",
        path: '/create',
        visible: true,
        authorized: ['Admin'],
        component: CreateDepartment
    },
    {
        primaryText: "CreateSubject",
        icon: "assignment",
        path: '/create-subject',
        visible: true,
        authorized: ['Admin'],
        component: CreateModule
    },
    {
        primaryText: "CreateRole",
        icon: "assignment",
        path: '/role-create',
        visible: true,
        authorized: ['Admin'],
        component: Role
    },
    {
        primaryText: "AssignSubject",
        icon: "assignment",
        path: '/assign-subject',
        visible: true,
        authorized: ['Admin'],
        component: AssignSubject
    },
    {
        primaryText: "Subjects",
        icon: "assignment",
        path: '/subject/:id/:secId',
        visible: true,
        authorized: ['Student', 'Parent'],
        component: Subjects
    },
    {
        primaryText: "SectionSubjects",
        icon: "assignment",
        path: '/section-subjects/:id',
        visible: true,
        authorized: ['Admin'],
        component: SectionSubjects
    },
];
