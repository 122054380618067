import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Members from "../../posted-work/assigned-task/assigned";
import TeacherTab from "./teacher";
import TeacherHomeworkTab from "../../homework/homework-teacher";
import {useParams} from "react-router";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
}));

export const TeacherAssignment = () => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    return (
        <div className={classes.root} style={{marginTop: 70}}>
            <Grid item lg={2} style={{marginTop: 30}} className="ml-0">
                <Button>
                    <Tooltip title="Back">
                        <ArrowBackIcon onClick={() => history.goBack()}/>
                    </Tooltip>
                </Button>
            </Grid>
            <h4 className="mb-4 mt-4 ml-3 pull-left">My Dashboard</h4>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{backgroundColor: 'white'}}
                >
                    <Tab label="Assignment" {...a11yProps(0)} />
                    <Tab label="Homework" {...a11yProps(1)} />
                    <Tab label="Members" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <TeacherTab/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <TeacherHomeworkTab/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Members/>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
};
export default TeacherAssignment

