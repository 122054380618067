import React, {Suspense, useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {eventDetails, eventList, eventParticipants, participantsActions} from "../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import RenderAuthorized from "../../../components/render_authorized";
import {Link, useHistory} from "react-router-dom";
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../../store";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import useStyles from "../styles";
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Spots from "../components/spots";
import FormattedTime from "../components/formatted-time";
import noop from "lodash-es/noop";

const ConfirmDialog = React.lazy(() => import('../../../components/confirm-dialog'));

const JoinButton = ({event, callback}) => {
    const [booked, setBooked] = useState(false);
    const {user} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const dispatch = useContext(DispatchContext);
    const [participant, setParticipant] = useState(null);
    useEffect(() => {
        const handler = setTimeout(fetchParticipants, 1000);
        return () => {
            clearTimeout(handler)
        }
    }, []);

    const fetchParticipants = () => {
        (async function fetchData() {
            const {participants} = await eventParticipants(event.id);
            setParticipant(prevState => {
                const foundParticipant = participants.find(participant => participant.user_id === user.id);
                setBooked(!!foundParticipant);
                return foundParticipant;
            });

        })()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        try {
            setOpen(false);
            const {participant: result} = await participantsActions(event.id, {
                id: participant && participant.id,
                participant: {user_id: user.id}
            });
            setParticipant(_ => !booked ? result : null);
            setBooked(!booked);

            dispatch({type: SNACKBAR_OPEN, payload: {message: !booked ? 'Joined event' : 'Cancelled booking'}});
            callback(true);
        } catch ({errors}) {
            setOpen(false);
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: (errors && errors[0]) || 'We are facing an issue!', severity: 'error'}
            });
            callback(false);
        }
    };


    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                    Yes
                </Button>
                <Button onClick={handleClose} color="primary" variant={"outlined"} autoFocus>
                    No
                </Button>
            </DialogActions>
        </Dialog>
        {booked !== null ?
            <Button variant="contained" color="primary" fullWidth={true} onClick={() => setOpen(true)}>
                {booked ? 'Cancel booking' : 'Join event'}
            </Button> :
            <Grid container justify={"center"}><CircularProgress size={20}/></Grid>}
    </>);
};


export const ActionMenu = ({callback, eventId}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const options = [{
        label: 'Edit', action: 'edit'
    }, {label: 'Delete', action: 'delete'}
    ];
    const dispatch = useContext(DispatchContext);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const handleMenuOpen = (event, ...rest) => {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        if (event) {
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
        }
        setAnchorEl(null);
    };

    const handleDelete = async (result, event) => {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        setIsConfirmDialogOpen(false);
        if (result) {
            await eventDetails('delete', eventId);
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Event deleted'}});
            callback(true, 'delete');
        }
    };

    const handleAction = async (event, action) => {
        handleClose();
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        switch (action) {
            case 'delete':
                try {
                    setIsConfirmDialogOpen(true);

                } catch (e) {
                    dispatch({type: SNACKBAR_OPEN, payload: {message: 'Event delete failed', severity: 'error'}});
                    callback(false, action);
                }
                break;
            case 'edit':
                callback(true, action);
                break;
            default:
                return;
        }
    };


    return (<>
        <Suspense fallback={<CircularProgress/>}>
            <ConfirmDialog open={isConfirmDialogOpen} handleAction={handleDelete}/>
        </Suspense>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className={classes.pullRight}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            variant={"menu"}
            keepMounted={false}
        >
            {
                options.map((option, index) =>
                    <MenuItem key={index}
                              onClick={(event) => handleAction(event, option.action)}>{option.label}</MenuItem>)
            }
        </Menu></>)
};

export default function EventList() {
    const classes = useStyles();
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searching, setSearching] = useState(false);
    const dispatch = useContext(DispatchContext);

    useEffect(() => {
        (async function () {
            await fetchEvents();
            setSearching(false);
        })();
    }, [searchQuery]);

    const searchEvents = (event) => {
        setSearchQuery(event.target.value);
        setSearching(true);
    };


    const fetchEvents = () => {
        eventList(null, null, {q: searchQuery, page: 1}).then(response => setEvents(response.data['events']))
    };


    const handleActionCallbacks = (result, action, eventId) => {
        if (!result) return;
        switch (action) {
            case 'delete':
                setEvents(prevState => {
                    const newState = [...prevState];
                    newState.splice(newState.findIndex(evt => evt.id === eventId), 1);
                    return newState;
                });
                break;
            case 'edit':
                history.push(`/events/edit/${eventId}`);
                break;
            default:
                return;
        }
    };


    const eventJoinCallback = async (result, eventId) => {
        if (result) {
            const {event: updatedEvent} = await eventDetails(null, eventId);
            setEvents(prevState => {
                const newState = [...prevState];
                const existingEventIndex = newState.findIndex(evt => evt.id === eventId);
                existingEventIndex ? newState[existingEventIndex] = updatedEvent : noop();
                return newState;
            })
        }

    };
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className="mt-2">
                    <h2 style={{backgroundColor: '#03a9fc'}}><EventIcon className={classes.title}/>Event</h2>
                </Grid>
                <Grid item md={6} xs={12}>
                    {/*Search field to be used from Upali*/}
                    <FormControl fullWidth className={classes.search} variant="outlined">
                        <OutlinedInput onChange={searchEvents}
                                       id="outlined-adornment-amount"
                                       placeholder={'Search'}
                                       startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <RenderAuthorized authorized={['Admin', 'Teacher']}>
                        <Button variant='contained' style={{color: 'white', backgroundColor: '#03a9fc'}}
                                className={classes.pullRight} component={Link}
                                to='/events/new'>
                            <AddIcon/> ADD
                        </Button>
                    </RenderAuthorized>
                </Grid>
            </Grid>
            {
                searching && <Grid item> <CircularProgress/></Grid>
            }
            <Paper elevation={0} className={classes.Paper}>
                <Grid container spacing={3}>
                    {events.map((evt, index) =>
                        (<Grid item md={4} xs={12} key={index}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} disableRipple to={{
                                    pathname: `/events/details/${evt.id}`,
                                    state: {...evt, fromList: true},
                                }}>
                                    <CardMedia
                                        className={classes.media}
                                        component='img'
                                        alt={evt.title}
                                        height='140'
                                        image={(evt.photos_attributes.length && evt.photos_attributes[0].medium) || '/event-image.jpg'}
                                        title={evt.title}
                                    />
                                    <CardContent>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={9}>
                                                <Typography component="h2">
                                                    {evt.title}
                                                </Typography>
                                            </Grid>
                                            <RenderAuthorized authorized={['Admin', 'Teacher']}>
                                                <Grid item xs={3}>
                                                    <ActionMenu eventId={evt.id}
                                                                callback={(result, action) => handleActionCallbacks(result, action, evt.id)}/>
                                                </Grid>
                                            </RenderAuthorized>
                                        </Grid>
                                        <div>
                                            <Grid container alignItems="center" justify="center">
                                                <Grid item xs={7}>
                                                    <Typography component="h2">
                                                        <Spots event={evt}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Chip
                                                        className={classes.pullRight}
                                                        label={evt.event_category.name}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div>
                                            <div><AccessTimeIcon className={classes.verMiddle}/>
                                                <FormattedTime {...evt}/>
                                            </div>
                                            <p><LocationOnIcon className={classes.verMiddle}/>{evt.location}</p>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <JoinButton className="ml-0" event={evt} callback={(result) => eventJoinCallback(result, evt.id)}/>
                                </CardActions>
                            </Card>
                        </Grid>)
                    )}
                    {!searching && !events.length &&
                    <Typography variant="h5" align={"center"}>There are not events.</Typography>}
                </Grid>
            </Paper>
        </div>
    );
}
