import React from 'react';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "left",
        marginTop: 30,
        fontSize: 18,
    },
}));

const OurHistory = () => {
    const classes = useStyles();

    return (
        <div className="overview-types">
            <h4 className="pull-left mt-2 mb-1 light-green" style={{fontSize: 27}}>Our History</h4>
            <Grid container>
                <Grid item xs={12}>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2001
                    </h6>
                    <p className={classes.typography}>
                        Druk School (Pvt.) was established in the year 2001 by Aum Lhaden Pem, the proprietor, along
                        with Mrs. Carolyn Tshering as the Head Teacher. The school is centrally located in Thimphu city,
                        just above the Jigme Dorji Wangchuk National Referral Hospital, on 3.5 acres of land belonging
                        to the proprietor. The school started off as a Primary school.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2003
                    </h6>
                    <p className={classes.typography}>
                        When Mrs. Carolyn Tshering left Druk School in the year 2003, the then Head Teacher of Khuenphen
                        Juney Regpay Primary School, Mrs. Karma Choden took over as the next principal.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2006
                    </h6>
                    <p className={classes.typography}>
                        Mrs. Karma Choden was succeeded by Ms. Deki Tyala as she took over as Principal from 2006. She
                        played a major role in establishing the school.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2008
                    </h6>
                    <p className={classes.typography}>
                        However, the best was yet to come. As Ms. Deki Tyala left for the United States for her higher
                        studies, Druk School got the person who gave her name and fame. On April, 2008 the present
                        Principal, Mrs. Tshewang Choden Wangdi took over her duties and since then Druk has never looked
                        back. <br/><br/>
                        Now, it stands as one of the most recognized and renowned privately run Higher Secondary Schools
                        in Bhutan.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2009
                    </h6>
                    <p className={classes.typography}>
                        A Nu 15 crore infrastructure development project started on January, 2009 for upgradation to
                        Middle Secondary level. It’s currently under process. The new state-of-the-art building will
                        cater to the needs of a Middle Secondary school. We are hoping that the construction gets over
                        soon by early 2011. <br/><br/>
                        The newly constructed state-of-the-art building will be housing well furnished classrooms, well
                        equipped Science laboratories, Multipurpose hall, parking space and much more. Perhaps the most
                        extra ordinary architectural achievement is extension of the school’s playground on the terrace
                        of the new building. <br/><br/>
                        The school started with 45 students in 2001 and thereafter saw a steady increase. Today we have
                        more than 363 students and we provide full scholarship to 5 students. <br/><br/>
                        The school employs 31 well-qualified and experienced teachers, two interns and eight
                        non-teaching staff. <br/><br/>
                        The school’s vision is to pursue excellence in education and wholesome development of the
                        children with the best of infrastructure. We, at Druk School, follow a policy of keeping the
                        Student: Teacher Ratio to 20: 1. We believe that a small class size is critical to ensuring that
                        teachers provide individual attention to the individual problems of the students.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2012
                    </h6>
                    <p className={classes.typography}>
                        The school was upgraded to a Middle Secondary School in 2012. <br/><br/>
                        Druk School serves students in grades Pre-Primary to grade IX. The total enrollment in 2012 is
                        683 students.
                    </p>
                    <Divider className="mt-5 mb-5"/>
                    <h6 className="light-green" style={{fontSize: 25, textAlign: "left", marginTop: 30}}>
                        2018
                    </h6>
                    <p className={classes.typography}>
                        The school upgraded to Higher Secondary with one section of XI Arts and one section of XI
                        Science. Druk School serves students in grades Pre-Primary to grade XI. The total enrollment as of 2018
                        is 614 students.
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default OurHistory;