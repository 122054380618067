import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import DateFnsAdapter from '@date-io/date-fns';
import {DropzoneArea} from 'material-ui-dropzone'
import Button from "@material-ui/core/Button";
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';
import MenuItem from "@material-ui/core/MenuItem";
import {CircularProgress, Typography} from "@material-ui/core";
import {FieldArray, Form, Formik} from "formik";
import {Select, TextField} from 'formik-material-ui';
import * as Yup from "yup";
import {eventCategories, eventDetails, eventList, eventUpdate} from "../../../utils/api";
import {KeyboardDatePicker, KeyboardDateTimePicker} from 'formik-material-ui-pickers';
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../../store";
import {useHistory} from 'react-router-dom';
import CreateCategory from "../category-create.dialog";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {objectToFormData as o2f} from 'object-to-formdata';
import EventIcon from '@material-ui/icons/Event';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {cloneDeep} from "lodash-es";
import {useParams} from "react-router";
import Loader from "../../shared/Loader";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles({
        mainRoot: {
            textAlign: 'left'
        },
        root: {
            textAlign: 'left',
            padding: '24px',
        },
        fullWidth: {
            width: '100%'
        },

        margin0: {
            margin: '0'
        },
        mb0: {
            marginBottom: '0'
        },
        pullRight: {
            float: 'right'
        },
        title: {
            verticalAlign: 'sub',
            marginRight: '4px'
        },
        mb12: {
            marginBottom: '16px'
        },
        handCursor: {
            cursor: 'pointer'
        },
        margintb: {
            marginTop: 15,
            marginBottom: 15
        }
    }
);
const dateFns = new DateFnsAdapter();
export default function EventCreate({location}) {
    const classes = useStyles();
    const {roles} = useContext(AppContext);
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const {id: eventId} = useParams();
    const [load, onLoad] = useState(false);
    const [show, setShow] = useState(false);
    const [editInitial, setEditInitial] = useState({});
    const inviteOptions = [
        {
            value: 'compulsory',
            label: 'Compulsory',
        },
        {
            value: 'need_approval',
            label: 'Need Approval',
        },
        {
            value: 'invite_only',
            label: 'Only Invited',
        },
        {
            value: 'can_join',
            label: 'Can Join',
        },
    ];

    const onSubmit = async (values, formikBag) => {
        try {
            onLoad(true);
            const data = cloneDeep(values);
            delete data['setting_attributes']['visibility'];
            const formData = o2f(data, {indices: true}, null, 'event');
            data.photos_attributes.map((lists, index) => (lists.id ? formData.append(`event[photos_attributes][${index}]`, ['']) : lists));
            values.setting_attributes.visibility.forEach(visib => {
                formData.append('event[setting_attributes][visibility][]', visib.toString());
            });
            if (eventId) {
                await eventUpdate(eventId, formData).then(response => {
                    formikBag.setSubmitting(false);
                    onLoad(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        payload: {severity: 'success', message: 'Event updated successfully'}
                    });
                    history.push(`/events/details/${response.data.event.id}`, {...response.data.event});
                });
            } else {
                await eventList('post', formData).then(response => {
                    formikBag.setSubmitting(false);
                    onLoad(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        payload: {severity: 'success', message: 'Event created successfully'}
                    });
                    history.push(`/events/details/${response.data.event.id}`, {...response.data.event});
                });
            }

        } catch ({errors}) {
            onLoad(false);
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {severity: 'error', duration: 5000, message: errors}
            });
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('title is required!'),
        description: Yup.string().min(20, 'Too short!').required('Enter a description')

    });

    const initialValues = {
        title: '', description: '',
        from_date: dateFns.addDays(new Date(), 1),
        to_date: dateFns.addDays(new Date(), 2),
        last_date: dateFns.addDays(new Date(), 1),
        location: '',
        color: '',
        event_category_id: '',
        draft: false,
        setting_attributes: {
            visibility: [],
            restriction: ''
        },
        photos_attributes: [],
        capacity: ''
    };

    const [categories, setCategories] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    //let CategoryModal = null;
    useEffect(() => {
        (async function () {
            if (eventId) {
                eventDetails(null, eventId).then(response => {
                    setEditInitial(response['event']);
                    setShow(true);
                });
            } else {
                setShow(true);
            }
            eventCategories().then(response => setCategories(response));

        })();
    }, []);

    const openAddCategoryModal = () => {
        setShowCategoryModal(true);
    };

    const handleCategoryCreation = (newCategory, setFieldValue) => {
        setShowCategoryModal(false);
        if (newCategory) {
            setCategories([...categories, newCategory]);
            setFieldValue('event_category_id', newCategory.id);
        }
    };

    const handleImagesChange = (files, single, formikBag) => {
        const existingImage = formikBag.values.photos_attributes.findIndex(photo => photo.default);
        if (single) {
            if (existingImage >= 0) {
                formikBag.setFieldValue(`photos_attributes[${existingImage}].image`, files[0]);
            } else {
                formikBag.setFieldValue(`photos_attributes`, [...formikBag.values.photos_attributes, {
                    image: files[0],
                    default: true
                }]);
            }
        } else {
            formikBag.setFieldValue(`photos_attributes`, [...files.map(file => ({image: file}))]);

            if (existingImage >= 0) {
                formikBag.setFieldValue(`photos_attributes[${existingImage}]`, formikBag.values.photos_attributes[existingImage]);

            }
        }
    };
    const handleAttachmentsChange = (files, formikBag) => {
        formikBag.setFieldValue('attachments_attributes', files.map(file => ({file})))
    };

    const eventColor = [
        {label: 'Red', value: '#fc2003'},
        {label: 'Blue', value: '#0703fc'},
        {label: 'Yellow', value: '#fcdf03'},
        {label: 'Green', value: '#03fc7b'},
        {label: 'Pink', value: '#ff00a2'},
        {label: 'Purple', value: '#c800ff'},
        {label: 'Sky Blue', value: '#00eaff'}

    ];
    const colorEvent = (e, data, formikBag) => {
        formikBag.setFieldValue('color', data?.value);
    }


    return (
        <div className={classes.mainRoot} style={{marginTop: 70}}>
            <Grid container>
                <Grid item xs={12}>
                    <h2 onClick={history.goBack}>
                        <ArrowBackIcon className={[classes.title, classes.handCursor].join(' ')}/>
                        <EventIcon className={classes.title}/>Create Event</h2>
                </Grid>
            </Grid>
            {show ?
                <Paper className={classes.root} elevation={0}>
                    <Formik
                        initialValues={eventId ? editInitial : initialValues}
                        onSubmit={onSubmit}
                        validateOnBlur={true}
                        validationSchema={validationSchema}
                    >
                        {({isSubmitting, touched, handleSubmit, errors, ...formik}) => {
                            return (<>
                                {showCategoryModal && <CreateCategory open={showCategoryModal}
                                                                      handleClose={(result) => handleCategoryCreation(result, formik.setFieldValue)}/>}


                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField id="outlined-basic" label="Event Title" variant="outlined"
                                                       name="title"
                                                       className={[classes.fullWidth, classes.mb12].join(' ')}/>

                                            <Grid container spacing={2} justify="center"
                                                  alignItems="center">
                                                <Grid item xs={12} sm={9}>
                                                    <FormControl variant="outlined"
                                                                 className={[classes.fullWidth, classes.mb12].join(' ')}>
                                                        <InputLabel>Category</InputLabel>
                                                        <Select className={classes.fullWidth}
                                                                name="event_category_id" labelWidth={100}>
                                                            {
                                                                categories.map((category, index) =>
                                                                    (<MenuItem key={index}
                                                                               value={category.id}>{category.name}</MenuItem>))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Button variant="contained" color="primary"
                                                            className="{classes.pullRight} background-header"
                                                            onClick={openAddCategoryModal}>
                                                        Add New
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <div><Typography variant='h6' style={{marginTop: 15}}>
                                                Event Schedule
                                            </Typography></div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} md={6}>
                                                        <KeyboardDateTimePicker
                                                            name="from_date"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label="From date"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            format="dd-MM-yyyy pp"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <KeyboardDateTimePicker
                                                            margin="normal"
                                                            name="to_date"
                                                            label="To date"
                                                            variant="outlined"
                                                            format="dd-MM-yyyy pp"
                                                            fullWidth={true}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </MuiPickersUtilsProvider>


                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container spacing={4} className={classes.mb12}>
                                                    <Grid item xs={12}>
                                                        <KeyboardDateTimePicker
                                                            name="last_date"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label="Last date of joining"
                                                            variant="outlined"
                                                            format="dd-MM-yyyy pp"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </MuiPickersUtilsProvider>

                                            <div className={classes.margintb}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Description"
                                                    multiline
                                                    rows="4"
                                                    variant="outlined"
                                                    name="description"
                                                    className={[classes.fullWidth, classes.mb12].join(' ')}
                                                />
                                            </div>
                                            <div className={classes.margintb}>
                                                <TextField id="outlined-basic" label="Where is the event taking place"
                                                           variant="outlined"
                                                           name="location"
                                                           className={[classes.fullWidth, classes.mb12].join(' ')}/>
                                            </div>
                                            <div className={classes.margintb}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={eventColor}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    onChange={(e, value) => colorEvent(e, value, formik)}
                                                    style={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label="Select event color"
                                                                                        name="color"
                                                                                        variant="outlined" />}
                                                />
                                            </div>


                                            <div style={{marginTop: 15}}>
                                                <Typography variant="h6">
                                                    Visibility
                                                </Typography></div>
                                            <p className={classes.margin0}>Select who all can view this event</p>
                                            <FormGroup row>
                                                <FieldArray name="setting_attributes.visibility"
                                                            render={arrayHelpers =>
                                                                roles.filter(role => role.name !== 'Admin').map((role, index) => {

                                                                    return (

                                                                        <FormControlLabel
                                                                            key={index} control={
                                                                            <Checkbox
                                                                                value={role.id}
                                                                                name={`setting_attributes.visibility`}
                                                                                checked={formik.values.setting_attributes.visibility.includes(role.id)}
                                                                                onChange={e => {
                                                                                    if (e.target.checked) arrayHelpers.push(role.id);
                                                                                    else {
                                                                                        const idx = formik.values.setting_attributes.visibility.indexOf(role.id);
                                                                                        arrayHelpers.remove(idx);
                                                                                    }
                                                                                }}

                                                                                color="primary"/>
                                                                        } label={role.name}
                                                                        />)
                                                                })
                                                            }>

                                                </FieldArray>
                                            </FormGroup>
                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            {/*<Typography variant="h6" style={{marginBottom: 15}}>
                                                Participant Approval Setting
                                            </Typography>
                                            <Grid container spacing={2}>

                                                <Grid item xs={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.fullWidth}>
                                                        <InputLabel>Restrictions</InputLabel>
                                                        <Select labelWidth={100} name="setting_attributes.restriction">
                                                            {inviteOptions.map((restriction, index) =>
                                                                (<MenuItem key={index}
                                                                           value={restriction.value}>{restriction.label}</MenuItem>))
                                                            }
                                                        </Select>
                                                    </FormControl>

                                                </Grid>
                                            </Grid>*/}

                                            {/*<Grid container spacing={2}>
                                    <Grid item xs={6}><p>Student</p></Grid>
                                    <Grid item xs={6}> <TextField
                                        id="outlined-select-currency"
                                        select
                                        variant="outlined"
                                        className={classes.fullWidth}
                                    >
                                        {inviteOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField></Grid>
                                </Grid>*/}
                                            <Typography variant="h6" style={{marginBottom: 15, marginTop: 15}}>
                                                Total Capacity Setting
                                            </Typography>
                                            <p className={classes.margin0}>Not specifying total capacity will set
                                                unlimited
                                                participant</p>
                                            {/*<FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={state.checkedA} onChange={handleChange('checkedA')}
                                                          value="checkedA"
                                                          color="primary"/>

}
                                            label="There is maximum capacity"
                                        />

                                    </FormGroup>*/}
                                            <div className={classes.margintb}><TextField name="capacity"
                                                                                         label="Total Capacity"
                                                                                         variant="outlined"
                                                                                         className={classes.fullWidth}/>
                                            </div>

                                            {/*<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedB}
                                                onChange={handleChange('checkedB')}
                                                value="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Allow participant to bring guest"
                                    />
*/}

                                            {/*<Grid container spacing={2}>
                                        <Grid item xs={6}><p>Total Guest Capacity</p></Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" variant="outlined"
                                                       className={classes.fullWidth}/>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={2}>
                                        <Grid item xs={6}><p>Per member</p></Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" variant="outlined"
                                                       className={classes.fullWidth}/>
                                        </Grid>
                                    </Grid>*/}
                                            <div>
                                                <DropzoneArea
                                                    maxFileSize={5000000}
                                                    filesLimit={1}
                                                    showPreviewsInDropzone={true}
                                                    dropzoneClass="dropZone"
                                                    showAlerts={false}
                                                    dropzoneText="Add event image "
                                                    dropzoneParagraphClass="dropZoneDes"
                                                    onChange={(files) => handleImagesChange(files, true, formik)}/>
                                            </div>

                                            <div>
                                                <DropzoneArea
                                                    maxFileSize={5000000}
                                                    filesLimit={20}
                                                    showPreviews={false}
                                                    showAlerts={false}
                                                    showPreviewsInDropzone={true}
                                                    dropzoneText="Add Attachments"
                                                    dropzoneClass="dropZone"
                                                    dropzoneParagraphClass="dropZoneDes"
                                                    showFileNames={true}
                                                    useChipsForPreview={true}
                                                    onChange={(files) => handleAttachmentsChange(files, formik)}/>
                                            </div>
                                            <div>
                                                <DropzoneArea
                                                    maxFileSize={5000000}
                                                    filesLimit={20}
                                                    showAlerts={false}
                                                    showPreviewsInDropzone={true}
                                                    acceptedFiles={['image/*']}
                                                    dropzoneClass="dropZone"
                                                    dropzoneText="Add other images" dropzoneParagraphClass="dropZoneDes"
                                                    onChange={(files) => handleImagesChange(files, false, formik)}
                                                />
                                            </div>


                                            <div style={{flexGrow: 1}}>
                                                <Grid container spacing={2} style={{marginTop: 15}}>
                                                    <Grid item md={4} xs={12}>
                                                        <Button fullWidth={true} variant="contained" color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                className="background-header"
                                                        >
                                                            {eventId ? 'Update' : 'Create'}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <Button variant="outlined" color="primary" fullWidth={true}
                                                                onClick={() => {
                                                                    formik.setFieldValue('draft', true);
                                                                    handleSubmit()
                                                                }}
                                                                disabled={isSubmitting}>
                                                            Save as draft
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <Button variant="outlined" color="secondary" fullWidth={true}>
                                                            Cancel
                                                        </Button></Grid>

                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </>)
                        }}
                    </Formik>
                    {load && <Loader/>}
                </Paper> : <div align="center"><CircularProgress/></div>}
        </div>
    )
}
