import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import TimeIcon from '@material-ui/icons/QueryBuilder';
import './principals.scss'


const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "left",
        marginTop: 10,
    },
}));

const PrincipalDesk = () => {
    const classes = useStyles();

    return (
        <div className="principal">
            <h4 className="pull-left mt-2 mb-2">Message from Principal</h4>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.typography}>
                        Message to Teachers:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: "left"}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Jan, 2020
                    </p>
                    <p className={classes.typography}>
                        All the teachers are asked to gather in the conference hall for the short meeting for the
                        upcoming events
                    </p>
                    <Divider className="mt-4 mb-4"/>
                    <Typography className={classes.typography}>
                        Message to Students:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        All the students of class 12 science are requested to be in their respective classes after
                        school for
                        the talk with officiating principal
                    </p>
                    <Divider className="mt-4 mb-4"/>
                    <Typography className={classes.typography}>
                        Message for general:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        School will remain closed from next month due to Covid-19 cases and resume once all the cases
                        are being resolved.
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default PrincipalDesk;