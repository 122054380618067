import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import './overview.scss'
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(theme => ({

    view: {
        padding: 15
    },
    overview: {
        backgroundColor: '#92b3fd',
        padding: 10,
        marginBottom: 32,
    },
    content: {
        flexGrow: 1,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
}));

const Overview = () => {

    const classes = useStyles();

    const history = useHistory();

    const historyDetail = () => {
        history.push('/history')
    };
    const missionDetail = () => {
        history.push('/mission')
    };
    const anthemDetail = () => {
        history.push('/anthem')
    };
    const boardDetail = () => {
        history.push('/board')
    };
    const leaderDetail = () => {
        history.push('/leader')
    };
    const achievementDetail = () => {
        history.push('/achievements')
    };

    /*const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
*/
    return (
        <div id="overview" className={classes.view}>
            <div className={classes.overview}>
                <h5 className="mt-3 mb-3 pull-left">Overview</h5>
                <Grid container className="mb-5">
                    <Grid item md={2} xs={12} className="mt-2">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}
                                    onClick={historyDetail}>Our
                            History</Typography>
                    </Grid>
                    <Grid item md={4} xs={12} className="mt-2">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}
                                    onClick={missionDetail}>Our
                            Mission, Vision, and Belief Statement</Typography>
                    </Grid>
                    <Grid item md={1} xs={12} className="mt-2">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}
                                    onClick={anthemDetail}>Anthem</Typography>
                    </Grid>
                    <Grid item md={3} xs={12} className="mt-2">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}} onClick={boardDetail}>Board
                            and Coordinators,
                            2020</Typography>
                    </Grid>
                    <Grid item md={2} xs={12} className="mt-2">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}>Encellence
                            Awards</Typography>
                    </Grid>
                    <Grid item md={2} xs={12} className="mt-4">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}
                                    onClick={leaderDetail}>Leadership</Typography>
                    </Grid>
                    <Grid item md={2} xs={12} className="mt-4">
                        <Typography className="hand-cursor overview" style={{textAlign: 'left'}}
                                    onClick={achievementDetail}>School Achievements</Typography>
                    </Grid>

                    {/*<Grid item md={2} xs={12}>
                    <Typography>Anthem</Typography>
                    <Typography
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={{float: 'left', backgroundColor: 'yellow', color: 'blue'}}
                    > View More
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit style={{textAlign: 'justify'}}>
                        <Typography style={{textAlign: 'justify'}} className="mb-4">
                            Big tour operators, considering the volume of the guests they entertain, they have access to
                            hotel rooms
                            at half the price of what small and medium tour operators have to pay. Why can’t small and
                            medium
                            tour operators come together under one roof and negotiate with hotels to get a better rate
                            from
                            hotels? Bookyul is here to represent all interested tour operators and get better discounts
                            from hotels.
                            The best hotel deal is guaranteed with Bookyul.
                        </Typography>
                    </Collapse>
                </Grid>*/}

                </Grid>
            </div>
        </div>
    );
}

export default Overview;
