import React, {useContext, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useHistory} from 'react-router-dom';
import useStyles from '../styles';
import {invitationAccept} from "../../../utils/api";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import Paper from "@material-ui/core/Paper";
import {Form, Formik, useFormikContext} from "formik";
import {steps} from './components/steps';
import {isEqual} from 'lodash-es';
import {Grid} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {AppContext, DispatchContext} from "../../../store";
import {Notify, setProps} from "../../shared/notification";
import cloneDeep from "lodash-es/cloneDeep";
import Loader from "../../shared/Loader";

const defaultShouldTriggerErrors = (errors, nextErrors) => !isEqual(errors, nextErrors);

export const ErrorListener = ({onError, TriggerErrors}) => {
    const shouldTriggerErrors = TriggerErrors || defaultShouldTriggerErrors;
    const formik = useFormikContext();
    const [errors, updateErrors] = React.useState(formik.errors);
    React.useEffect(() => {

        if (shouldTriggerErrors(errors, formik.errors)) {
            onError(formik.errors);

            updateErrors(errors);
        }
    }, [formik.errors]);

    return null;
};


export const PeopleCreate = (props) => {
    const classes = useStyles();
    const urlParams = new URLSearchParams(props.location.search);
    const roleFromParam = '' + urlParams.get('roles[]');
    const invitation_token = urlParams.get('invitation_token');
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const {roles = []} = useContext(AppContext);
    const [load, setLoad] = useState(false);
    props = useSnackbar();


    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (!isLastStep()) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepReset = () => {
        setActiveStep(0);
    };

    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const inputLabel = React.useRef(null);

    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        setProps(props);
        inputLabel && inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClickOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const initialValues = steps.reduce(
        (values, {initialValues}) => ({
            ...values,
            ...initialValues
        }),
        {}
    );

    const ActiveStep = steps[activeStep];
    const validationSchema = ActiveStep.validationSchema(useContext(AppContext));

    const onSubmit = async (values, formikBag) => {
        const {setSubmitting} = formikBag;
        setLoad(true);
        if (!isLastStep()) {
            setSubmitting(false);
            setLoad(false);
            handleNext();
            return;
        }

        const formData = cloneDeep(values);

        delete formData['parent'];
        formData.qualifications_attributes = values.qualifications_attributes.filter(qua => !!qua.grade);
        if (!formData.qualifications_attributes.length) {
            delete formData['qualifications_attributes']
        }
        /*if (roleFromParam === 'Student') {
            debugger
            const userRole = roles.find(role => role.name === roleFromParam);
            if (!userRole) return;  //bail out
            formData.role_ids = [userRole.id];
        }*/
        dispatch({type: 'LOGGING_IN'});
        invitationAccept({user: {...formData, invitation_token: invitation_token}}, dispatch).then(_ => {
            setLoad(false);
            dispatch({type: 'LOGIN_SUCCESS'});
            history.push('/');
            Notify('User confirmed successfully', 'success');
        })
            .catch(({errors}) => {
                setLoad(false);
                Notify(errors.join(','), 'error');
            });

    };


    return (
        <div className={classes.root}>
            {/*<Grid container>
                <Grid item xs={12}>
                    <h2>
                        <IconButton>
                            <ArrowBackIcon className={classes.title}/>
                        </IconButton>

                        <PeopleIcon className={classes.title}/>Adding {roleFromParam.toUpperCase()}</h2>
                </Grid>
            </Grid>*/}
            <Paper elevation={0} className={classes.peopleCreate}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={true}
                >
                    {({isSubmitting, touched, values, setFieldValue, errors, formik}) => {
                        return (
                            <>
                                {/* { JSON.stringify(values) }*/}
                                <Form>
                                    <Grid container
                                          alignItems="center"
                                          justify="center"
                                          style={{minHeight: '100vh'}}>
                                        <Grid item xs={10}>
                                            <Stepper activeStep={activeStep} className="center" orientation='vertical'>
                                                {steps.map((MyStep, index) => (
                                                    <Step key={index}>
                                                        <StepLabel><h3>{MyStep.label}</h3></StepLabel>
                                                        <StepContent>
                                                            <Typography component='div'>
                                                                <MyStep role={roleFromParam} {...values}/>
                                                            </Typography>
                                                        </StepContent>
                                                    </Step>
                                                ))}
                                                <>
                                                    <div className={classes.actionsContainer}>
                                                        <div className={classes.pullRight}>
                                                            <Button
                                                                disabled={activeStep === 0}
                                                                variant='outlined'
                                                                color='primary'
                                                                className={classes.btnMargin}
                                                                onClick={handleBack}
                                                            >
                                                                Back
                                                            </Button>
                                                            <Button disabled={isSubmitting}
                                                                    variant='contained'
                                                                    color='primary'
                                                                    type="submit"
                                                                    className={classes.btnMargin}
                                                            >
                                                                {isLastStep() ? 'Finish' : 'Next'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>
                                            </Stepper>
                                        </Grid>
                                        {load && <Loader/>}
                                    </Grid>
                                </Form>
                            </>)
                    }}
                </Formik>
                {activeStep === steps.length && (
                    <div>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleStepReset} variant='outlined'
                                color='primary' className={classes.btnMargin}>
                            Reset
                        </Button>
                        <Button variant='contained' color='primary' className={classes.btnMargin}
                                onClick={handleClickOpenModal}>
                            Invite
                        </Button>
                    </div>
                )}
            </Paper>
            {/*Confirmation Modal to invite user*/}
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseModal}
            >
                <DialogTitle id="responsive-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to invite?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseModal} color="primary">
                        No
                    </Button>
                    <Button onClick={handleCloseModal} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PeopleCreate;