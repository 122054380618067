import React, {useContext} from 'react';
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {AppContext} from "../../../store";


export const Parent = () => {
    const history = useHistory();
    const {user} = useContext(AppContext);
    const {student_ids: students} = user;
    const viewSubjects = (id, secId) => {
        history.push(`/subject/${id}/${secId}?parent=${true}`)
    };

    return (
        <div>
            <h4 className="mt-4 pull-left">Children</h4>
            <Grid container spacing={2}>
                {students && students.length > 0 ? students.map(student => (
                    <Grid item xs={12} md={4} key={student.id}>
                        <Card className="p-3 mt-4 hand-cursor" style={{backgroundColor: 'white'}}
                              onClick={() => viewSubjects(student?.profile_attributes?.section_grade_id,
                                  student?.profile_attributes?.section_id)}>
                            <Typography style={{color: "black"}}>
                                <b>
                                    {student?.profile_attributes?.contact_person_attributes?.first_name}{' '}
                                    {student?.profile_attributes?.contact_person_attributes?.last_name}
                                </b>
                            </Typography>
                        </Card>
                    </Grid>
                )) : <div align={'center'}><h3>No Data To Display</h3></div>}
            </Grid>
        </div>
    );
};
