import {SNACKBAR_CLOSED, SNACKBAR_OPEN} from "./action-types";
import isArray from "lodash-es/isArray";

export const snackbarReducer = (state, action) => {
    switch (action.type) {
        case SNACKBAR_OPEN:
            return {
                ...state,
                open: true,
                severity: 'success',
                duration: 3 * 1000,
                ...action.payload,
                message: !isArray(action.payload.message) ? [action.payload.message] : action.payload.message
            };
        case SNACKBAR_CLOSED:
            return {...state, open: false, ...action.payload};
        default:
            return state;
    }
};
