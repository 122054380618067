import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import FileIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {Link, useHistory} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router";
import {assignmentDetail} from "../../../../utils/assignApi";
import ReactPlayer from "react-player";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import RenderAuthorized from "../../../../components/render_authorized";
import Moment from "react-moment";
import parse from 'html-react-parser';
import EditIcon from "@material-ui/icons/Edit";
import "./current.scss";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        paddingTop: '56.25%',
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));

export const CurrentStudent = () => {
    const classes = useStyles();
    const history = useHistory();
    const {id: subjectId, assignId: assignmentId} = useParams();
    const [assignments, setAssignments] = useState({});
    const [load, setLoad] = useState(false);
    const [subId, setSubId] = useState('');

    useEffect(() => {
        fetchAssignments();
    }, []);

    const fetchAssignments = () => {
        (async function fetchData() {
            await assignmentDetail(subjectId, assignmentId).then(response => {
                setLoad(true);
                setSubId(response.data.assignment.submissions?.[0]?.id);
                setAssignments(response.data['assignment']);
            })
        })()
    };

    const answerForm = (id) => {
        history.push(`/answer/${subjectId}/${assignmentId}?task=assignment`)
    };

    return (
        <div style={{marginTop: 70}}>
            {load ?
                <div>

                    <Grid container>
                        <Grid item xs={7}>
                            <Typography className="mt-2 mb-2">
                                <h6 style={{float: 'left'}}>{assignments.title}<br/>
                                </h6>
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <span className="ml-0 mb-2"
                                  style={{fontSize: 14, opacity: 0.7, float: 'left'}}>Posted: <Moment format="LLL"
                                                                                                      withTitle>{assignments?.created_at}</Moment></span>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography className="mb-2 ml-0 pull-left">
                                <h6> Total marks: {assignments.total_marks}</h6>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12} md={6}>
                            <Card className="card pt-2 pb-2">
                                <h6 className="pull-left">{assignments.title}</h6>
                                <Typography>
                                    {parse(assignments?.description || '')}
                                </Typography>
                                <Grid container>
                                    {assignments.attachments_attributes && assignments.attachments_attributes.map(files => (
                                      <Grid item xs={12} md={12} key={files.id} className="mb-3">
                                          Documents:
                                            <a href={files.file_url}  target="_blank" rel="noopener noreferrer" >
                                                {files?.filename}
                                            </a>
                                        </Grid>
                                    ))}
                                    {assignments.photos_attributes && assignments.photos_attributes.map(images => (
                                        <Grid item xs={12} md={2} key={images.id}>
                                            <a href={images.original}
                                               target="_blank"rel="noopener noreferrer">
                                                <img src={images.large} style={{width: 150, height: 150}} alt=""/>
                                            </a>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container>
                                    {assignments.videos_attributes && assignments.videos_attributes.map(videos => (
                                        <Grid item xs={12} md={6} key={videos.id}>
                                            <div className={classes.wrapper} data-vimeo-url={videos.clip_url}>
                                                <ReactPlayer
                                                    className={classes.player}
                                                    url={videos.clip_url}
                                                    controls={true}
                                                    //youtubeConfig={{playerVars: {showinfo: 1}}}
                                                    //playing
                                                    width='100%'
                                                    height='100%'
                                                />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item lg={6} xs={12} md={6}>
                            <Card className="card pt-2 pb-2">
                                <Typography>
                                    <h5>My Submission</h5>
                                </Typography>
                                <Button color="primary" className="edit-btn"
                                        component={Link}
                                        to={{pathname: `/answer/${subjectId}/${assignmentId}?task=assignment&&edit=${true}&&id=${subId}`}}>
                                    <EditIcon/> Edit
                                </Button>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <h6 style={{textTransform: 'capitalize'}}>
                                            <b>{assignments?.submissions?.[0]?.title}</b></h6>
                                        <Typography style={{textAlign: "left"}} className="p-3">
                                            {parse(assignments?.submissions?.[0]?.description || '')}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12} className="p-2">
                                        {assignments?.submissions?.[0]?.attachments_attributes && assignments?.submissions?.[0]?.attachments_attributes.map(files => (
                                            <Grid item xs={12} md={12} key={files.id}>
                                                Documents:
                                                <a href={files.file_url}
                                                   target="_blank" rel="noopener noreferrer">
                                                    {files?.filename}
                                                </a>
                                            </Grid>
                                        ))}
                                        {assignments?.submissions?.[0]?.photos_attributes && assignments?.submissions?.[0]?.photos_attributes.map(images => (
                                            <Grid item xs={12} md={2} key={images.id}>
                                                <a href={images.original}
                                                   target="_blank" rel="noopener noreferer">
                                                    <img src={images.large} style={{width: 150, height: 150}} alt=""/>
                                                </a>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container>
                                        {assignments?.submissions?.[0]?.videos_attributes && assignments?.submissions?.[0]?.videos_attributes.map(videos => (
                                            <Grid item xs={12} md={6} key={videos.id}>
                                                <div className={classes.wrapper} data-vimeo-url={videos.clip_url}>
                                                    <ReactPlayer
                                                        className={classes.player}
                                                        url={videos.clip_url}
                                                        controls={true}
                                                        //youtubeConfig={{playerVars: {showinfo: 1}}}
                                                        //playing
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Card>

                        </Grid>
                    </Grid>
                    <Card className="mt-2 p-2" style={{backgroundColor: '#03a9fc'}}>
                        <Typography className="pull-left">Submission
                            Date: {new Date(assignments.due_date).toDateString()}</Typography>
                    </Card>
                    <RenderAuthorized authorized={['Student']}>
                        <div className="mt-3 mb-3 p-4">
                            <Button variant="contained" style={{backgroundColor: 'green'}}
                                    className="text-capitalize pull-left"
                                    onClick={answerForm}>Answer</Button>
                        </div>
                    </RenderAuthorized>

                </div> : <div align={'center'}><CircularProgress/></div>}
        </div>
    )
};

export default CurrentStudent
