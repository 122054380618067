import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Pagination from '@material-ui/lab/Pagination';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {DropzoneArea} from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import {Editor} from '@tinymce/tinymce-react';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export const AssignmentDetail = () => {

    const classes = useStyles();

    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    };

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Assignment 1</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Ad doloribus dolorum eius eos expedita ipsa, ipsam iusto natus nisi nulla possimus quae
                        quaerat quia reiciendis rem repellat saepe sunt temporibus?
                    </Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <FileIcon fontSize="large" className="hand-cursor" style={{color: 'blue'}}/>
                    <Typography className="ml-2 mt-2">File</Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <Typography>Submission Date: Sat, 29 Mar.</Typography>
                </ExpansionPanelDetails>
                <Divider light className="mb-4"/>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField variant="outlined" label="Title" className="half-width pull-left"/>
                        </Grid>
                        <Grid item xs={8}>
                            <Card>
                                <Editor
                                    initialValue="<p>Questions</p>"
                                    init={{
                                        height: 320,
                                        width: '100%',
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <DropzoneArea
                                maxFileSize={5000000}
                                filesLimit={4}
                                showPreviews={false}
                                showPreviewsInDropzone={true}
                                dropzoneClass="dropZone"
                                dropzoneText="Upload answer"
                                acceptedFiles={['image/*']}
                                dropzoneParagraphClass="dropZoneDes"
                                className="dropZone"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="mt-3 mb-3">
                            <Button variant="contained" color="primary" style={{borderRadius: 20}}
                                    className="pull-left text-capitalize">Submit</Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Assignment 2</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Asperiores deleniti, ducimus ea excepturi nulla pariatur perspiciatis quis sint suscipit veniam!
                        Aperiam at delectus dolore facere minima placeat quidem temporibus vitae.
                    </Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <FileIcon fontSize="large" className="hand-cursor" style={{color: 'blue'}}/>
                    <Typography className="mt-2 ml-2">File</Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <Typography>Submission Date: Sat, 29 Mar.</Typography>
                </ExpansionPanelDetails>
                <Divider light className="mb-4"/>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField variant="outlined" label="Title" className="half-width pull-left"/>
                        </Grid>
                        <Grid item xs={8}>
                            <Card>
                                <Editor
                                    initialValue="<p>Questions</p>"
                                    init={{
                                        height: 320,
                                        width: '100%',
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <DropzoneArea
                                maxFileSize={5000000}
                                filesLimit={4}
                                showPreviews={false}
                                showPreviewsInDropzone={true}
                                dropzoneClass="dropZone"
                                dropzoneText="Upload answer"
                                acceptedFiles={['image/*']}
                                dropzoneParagraphClass="dropZoneDes"
                                className="dropZone"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="mt-3 mb-3">
                            <Button variant="contained" color="primary" style={{borderRadius: 20}}
                                    className="pull-left text-capitalize">Submit</Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Assignment 3</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus asperiores consequatur
                        cum dignissimos harum illum itaque modi obcaecati officia perspiciatis,
                        quam quia rem reprehenderit sed suscipit tempore vel velit veritatis?
                    </Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <FileIcon fontSize="large" className="hand-cursor" style={{color: 'blue'}}/>
                    <Typography className="ml-2 mt-2">File</Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <Typography>Submission Date: Sat, 29 Mar.</Typography>
                </ExpansionPanelDetails>
                <Divider light className="mb-4"/>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField variant="outlined" label="Title" className="half-width pull-left"/>
                        </Grid>
                        <Grid item xs={8}>
                            <Card>
                                <Editor
                                    initialValue="<p>Questions</p>"
                                    init={{
                                        height: 320,
                                        width: '100%',
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <DropzoneArea
                                maxFileSize={5000000}
                                filesLimit={4}
                                showPreviews={false}
                                showPreviewsInDropzone={true}
                                dropzoneClass="dropZone"
                                dropzoneText="Upload answer"
                                acceptedFiles={['image/*']}
                                dropzoneParagraphClass="dropZoneDes"
                                className="dropZone"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="mt-3 mb-3">
                            <Button variant="contained" color="primary" style={{borderRadius: 20}}
                                    className="pull-left text-capitalize">Submit</Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Assignment 4</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi corporis dignissimos,
                        dolore eveniet expedita iste iure labore nam nemo nostrum obcaecati odio placeat
                        quasi quia reiciendis repellendus tenetur voluptates.
                    </Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <FileIcon fontSize="large" className="hand-cursor" style={{color: 'blue'}}/>
                    <Typography className="ml-2 mt-2">File</Typography>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>
                    <Typography>Submission Date: Sat, 29 Mar.</Typography>
                </ExpansionPanelDetails>
                <Divider light className="mb-4"/>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField variant="outlined" label="Title" className="half-width pull-left"/>
                        </Grid>
                        <Grid item xs={8}>
                            <Card>
                                <Editor
                                    initialValue="<p>Questions</p>"
                                    init={{
                                        height: 320,
                                        width: '100%',
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={8}>
                            <DropzoneArea
                                maxFileSize={5000000}
                                filesLimit={4}
                                showPreviews={false}
                                showPreviewsInDropzone={true}
                                dropzoneClass="dropZone"
                                dropzoneText="Upload answer"
                                acceptedFiles={['image/*']}
                                dropzoneParagraphClass="dropZoneDes"
                                className="dropZone"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="mt-3 mb-3">
                            <Button variant="contained" color="primary" style={{borderRadius: 20}}
                                    className="pull-left text-capitalize">Submit</Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>
        </div>
    )
};