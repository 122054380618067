import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, ThemeProvider, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SubjectTeacherList from "../list";
import {StudentList} from "../../student-list/student-list";
import {createMuiTheme} from "@material-ui/core";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const MemberTab = (props) => {
    const theme = useTheme();
    const profile = props.list;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    const themee = createMuiTheme({
        palette: {
            primary: {
                main: "#cda817"
            },
        },
    });

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor='#cda817'
                    aria-label="full width tabs"
                >
                    <Tab label="Subject Teachers" {...a11yProps(0)}/>
                    <Tab label="Students" {...a11yProps(1)}/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <ThemeProvider theme={themee}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <SubjectTeacherList list={profile}/>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <StudentList list={profile}/>
                    </TabPanel>
                </ThemeProvider>
            </SwipeableViews>
        </div>
    );
};
export default MemberTab

