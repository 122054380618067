import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import TimeIcon from '@material-ui/icons/QueryBuilder';
import './announcement.scss'

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "left",
        marginTop: 10,
    },
}));

const Announcement = () => {
    const classes = useStyles();

    return (
        <div className="announcement">
            <h4 className="pull-left mt-2 mb-2">Announcements</h4>
            <Grid container>
                <Grid item xs={12}>
                    <h6 className={classes.typography}>
                        Announcement for Teachers:
                    </h6>
                    <p style={{fontSize: 12, textAlign: "left"}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Jan, 2020
                    </p>
                    <p className={classes.typography}>
                        All the teachers are asked to gather in the conference hall for short meeting for the upcoming
                        school events.
                    </p>
                    <Divider className="mt-2 mb-2"/>
                    <h6 className={classes.typography}>
                        Announcement for Students:
                    </h6>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        All the class 12 science students are asked to stay in the classroom during the lunch time
                        for discussion on the upcoming events
                    </p>
                    <p className={classes.typography}>
                        All the class 12 Arts students are asked to stay in the classroom during the lunch time
                        for discussion on the upcoming events
                    </p>
                    <p className={classes.typography}>
                        All the class 11 science students are asked to stay in the classroom during the lunch time
                        for the discussion on the upcoming school events
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default Announcement;