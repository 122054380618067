import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useContext, useState} from "react";
import useStyles from '../../styles';
import {Select, TextField} from 'formik-material-ui';
import {departmentList} from "../../../../utils/api";
import {AppContext} from "../../../../store";
import {ErrorMessage, useFormikContext} from "formik";
import {FormHelperText} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

export const StaffForm = () => {
    const {roles} = useContext(AppContext);
    const staffRoles = roles.filter(role => ['Admin', 'Teacher', 'Staff'].includes(role.name));
    const classes = useStyles();
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [departments, setDepartments] = useState([{id: '-1', name: 'None'}]);
    const formik = useFormikContext();
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        /*departmentList().then(response => setDepartments([...departments, ...response.data['departments']]));*/
    }, []);

    return (
        <div>
            {/*<h4> Enter your Details</h4>*/}
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel ref={inputLabel}>Salutation</InputLabel>
                        <Select labelWidth={labelWidth} name="profile_attributes.contact_person_attributes.salutation">
                            <MenuItem value="mr">Mr.</MenuItem>
                            <MenuItem value="ms">Ms.</MenuItem>
                            <MenuItem value="mrs">Mrs.</MenuItem>
                        </Select>
                        <ErrorMessage name="profile_attributes.contact_person_attributes.salutation"
                                      component={FormHelperText} error={true}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField name="profile_attributes.contact_person_attributes.first_name" label="First Name"
                               variant="outlined"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField label="Last Name" variant="outlined"
                               className={classes.fullWidth}
                               name="profile_attributes.contact_person_attributes.last_name"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" label="Designation" variant="outlined"
                               className={classes.fullWidth}
                               name="profile_attributes.contact_person_attributes.designation"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" label="Employee ID" variant="outlined"
                               className={classes.fullWidth} name="profile_attributes.contact_person_attributes.eid"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" label="Citizenship ID" variant="outlined" type="number"
                               className={classes.fullWidth} name="profile_attributes.contact_person_attributes.cid"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" label="Phone Number" variant="outlined" type="number"
                               className={classes.fullWidth}
                               name="profile_attributes.contact_person_attributes.phone"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" type="password" label="Password"
                               variant="outlined"
                               name="password" className={classes.fullWidth}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" type={values.showPassword ? 'text' : 'password'}
                               label="Confirmed Password" variant="outlined"
                               name="password_confirmation" className={classes.fullWidth}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           onMouseDown={handleMouseDownPassword}
                                       >
                                           {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                       </IconButton>
                                   </InputAdornment>,
                               }}/>
                </Grid>
                 {/*<Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel ref={inputLabel}>Department</InputLabel>
                        <Select labelWidth={labelWidth}
                                name="profile_attributes.department_id">
                            {
                                departments.map((department, index) =>
                                    (<MenuItem key={index}
                                               value={department.id.toString()}>{department.name}</MenuItem>)
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>*/}
            </Grid>
        </div>
    )
};
