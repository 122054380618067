import {ROLE_LIST} from "./action-types";

export const rolesReducer = (state, action) => {
    switch (action.type) {
        case  ROLE_LIST:
            return [...action.payload];
        default:
            return state;
    }
};
export default rolesReducer;