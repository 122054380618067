import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/AddCircle'
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link, useHistory} from 'react-router-dom';
import {AppContext} from "../../../store";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TopMemberTab from "./assign-subject/member/top-member";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    Button: {
        backgroundColor: '$success-color'
    },
    year: {
        float: 'left',
        marginTop: 25,
        marginBottom: 25,
        fontSize: 25,
    },
    content: {
        flexGrow: 1,
    },
}));

export const ClassSection = () => {
    const classes = useStyles();

    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    //const {user} = useContext(AppContext);
    const {user: {profile_attributes}} = useContext(AppContext);
    const assign = () => {
        history.push('/assign')
    };

    return (
        <div style={{marginTop: 70}}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    {profile_attributes &&
                    <h3 className={classes.year}>Class {profile_attributes.section_grade_name} {' '}
                        '{profile_attributes.section_name}' for the Academic year {new Date().getFullYear()}</h3>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant="contained" className="pull-left mt-4"
                            style={{textTransform: 'capitalize', backgroundColor: "white"}}
                            startIcon={<AddIcon fontSize="small"/>}
                            onClick={assign}>
                        Assign Subject Teacher
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant="contained" className="background-header pull-right mt-4 ml-5 text-capitalize"
                            color="primary"
                            aria-controls='simple-menu' aria-haspopup='true'
                            style={{textTransform: 'capitalize'}} startIcon={<AddIcon fontSize="small"/>}
                            onClick={handleClickMenu}>
                        Invite
                    </Button>
                    <Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem component={Link}
                                  to={`/invite?role=Student`}>Student</MenuItem>
                        <MenuItem component={Link}
                                  to={`/invite?role=Parent`}>Parent</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="mt-5">
                    <TopMemberTab list={profile_attributes}/>
                </Grid>
            </Grid>
        </div>
    )
};
