import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {assignmentList, deleteAssignment} from "../../../../../utils/assignApi";
import {CircularProgress, Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import {DispatchContext, SNACKBAR_OPEN} from "../../../../../store";
import SharedDialog from "../../../../shared/dialog";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    button: {
        backgroundColor: '#D5F934',
        textTransform: 'capitalize',
        borderRadius: 25,
    }
}));

export default function AssignmentList(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const grade = params.get('grade');
    const section = params.get('grade');
    const {id: subjectId} = useParams();
    const [load, setLoad] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [ids, setIds] = useState('');

    useEffect(() => {
        fetchAssignments();
    }, []);

    const viewAssignment = (id) => {
        history.push(`/current-assignment/${subjectId}/${id}?grade=${grade}&&section=${section}`)
    };
    const addAssignment = () => {
        history.push(`/assigning-task/${subjectId}?task=assignment`)
    };

    const handleEdit = (id) => {
        history.push(`/assigning-task/${subjectId}/${id}?task=assignment&&edit=${true}`)
    };

    const handleDelete = async (result) => {
        setConfirmationOpen(false);
        if (result) {
            await deleteAssignment(subjectId, ids).then(response => {
                fetchAssignments();
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Assignment deleted successfully'}});
            }).catch(err => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const onClickDelete = (id) => {
        setIds(id);
        setConfirmationOpen(true);
    };


    const fetchAssignments = () => {
        (async function fetchData() {
            await assignmentList(subjectId, {filter: 'current'}).then(response => {
                setLoad(true);
                setAssignments([...response.data['assignments']]);
            })
        })()
    };

    return (
        <div>
            {load ?
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button variant="contained" className="mr-2 mb-2 text-capitalize pull-right"
                                    style={{borderRadius: 30}}
                                    onClick={addAssignment}>Add New</Button>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Assignment Title</StyledTableCell>
                                    <StyledTableCell>Submission Date</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>
                                        Actions
                                    </StyledTableCell>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignments && assignments.length > 0 ? assignments.map(assignment => (

                                    <StyledTableRow hover className="hand-cursor" key={assignment.id}>
                                        <StyledTableCell>{assignment.title}</StyledTableCell>
                                        <StyledTableCell>{new Date(assignment.due_date).toDateString()}</StyledTableCell>
                                        <StyledTableCell onClick={() => handleEdit(assignment.id)}>
                                            <Tooltip title="Edit">
                                                <EditIcon/>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => onClickDelete(assignment.id)}>
                                            <Tooltip title="Delete">
                                                <DeleteIcon/>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button variant="contained" className={classes.button}
                                                    onClick={() => viewAssignment(assignment.id)}>View
                                                Detail</Button>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                )) : <StyledTableRow>
                                    <StyledTableCell>
                                        <Typography align={"center"} variant={"h6"} component={"div"}>
                                            No data to display
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>
                </> : <div align={'center'}><CircularProgress/></div>}
            {/*<Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>*/}
        </div>
    )
}
