import React from "react";
import Typography from "@material-ui/core/Typography";
import {Link} from "@material-ui/core";

function Footer() {

    return (
        <footer className="footer" style={{backgroundColor: '#92b3fd', height: 100, color: 'white'}}>
            <Typography variant="h6" style={{paddingTop: 15, fontSize: 16, textAlign: "center"}}>
                {'Copyright ©'}
                {' '}
                <Link color="inherit" target="_blank">
                    Druk School
                </Link>
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Typography className="mt-3">
                Powered by <Link color="inherit" href="https://dcpl.bt/" target="_blank">
                Dragon Coders Pvt. Ltd.
            </Link>
            </Typography>
        </footer>
    );
}

export default Footer;
