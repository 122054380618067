import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "left",
        marginTop: 30,
        fontSize: 18,
    },
}));

const Mission = () => {
    const classes = useStyles();

    return (
        <div className="overview-types">
            <h4 className="mt-2 mb-1 light-green" style={{fontSize: 27}}>Mission, Vision and Belief Statements</h4>
            <Grid container>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="text-uppercase light-green" style={{fontSize: 21, textDecoration: 'underlined', textAlign: 'left'}}>
                        Vision
                    </h6>
                    <p className={classes.typography}>
                        Druk School is a center of academic excellence responsible for imparting knowledge and
                        behavioral skill to meet the challenges of today and tomorrow.
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3">
                    <h6 className="text-uppercase light-green" style={{fontSize: 21, textDecoration: 'underlined', textAlign: 'left'}}>
                        Mission
                    </h6>
                    <p className={classes.typography}>
                        GNH in Action.
                    </p>
                </Grid>
                <Grid item xs={12} md={12}>
                    <h6 className="text-uppercase light-green" style={{fontSize: 21, textDecoration: 'underlined', textAlign: 'left'}}>
                       belief statements
                    </h6>
                    <p className={classes.typography}>
                        Successful teaching takes place when there is safe, secure, and stimulating environment.<br/><br/>
                        Each child should be motivated in all aspects of learning by doing.<br/><br/>
                        Wholesome development of every individual.<br/><br/>
                        Successful teaching and learning takes place when there is mutual understanding between
                        parents and teachers.<br/><br/>
                        Reflection is an integral part of learning.<br/><br/>
                        Honoring human potential.<br/><br/>
                        All teachers make difference in the lives of children.<br/><br/>
                        All learning takes place when children are encouraged to think creatively and critically.<br/><br/>
                        Providing student centered class.<br/><br/>
                        Encouraging risk taking and problem solving.<br/><br/>
                        Recognizing every child’s contribution.<br/><br/>
                        Working collaboratively and helping each other.<br/><br/>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default Mission;