import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Formik} from 'formik';
import {login} from "../../utils/api";
import {DispatchContext} from "../../store";
import {useHistory} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://dcpl.bt">
                Dragon Coders Pvt. Ltd.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    logo: {
        margin: theme.spacing(1),
        width: '100px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    container: {
        height: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));

const Login = () => {
    const classes = useStyles();
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const [value, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({...value, showPassword: !value.showPassword});
    };
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    const submitForm = async (values, setSubmitting) => {
        dispatch({type: 'LOGGING_IN'});
        await login({user: {...values, login: values.email}}, dispatch);
        dispatch({type: 'LOGIN_SUCCESS'});
        history.push('/');
    };

    const validate = (values) => {
        let errors = {};
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        if (!values.password) {
            errors.password = 'Required';
        }
        return errors;
    };

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <Paper elevation={1} className={classes.paper}>
                <img className={classes.logo} src={require('../../assets/images/sherig-logo.png')}/>
                <Typography component="h1" variant="h5" className="mr-4">
                    Sign in
                </Typography>
                <Formik
                    initialValues={{email: '', password: '', remember_me: true}}
                    onSubmit={(values, actions) => {
                        submitForm(values, actions.setSubmitting)
                            .catch(error => {
                                actions.setSubmitting(false);
                                actions.setFieldError('general', error.error);
                            })
                    }
                    }
                    validateOnBlur={true}
                    validate={(values => validate(values))}
                >
                    {({
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          values
                      }) => (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <Typography component="span" variant="body2" style={{color: 'red'}}>
                                {errors.general}
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                type="email"
                                helperText={touched['email'] && errors['email']}
                                error={!!errors['email'] && touched['email']}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={value.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                helperText={touched['password'] && errors['password']}
                                error={!!errors['password'] && touched['password']}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {value.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" name="remember_me"/>}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                            {/*<Grid container>
                                <Grid item xs={12}>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item xs={12} className={classes.submit}>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>*/}
                        </form>
                    )}
                </Formik>

            </Paper>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default Login;