import React from 'react';
import './home-carousel.scss'
import Carousel from 'react-bootstrap/Carousel'
import {makeStyles} from "@material-ui/core/styles";
import Category from "../category/category";
import Overview from "../category/overview/overview";
import Footer from "../../footer/footer";
import {ContactUs} from "../contact-us/contact-us";


const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
    },
});

export default function HomeCarousel() {

    const classes = useStyles();

    return (
        <div>
            <Carousel className="image-carousel">
                <Carousel.Item>
                    <img src={require('../../../assets/images/bhu.jpg')} className="img"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={require('../../../assets/images/bhu1.jpeg')} className="img"
                    />
                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={require('../../../assets/images/bhu2.jpeg')} className="img"/>
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <main className={classes.content}>
                <Overview/>
                <Category />
                <ContactUs />
            </main>
            <Footer/>
        </div>
    );
}
