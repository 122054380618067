import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import TimeIcon from '@material-ui/icons/QueryBuilder';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "left",
        marginTop: 10,
    },
    category: {
      padding: 15,
    },
}));

const Category = () => {
    const classes = useStyles();

    const history = useHistory();

    const viewDetail = () => {
        history.push('/principal-desk')
    }

    const announcementDetail = () => {
        history.push('/announcement')
    }

    return (
        <div className={classes.category}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">From Principal's Desk</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}} onClick={viewDetail}>View All</p>
                    </div>
                    <Typography className={classes.typography}>
                        Message to Teachers:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: "left"}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Jan, 2020
                    </p>
                    <p className={classes.typography}>
                        Messages to teachers by the principal...
                    </p>
                    <Divider className="mt-2 mb-2"/>
                    <Typography className={classes.typography}>
                        Message to Students:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        Messages to students by the principal...
                    </p>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">Announcements</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}} onClick={announcementDetail}>View All</p>
                    </div>
                    <Typography className={classes.typography}>
                        Announcement for Teachers:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: "left"}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Jan, 2020
                    </p>
                    <p className={classes.typography}>
                        All the teachers are asked to gather in the conference hall ...
                    </p>
                    <Divider className="mt-2 mb-2"/>
                    <Typography className={classes.typography}>
                        Announcement for Students:
                    </Typography>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        All the class 12 science students are asked to stay in the classroom during the lunch time...
                    </p>
                    <p className={classes.typography}>
                        All the class 12 Arts students are asked to stay in the classroom during the lunch time...
                    </p>
                    <p className={classes.typography}>
                        All the class 11 science students are asked to stay in the classroom during the lunch time...
                    </p>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">Vacancy</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}}>View All</p>
                    </div>
                    <Typography className={classes.typography}>
                        Vacancy for IT teacher
                    </Typography>
                    <p style={{fontSize: 12, textAlign: "left"}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Jan, 2020
                    </p>
                    <p className={classes.typography}>
                        All the eligible candidates fulfilling the requirement can apply for the post...
                    </p>
                    <Divider className="mt-2 mb-2"/>
                    <Typography className={classes.typography}>
                        Vacancy for wet sweeper
                    </Typography>
                    <p style={{fontSize: 12, textAlign: 'left'}} className="ml-2">
                        <TimeIcon fontSize="small" style={{marginBottom: 3, width: 15}}/> 1 Mar, 2020
                    </p>
                    <p className={classes.typography}>
                        Immediate need of wet sweeper from next month...
                    </p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">Tender</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}}>View All</p>
                    </div>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Ab aspernatur aut culpa dolor eveniet incidunt ipsum nihil
                        numquam rerum sit soluta tenetur, velit, voluptatem? Deleniti
                        dolorum esse facilis ratione voluptatibus!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">Staff List</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}}>View All</p>
                    </div>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Ab aspernatur aut culpa dolor eveniet incidunt ipsum nihil
                        numquam rerum sit soluta tenetur, velit, voluptatem? Deleniti
                        dolorum esse facilis ratione voluptatibus!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{backgroundColor: 'yellow', padding: 10, height: 40, color: 'blue'}} >
                        <h6 className="pull-left">Student Results</h6>
                        <p className="pull-right text-uppercase hand-cursor" style={{color: 'blue'}}>View All</p>
                    </div>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Ab aspernatur aut culpa dolor eveniet incidunt ipsum nihil
                        numquam rerum sit soluta tenetur, velit, voluptatem? Deleniti
                        dolorum esse facilis ratione voluptatibus!
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
};

export default Category;