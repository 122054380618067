import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SearchFilter from "../../../shared/search";
import Grid from "@material-ui/core/Grid";
import {sectionList} from "../../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    },
    year: {
        float: 'left',
        fontSize: 25,
    },
}));

export const StudentList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const gradeId = props && props.list && props.list['section_grade_id'];
    const sectionId = props && props.list && props.list['section_id'];
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const grade = params.get('grade');
    const section = params.get('section');
    const [searchQuery, setSearchQuery] = useState('');
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [students, setStudents] = useState([]);

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const handleChange = event => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    useEffect(() => {
        fetchClassList();
    }, []);

    const fetchClassList = () => {
        (async function fetchData() {
            await sectionList(gradeId || grade, sectionId || section).then(response => {
                setLoad(true);
                setStudents([...response.data.section['users']]);
            });
        })()
    };

    const searchPeople = (query) => {
        setSearchQuery(query);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };

    return (
        <div>
            <h3 className={classes.year}>Students</h3>
            {/*<Grid container>
                <Grid item xs={6} className="mt-4 mb-4">
                    <SearchFilter callback={searchPeople}/>
                </Grid>
            </Grid>*/}
            {load ?
                <>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>ID</StyledTableCell>
                                    <StyledTableCell>Student Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Phone No.</StyledTableCell>
                                    {/*<StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell/>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {students.map((lists) => (
                                    lists.role_ids && lists.role_ids[0].name === 'Student' && lists.active === true &&
                                    <StyledTableRow key={lists.id}>
                                        <StyledTableCell>{lists.id}</StyledTableCell>
                                        <StyledTableCell>{lists.profile_attributes?.contact_person_attributes?.first_name || 'No'} {
                                            lists.profile_attributes?.contact_person_attributes?.last_name || 'Name'}</StyledTableCell>
                                        <StyledTableCell>{lists.email}</StyledTableCell>
                                        <StyledTableCell>{lists.profile_attributes.contact_person_attributes?.phone}</StyledTableCell>
                                        {/*<StyledTableCell>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={state.checkedB}
                                                            onChange={handleChange}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={'Delete'}>
                                                <Button className="text-capitalize" variant="outlined"
                                                        style={{color: 'white', backgroundColor: "red"}}>Remove</Button>
                                            </Tooltip>
                                        </StyledTableCell>*/}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*<TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />*/}
                </> : <CircularProgress/>}
        </div>
    );
};

