import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, ThemeProvider, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from "@material-ui/core/Card";
import Department from "./department/department";
import Role from "./role/role";
import Subject from "./subject/subject";
import {createMuiTheme} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
}));

export const Preferences = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const urlParams = new URLSearchParams(props.location.search);
    const roleFromParam = '' + urlParams.get('id');
    const [value, setValue] = React.useState(parseInt(roleFromParam) || 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    const themee = createMuiTheme({
        palette: {
            primary: {
                main: "#2C3E50"
            },
        },
    });

    return (
        <div className="pt-2" style={{marginTop: 70}}>
            <ThemeProvider theme={themee}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width"
                    >
                        <Tab label="Department" {...a11yProps(0)} />
                        <Tab label="Role" {...a11yProps(1)} />
                        <Tab label="Subject" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
            </ThemeProvider>
            <Card className="mt-3">
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <Department/>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Role/>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <Subject/>
                    </TabPanel>
                </SwipeableViews>
            </Card>
        </div>
    );
};
export default Preferences
