import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    list: {
        textDecoration: 'underline'
    },
    lists: {
        textAlign: 'left',
        fontSize: 17,
    }
}));

export const Achievement = () => {
    const classes = useStyles();

    return (
        <div className="overview-types" style={{padding: 20}}>
            <h4 className="mt-3 mb-3 light-green" style={{fontSize: 27, textAlign: 'left'}}>School's Achievement</h4>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ul className={classes.lists}>
                        <li>2011 end of year pass percentage: 98.64%. Students with aggregate 85 % above: 61%.</li>
                        <li>Adjudged one of the TOP TEN Schools in Bhutan by Ministry of Education for the years 2010,
                            2011 and 2012. Ranked First amongst all the 571 schools in the country.
                        </li>
                        <li>Adjudged as the Best School Performance in Science and Mathematics in the Students Learning
                            Assessment conducted by Royal Education Council in 2008, 2009 ,2010 and 2011.
                        </li>
                        <li>Adjudged as the best team of teachers in overall performance in the Teachers Needs
                            Assessment conducted by Royal Education Council in 2008 and 2011.
                        </li>
                        <li>Information Technology offered to classes PP-X</li>
                        <li>Music lessons (Traditional and Contemporary) for all classes: PP-X</li>
                        <li>Additional subject of Science in Class III level.</li>
                        <li>Day Boarding Programme( 3.15pm-7.00pm) for High Achievers as well as low achievers.</li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={12} className="p-4">
                    <h5 >
                        Druk School thanks all the parents and students for their support in making Druk School the
                        number 1school in the country once again!!
                    </h5>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3">
                    <h6 style={{textDecoration: "underline", textAlign: 'left'}}>Tops the nation in all three categories in BCSEA Exam,
                        2013:</h6>
                    <ol type="1" className={classes.lists}>
                        <li>Overall Ranking -
                            <span className="ml-1" style={{color: "yellow", backgroundColor: "red"}}>Rank one</span>
                        </li>
                        <li>Subject-wise mean performance Ranking:
                            <span className="ml-1" style={{color: "yellow", backgroundColor: "red"}}>Rank one</span>
                        </li>
                        <li>Schoolwise number of students qualified for class XI in Government School:
                            <span className="ml-1" style={{color: "yellow", backgroundColor: "red"}}>Rank One</span>
                        </li>
                    </ol>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <img src={require('../../../../../assets/images/sherig-logo.png')} className={classes.image}
                         alt=""/>
                </Grid>
            </Grid>
        </div>
    )
};

