import {Redirect, Route} from 'react-router-dom'
import React, {useContext} from "react";
import {AppContext} from "../store";
import RootLayout from "./root-layout";
import Authorized from "./authorized";
import Layout from "../layout/layout";

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const {user} = useContext(AppContext);
    return (
        <Route
            {...rest}
            render={props => {
                return user.authenticated ? (
                    <RootLayout><Authorized Component={Component}  {...rest}/></RootLayout>
                ) : (
                    <Redirect exact
                              to={{pathname: "/login", state: {from: props.location}}}
                    />
                )
            }
            }
        />
    )
};

export const UserRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    <Layout><Component  {...rest}/></Layout>
                );
            }
            }
        />
    )
};
