import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import Pagination from '@material-ui/lab/Pagination';
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/AddCircle'
import {NavLink, useHistory} from 'react-router-dom'
import {subjectDelete, subjectList} from "../../../utils/api";
import {CircularProgress, Typography} from "@material-ui/core";
import SharedDialog from "../../shared/dialog";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    },
    year: {
        float: 'left',
        marginLeft: 25,
        fontSize: 25,
    },
}));

export const Subject = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const [subjects, setSubjects] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [subId, setSubId] = useState('');
    const [load, onLoad] = useState(false);
    const [total, setTotal] = useState('');
    const [page, setPage] = useState(1);
    //const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async function fetchData() {
            await fetchSubject();
        })()
    }, [page]);

    const fetchSubject = async () => {
        await subjectList({page: page}).then(response => {
            onLoad(true);
            setTotal(response.data['meta'].last_page);
            setSubjects([...response.data['subjects']]);
        })
    };

    const handleDelete = async (result, event) => {
        setConfirmationOpen(false);
        if (result) {
            await subjectDelete(subId).then(response => {
                fetchSubject();
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Subject deleted successfully'}});
            }).catch(err => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };
    const setDelete = (id) => {
        setSubId(id);
        setConfirmationOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const createSubject = () => {
        history.push('/create-subject')
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Button variant="contained" color="primary"
                            className="background-header text-capitalize pull-right mr-2 mb-3"
                            onClick={createSubject}>
                        <AddIcon className="mr-2"/>
                        Add
                    </Button>
                </Grid>
            </Grid>
            {load ?
                <>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            {subjects.length > 0 &&
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>ID</StyledTableCell>
                                    <StyledTableCell>Module Name</StyledTableCell>
                                    <StyledTableCell/>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>
                            }
                            <TableBody>
                                {subjects.length > 0 ? subjects.map(lists => (
                                    <StyledTableRow key={lists.id}>
                                        <StyledTableCell>{lists.id}</StyledTableCell>
                                        <StyledTableCell>{lists.name}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={'Edit'}>
                                                <Button className="text-capitalize" variant="contained"
                                                        component={NavLink}
                                                        to={`/create-subject?edit=${lists.id}`}>Edit</Button>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={'Delete'}>
                                                <Button className="text-capitalize" variant="outlined"
                                                        color="secondary"
                                                        onClick={() => setDelete(lists.id)}>Delete</Button>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : (<StyledTableRow>
                                    <StyledTableCell>
                                        <Typography align={"center"} variant={"h6"} component="div">
                                            No data to display
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>
                </> : <CircularProgress/>}
            <Pagination count={total || 0} variant="outlined" onChange={handleChangePage} shape="rounded"/>
        </div>
    );
};

export default Subject

