import React, {useContext, useEffect, useState} from "react";
import {TextField} from 'formik-material-ui';
import useStyles from '../../../pages/people/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FieldArray, Form, Formik} from "formik";
import uuid from "uuid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import cloneDeep from "lodash-es/cloneDeep";
import {subjectCreate, subjectDetail, subjectUpdate} from "../../../utils/api";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";
import {useHistory} from "react-router-dom";
import * as Yup from 'yup';
import Card from "@material-ui/core/Card";

const validationSchema = Yup.object().shape({
    subject: Yup.array().of(Yup.object().shape({
        name: Yup.string().required('Field is required')
    }))
});

export const CreateModule = (props) => {
    const classes = useStyles();
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const urlParams = new URLSearchParams(props.location.search);
    const editId = '' + urlParams.get('edit');
    const [subject, setSubject] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        (async function fetchData() {
            if (editId !== 'null') {
                await fetchSubject();
            } else {
                setShow(true);
            }
        })()
    }, []);

    const fetchSubject = async () => {
        await subjectDetail(parseInt(editId)).then(response => {
            setSubject(response.data.subject);
            setShow(true);
        }).catch(error => {
            setShow(true);
        })
    };

    const addSubject = (arrayHelpers => {
        arrayHelpers.push({
            "uid": uuid.v4(),
            'name': '',
            "_destroy": 0
        });
    });
    const initialValue = {
        "subject": [
            {
                "default": true,
                "uid": uuid.v4(),
                "name": "",
                "_destroy": 0
            }
        ]
    };

    const editInitialValue = {
        "subject": [
            {
                "default": true,
                "uid": uuid.v4(),
                "name": subject.name,
                "_destroy": 0
            }
        ]
    };

    const deleteSubject = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    const onSubmit = async (values) => {
        const formData = cloneDeep(values);
        formData.subject.map((subjct) => (
            subjectCreate({subject: {name: subjct.name}}).then(response => {
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Subject created successfully'}});
                history.push(`/preferences?id=2`);
            }).catch(errors => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors, severity: 'error', duration: 5000}
                });
            })
        ))
    };

    const onUpdate = async (values) => {
        const formData = cloneDeep(values);
        formData.subject.map((subjct) => (
            subjectUpdate({subject: {name: subjct.name}}, parseInt((editId))).then(response => {
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Subject updated successfully'}});
                history.push(`/preferences?id=2`);
            }).catch(errors => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors, severity: 'error', duration: 5000}
                });
            })
        ))
    };

    return (
        <div style={{marginTop: 70}}>
            <Card>
                <h5 className="mt-3 ml-4 mb-3 pull-left">{editId !== 'null' ? 'Update Subject' : 'Add Subject'}</h5>
                {show &&
                <Formik
                    initialValues={editId ? editInitialValue : initialValue}
                    onSubmit={editId !== 'null' ? onUpdate : onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={true}
                >
                    {({isSubmitting, touched, values, ...formik}) => {
                        return (
                            <Form>
                                <FieldArray name="subject"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {
                                                        values.subject.map((departments, index) => (
                                                            <Grid container direction="row" spacing={2} key={index}>
                                                                <Grid item xs={12} md={5} className="ml-3 mr-3">
                                                                    <TextField variant="outlined" label="Subject Name"
                                                                               fullWidth
                                                                               name={`subject[${index}].name`}/>
                                                                </Grid>
                                                                <Grid item xs={12} sm={1}>
                                                                    {1 && editId === 'null' &&
                                                                    <Button aria-label='delete' variant='outlined'
                                                                            color='secondary'
                                                                            className="mt-2 ml-3"
                                                                            style={{float: "left"}}
                                                                            onClick={() => deleteSubject(index, arrayHelpers)}>
                                                                        <DeleteIcon/>
                                                                        Remove
                                                                    </Button>}
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                    {editId === 'null' &&
                                                    <Button variant='outlined' color='primary'
                                                            style={{float: 'left', marginLeft: 15}}
                                                            className={classes.addQualification}
                                                            onClick={() => addSubject(arrayHelpers)}>
                                                        <AddIcon/> Add more
                                                    </Button>
                                                    }
                                                </div>
                                            )}>
                                </FieldArray>
                                <Button
                                    variant='contained'
                                    style={{float: 'left', width: 186}}
                                    color='primary'
                                    type="submit"
                                    className="{classes.btnMargin} background-header mt-3 ml-3 mb-3"
                                >
                                    {editId !== 'null' ? 'Update Subject' : 'Create Subject'}
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
                }
            </Card>
        </div>

    )
};

export default CreateModule