import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from './utils/theme'
import {Route, Router, Switch} from "react-router-dom";
import useCombinedReducers from 'use-combined-reducers';
import {AppContext, defaultUser, DispatchContext, PEOPLE_LIST_FETCHING, userReducer} from "./store";
import React, {useEffect, useReducer} from "react";
import {peopleReducer, peopleStatusReducer} from "./store/people.reducer";
import Login from "./pages/auth/login";
import NotFound from "./pages/404";
import {ProtectedRoute, UserRoute} from "./components/protected-route";
import {history} from "./utils/history";
import {roleList} from "./utils/api";
import {rolesReducer} from "./store/roles.reducer";
import {navItems} from "./components/nav-content";
import {Routes} from "./utils/routes";
import Profile from "./pages/profile/profile";
import {snackbarReducer} from "./store/snackbar.reducer";
import {SnackbarProvider} from "notistack";
import {apiClient} from "./utils/apiClient";
import InvitationAll from "./pages/people/create/invitation/invitation";
import PeopleCreate from "./pages/people/create/create";
import HomeCarousel from "./containers/landing/home-carousel/home-carousel";
import {RouteUser} from "./utils/userRoutes";

function App() {
    const [state, dispatch] = useCombinedReducers({
        user: useReducer(userReducer, defaultUser),
        people: useReducer(peopleReducer, {data: [], meta: {}}),
        peopleStatus: useReducer(peopleStatusReducer, PEOPLE_LIST_FETCHING),
        roles: useReducer(rolesReducer, []),
        snackbar: useReducer(snackbarReducer, {
            severity: 'success',
            open: false,
            duration: 3000,
            message: ['Operation completed successfully']
        })
    });

    apiClient.dispatch = dispatch;
    useEffect(() => {
        state.user.authenticated && setTimeout(() => roleList(dispatch), 0);
    }, [state.user.authenticated]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <DispatchContext.Provider value={dispatch}>
                        <AppContext.Provider value={state}>
                            <SnackbarProvider maxSnack={3}>
                                <Router history={history}>
                                    <Switch>
                                        {
                                            navItems.map((nav, index) => (
                                                <ProtectedRoute {...nav} key={index} path={nav.path}
                                                                component={nav.component} exact/>))
                                        }
                                        {
                                            Routes.map((nav, index) => (
                                                <ProtectedRoute {...nav} key={index} path={nav.path}
                                                                component={nav.component} exact/>))
                                        }
                                        <ProtectedRoute path="/profile" component={Profile} exact skip={true}/>
                                        <ProtectedRoute path="/profile/:id" component={Profile} exact skip={true}/>
                                        <ProtectedRoute path="/invite" component={InvitationAll} exact skip={true}/>
                                        {RouteUser.map((nav, index) => (
                                            <UserRoute key={index} path={nav.path}
                                                            component={nav.component} exact/>))}
                                        <Route path="/login" component={Login} exact/>
                                        <Route path="/auth/invitation" component={PeopleCreate} exact/>
                                        <Route path="*" component={NotFound}/>
                                    </Switch>
                                </Router>
                            </SnackbarProvider>
                        </AppContext.Provider>
                    </DispatchContext.Provider>
                </div>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;