import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, ThemeProvider, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {StudentHomework} from "./homework/homework";
import MyAssignmentView from "./assignment";
import {useParams} from "react-router";
import {createMuiTheme} from "@material-ui/core";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
}));

export const Assignment = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    const themee = createMuiTheme({
        palette: {
            primary: {
                main: "#cda817"
            },
        },
    });

    return (
        <div className={classes.root} style={{marginTop: 70}}>
            <h4 className="mb-4 mt-4 ml-3 pull-left">My Dashboard</h4>
            <ThemeProvider theme={themee}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Assignment" {...a11yProps(0)} />
                        <Tab label="Homework" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </ThemeProvider>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <ThemeProvider theme={themee}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <MyAssignmentView/>
                    </TabPanel>
                </ThemeProvider>
                <ThemeProvider theme={themee}>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <StudentHomework/>
                    </TabPanel>
                </ThemeProvider>
            </SwipeableViews>
        </div>
    );
};
