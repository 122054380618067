import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import {assigningSubjectTeacher, sectionList, userList} from "../../../../utils/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListItemText from "@material-ui/core/ListItemText";
import {TextField} from "formik-material-ui";
import {Form, Formik} from "formik";
import {useHistory} from "react-router-dom";
import Loader from "../../../shared/Loader";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left',
        paddingBottom: '24px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        marginTop: 40,
    },
    title: {
        verticalAlign: 'sub',
        marginRight: '4px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: 50,
        float: 'left',
        marginBottom: 50,
    },
    card: {
        minHeight: 170,
        width: '100%',
    },
}));

export const SubjectTeacher = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const {user} = useContext(AppContext);
    const [subjects, setSubject] = React.useState([]);
    const [teachers, setTeachers] = React.useState([]);
    const [query, setQuery] = useState('');
    const [load, setLoad] = useState(false);
    const {profile_attributes: person} = user;

    useEffect(() => {
        fetchSubjects();
    }, []);

    useEffect(() => {
        fetchTeachers();
    }, [query]);

    const fetchSubjects = () => {
        (async function fetchData() {
            await sectionList(person.section_grade_id, person.section_id).then(response => {
                setSubject([...response.data.section['section_subjects']]);
            })
        })()
    };

    const fetchTeachers = () => {
        (async function fetchData() {
            await userList({q: query, roles: [2]}).then(response => {
                setTeachers([...response]);
            })
        })()
    };

    const handleChange = event => {
        setQuery(event.target.value);
    };

    const onSubmit = async (values, formikBag) => {
        setLoad(true);
        await assigningSubjectTeacher(values.section_subject.id, values).then(response => {
            setLoad(false);
            history.push('/sections');
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Subject Teacher assigned successfully'}});
        }).catch(({errors}) => {
            setLoad(false);
            dispatch({
                type: SNACKBAR_OPEN, payload: {message: errors, severity: 'error', duration: 5000}
            });
        })
    };

    const renderLabel = (user) => {
        if (!user) return '';
        const {profile_attributes: person} = user;
        return `${person && person.contact_person_attributes && person.contact_person_attributes.first_name}  ${person &&
        person.contact_person_attributes && person.contact_person_attributes.last_name}`;
    };

    const handleOptionChange = (e, value, formikBag) => {
        formikBag.setFieldValue(`section_subject[id]`, (value && value.id) || '', false)
    };

    const handleOptionTeacher = (e, value, formikBag) => {
        formikBag.setFieldValue(`section_subject[teacher_ids]`, ([value && value.id]) || '', false)
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <h5 onClick={history.goBack}>
                        <IconButton>
                            <ArrowBackIcon className={classes.title}/>
                        </IconButton>
                        Back {/*{roleFromParam.toUpperCase()}*/}
                    </h5>
                </Grid>
                <Grid item xs={12}>
                    <h4 className="{classes.header} ml-2 mt-4 mb-3">Assign Subject Teachers</h4>
                </Grid>
            </Grid>
            <Formik
                initialValues={{section_subject: {teacher_ids: [], id: []}}}
                onSubmit={onSubmit}
                //validationSchema={validationSchema}
                validateOnBlur={true}
            >
                {({isSubmitting, touched, values, ...formik}) => (
                    <Form>
                        <Card className={classes.card}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} className="mt-5 ml-2 mr-2">
                                    <Autocomplete
                                        id="checkboxes-tags-demo"
                                        options={subjects}
                                        //disableCloseOnSelect
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option => option.subject.name)}
                                        renderOption={({subject: subj}) =>
                                            (<ListItemText
                                                primary={`${subj.name}`}
                                            />)}
                                        onChange={(e, value) => handleOptionChange(e, value, formik)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="id"
                                                variant="outlined"
                                                label="Select Subject"
                                                //placeholder="Select Parents"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className="mt-5 ml-2 mr-2">
                                    <Autocomplete
                                        id="checkboxes-tags-demo"
                                        options={teachers}
                                        //disableCloseOnSelect
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={renderLabel}
                                        renderOption={({profile_attributes: person, email}) =>
                                            (<ListItemText
                                                primary={`${person && person.contact_person_attributes && person.contact_person_attributes.first_name}  
                                                ${person && person.contact_person_attributes && person.contact_person_attributes.last_name}`}
                                                secondary={email}/>)}
                                        onInputChange={handleChange}
                                        onChange={(e, value) => handleOptionTeacher(e, value, formik)}
                                        renderInput={(params) => (
                                            <TextField
                                                onChange={handleChange}
                                                {...params}
                                                name="teacher_ids"
                                                variant="outlined"
                                                label="Select Teacher"
                                                //placeholder="Select Parents"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}
                                            className="text-capitalize ml-2 mb-3" style={{marginTop: 55, width: 120}}>
                                        Assign
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Form>
                )}
            </Formik>
            {load && <Loader/>}
        </div>
    );
};
