import React, {useContext, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import './subject.scss'
import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";
import {AppContext} from "../../../../store";
import {sectionList} from "../../../../utils/api";
import {CircularProgress} from "@material-ui/core";

export const Subjects = (props) => {
    const history = useHistory();
    const {user} = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search);
    const roleFromParam = urlParams.get('parent');
    const [load, setLoad] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const {id: gradeId, secId: sectionId} = useParams();

    const navigateAssignment = (id) => {
        history.push(`/assignment/${id}`);
    };
    const {profile_attributes: person} = user;

    useEffect(() => {
        if (roleFromParam) {
            fetchParentsSubjects()
        } else {
            fetchSubjects();
        }
    }, []);

    const fetchSubjects = () => {
        (async function fetchData() {
            await sectionList(person.section_grade_id, person.section_id).then(response => {
                setSubjects([...response.data.section['section_subjects']]);
                setLoad(true);
            })
        })()
    };

    const fetchParentsSubjects = () => {
        (async function fetchData() {
            await sectionList(gradeId, sectionId).then(response => {
                setSubjects([...response.data.section['section_subjects']]);
                setLoad(true);
            })
        })()
    };

    return (
        <div style={{marginTop: 70}}>
            <h4 className="mt-4 ml-3" style={{float: "left"}}>Subjects</h4>
            {load ?
                <Grid container>
                    {subjects && subjects.length > 0 ? subjects.map(lists => (
                        <Grid item xs={12} md={4} key={lists.id}>
                            <Card className="assignment-card mt-4 hand-cursor"
                                  onClick={() => navigateAssignment(lists.id)}>
                                <CardHeader
                                    title={lists['subject'].name}
                                    className="card-head text-capitalize"
                                />
                                <CardContent className="card-content">
                                    <h6 style={{textAlign: 'justify'}} className="text-capitalize">
                                        Teacher: {lists.teachers?.[0]?.profile_attributes?.contact_person_attributes?.salutation}.{' '}
                                        {lists.teachers?.[0]?.profile_attributes?.contact_person_attributes?.first_name || 'Teacher'}{' '}
                                        {lists.teachers?.[0]?.profile_attributes?.contact_person_attributes?.last_name || 'Not Assigned'}</h6>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <IconButton aria-label="share">
                                        <FolderIcon/>
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    )) : <div align={'center'}><h1>No Data To Display</h1></div>}
                </Grid>
                : <div align={'center'}><CircularProgress style={{color: "red"}}/></div>}
        </div>
    );
};
