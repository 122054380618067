import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../store";
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {Link, useHistory} from 'react-router-dom'
import {gradeDelete, gradeList} from "../../utils/api";
import SharedDialog from "../shared/dialog";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    },
    year: {
        float: 'left',
        marginTop: 30,
        fontSize: 25,
    },
}));

export const ClassList = () => {
        const classes = useStyles();
        const {roles} = useContext(AppContext);
        const dispatch = useContext(DispatchContext);
        const history = useHistory();
        const [page, setPage] = useState(1);
        const [grades, setGrade] = useState([]);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [total, setTotal] = useState(0);
        const [clsId, setClsId] = useState('');
        const [load, onLoad] = useState(false);
        const [confirmationOpen, setConfirmationOpen] = useState(false);

        const handleChangePage = (event, newPage) => {
            setPage(newPage + 1);
        };

        useEffect(() => {
            fetchGrade();
        }, [page, rowsPerPage]);

        const fetchGrade = () => {
            (async function fetchData() {
                await gradeList({page: page, per_page: rowsPerPage}).then(response => {
                    setGrade(response.data.grades);
                    setTotal(response.data.meta.total);
                    onLoad(true);
                }).catch(err => {
                    onLoad(true);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                    });
                });
            })()
        };

        const handleChangeRowsPerPage = event => {
            setRowsPerPage(+event.target.value);
            setPage(1);
        };

        const handleClassEdit = (id) => {
            history.push(`/grade/create?edit=${true}&&id=${id}`);
        };

        const handleAssign = (id) => {
            history.push(`/assign-subject?id=${id}`);
        };
        const viewDetail = (gId, id) => {
            history.push(`/section-subjects/${gId}?id=${id}`)
        };

        const handleDelete = async (result, event) => {
            setConfirmationOpen(false);
            if (result) {
                await gradeDelete(clsId).then(response => {
                    fetchGrade();
                    dispatch({type: SNACKBAR_OPEN, payload: {message: 'Class deleted successfully'}});
                }).catch(err => {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                    });
                })
            }
        };
        const setDelete = (id) => {
            setClsId(id);
            setConfirmationOpen(true);
        };

        return (
            <div style={{marginTop: 70}}>
                {load ?
                    <>
                        <h3 className={classes.year}>Classes for the Academic
                            year {new Date().getFullYear()}</h3>
                        <div style={{float: 'right', margin: 25}}>
                            <Link to="/grade/create">
                                <Button variant="contained"
                                        color="primary"
                                        className="background-header"
                                        startIcon={<AddIcon fontSize="small"/>}
                                >
                                    Add
                                </Button>
                            </Link>
                        </div>
                        <TableContainer className={classes.root}>
                            <Table stickyHeader className={classes.table} aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Class Name</StyledTableCell>
                                        <StyledTableCell>Sections</StyledTableCell>
                                        <StyledTableCell align="center" colSpan={3}>
                                            Actions
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {grades.length ? grades.map((list) => (
                                        <StyledTableRow key={list.id} hover className="hand-cursor">
                                            <StyledTableCell>{list.name}</StyledTableCell>
                                            <StyledTableCell
                                                onClick={() => viewDetail(list.id, list.sections_attributes?.[0]?.id)}>
                                                {list.sections_attributes.length && list.sections_attributes.map(section => section.name).join(', ')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button className="text-capitalize"
                                                        style={{backgroundColor: "#2C3E50", color: "white"}}
                                                        variant="outlined"
                                                        onClick={() => handleAssign(list.id)}
                                                >
                                                    <AddIcon/>ASSIGN SUBJECT
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Tooltip title={'Edit'}>
                                                    <Button className="text-capitalize" variant="contained"
                                                            onClick={() => {
                                                                handleClassEdit(list.id)
                                                            }}>Edit</Button>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Tooltip title={'Delete'}>
                                                    <Button className="text-capitalize" variant="outlined"
                                                            onClick={() => setDelete(list.id)}
                                                            color="secondary">
                                                        <DeleteIcon/>DELETE
                                                    </Button>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : (<TableRow>
                                        <TableCell component="th" scope="row" colSpan={4}>
                                            <Typography align={"center"} variant={"h6"} component="div">
                                                No data to display
                                            </Typography>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={total || 0}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </> : (<CircularProgress color="secondary"/>)}
            </div>
        )
    }
;
