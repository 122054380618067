import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import './contact.scss'
import {SocialIcon} from 'react-social-icons';
import Typography from "@material-ui/core/Typography";

export const ContactUs = () => {

    return (
        <div className="contact" style={{padding: 20}}>
            <h4 className="mt-2 mb-2 light-green" style={{fontSize: 27}}>Contact Us</h4>
            <Grid container>
                <Grid item xs={12} md={4} className="mb-3 mt-2">
                    <Typography>
                        You can contact us through: <br/> <br/>
                        <a href="#">
                            Admissions: adm08@drukschool.com <br/>
                        </a>
                        <a href="#">
                            Finance Manager: laxman@drukschool.com
                        </a>
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <h4 className="mb-3 mt-2" style={{color: '#707070'}}>Follow Us</h4>
                    <div style={{textAlign: 'center'}}>
                        <span style={{marginRight: 60, marginLeft: 20}}>
                            <SocialIcon url="http://instagram.com" style={{color: 'blue', width: 30, height: 30}}/>
                        </span>
                        <span style={{marginRight: 60}}>
                           <SocialIcon url="https://facebook.com/dcpl.bt/"
                                       style={{color: 'blue', width: 30, height: 30}}/>
                        </span>
                        <span style={{marginRight: 60}}>
                            <SocialIcon url="http://linkedin.com" style={{color: 'blue', width: 30, height: 30}}/>
                        </span>
                        <span>
                            <SocialIcon url="http://twitter.com" style={{color: 'blue', width: 30, height: 30}}/>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} className="mt-3">
                    <h6>Telephone</h6>
                    <p>Principal +975-2-323985</p>
                    <p>Vice +975-2-326150</p>
                    <p>Fax: +975-2-325922</p>
                </Grid>
            </Grid>
        </div>
    )
};

