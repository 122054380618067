import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {eventDetails, eventList} from "../../utils/api";
import Button from "@material-ui/core/Button";
import {Link, useHistory} from "react-router-dom";
import RenderAuthorized from "../../components/render_authorized";
import Loader from "../shared/Loader";
import './schedules.scss';

const useStyles = makeStyles(({palette}) => ({
    headerContent: {
        textAlign: 'left'
    },
    title: {
        verticalAlign: 'sub',
        marginRight: '4px',
        marginBottom: 6,
        color: "black"
    },
    calendar: {
        padding: '16px',
    }
}));


const Schedules = () => {
    const classes = useStyles();
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [load, setLoad] = useState(false);


    const fetchEvents = () => {
        eventList().then(response => setEvents(response.data['events'].map(evt => {
            return ({
                id: evt.id,
                title: evt.title,
                start: evt.from_date,
                end: evt.to_date,
                color: evt.color,
            })
        })))
    };

    useEffect(() => {
        (async function () {
            await fetchEvents();
        })();
    }, []);

    const detailRoute = (event) => {
        setLoad(true);
        eventDetails('get', event.id).then(response => {
            setLoad(false);
            history.push(`/events/details/${event.id}`, {...response.event});
        })
    };

    return (
        <div style={{marginTop: 70}}>
            <Grid container className={classes.headerContent}>
                <Grid item xs={12} className="mt-2">
                    <RenderAuthorized authorized={['Admin']}>
                        <Button variant="contained"
                                className="background-header pull-right mb-3 mt-1 text-white text-capitalize"
                                component={Link}
                                to='/events/new'
                        >
                            Add new events
                        </Button>
                    </RenderAuthorized>
                    {/*<h2 className="text-capitalize p-2 text-white">*/}
                    {/*    <ScheduleIcon*/}
                    {/*        className={classes.title}*/}
                    {/*    />*/}
                    {/*    <span style={{color: "black"}}>Calendar</span>*/}
                    {/*</h2>*/}
                </Grid>
            </Grid>
            <Paper elevation={0} className={classes.calendar}>
                <FullCalendar plugins={[dayGridPlugin, listPlugin]}
                              events={events}
                              eventTimeFormat={{
                                  hour: 'numeric',
                                  minute: '2-digit',
                                  meridiem: 'short'
                              }}
                              header={{
                                  left: 'today, prev,next',
                                  center: 'title',
                                  right: 'dayGridMonth,dayGridWeek,listMonth'
                              }}
                              eventClick={(e) => detailRoute(e.event)}
                />
            </Paper>
            {load && <Loader/>}
        </div>
    );
};


export default Schedules;