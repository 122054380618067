import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import Card from "@material-ui/core/Card";
import {useHistory, useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import {homeSubmissionList, homeworkDetail} from "../../../../utils/assignApi";
import {CircularProgress} from "@material-ui/core";
import FileIcon from "@material-ui/icons/Description";
import parse from "html-react-parser";


const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 17,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(({palette}) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      padding: '16px'
    },
    eventDetail: {
      paddingBottom: '12px'
    },
    container: {
      maxHeight: 440,
    },
    eventImg: {
      width: 150,
      height: 150
    },
    actionBtn: {
      float: 'right'
    },
    eventBasic: {
      borderRight: '1px solid gray'
    },
    icon: {
      verticalAlign: 'middle',
      paddingRight: 3
    },
    otherImages: {
      overflowY: 'scroll',
      maxHeight: '215px',
      minHeight: '120px',
    },
    margin0: {
      margin: '0'
    },
    Table: {
      maxHeight: '440px'
    },
    fullWidth: {
      width: '100%',
    },
    ParticipantModal: {
      padding: '16px'

    },
    participantList: {
      minHeight: '64px',
      maxHeight: '300px',
      overflowY: 'scroll',
      marginTop: '12px'

    },

    Participant: {
      marginTop: '10px'
    },

    TableContainer: {
      display: 'flex',
    },
    table: {
      width: '100%'
    },
    button: {
      backgroundColor: '#D5F934',
      textTransform: 'capitalize',
      borderRadius: 25,
    },
    wrapper: {
      position: 'relative',
      paddingTop: '56.25%',
    },
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }),
);

export default function HomeworkCurrent() {
  const classes = useStyles();
  const history = useHistory();
  const {subId: subjectId, homeId: homeworkId} = useParams();
  const [load, setLoad] = useState(false);
  const [homework, setHomework] = useState({});
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    fetchHomework();
    fetchSubmission();
  }, []);

  const fetchHomework = () => {
    (async function fetchData() {
      await homeworkDetail(subjectId, homeworkId).then(response => {
        setLoad(true);
        setHomework(response.data['home_work']);
      })
    })()
  };

  const fetchSubmission = () => {
    (async function fetchData() {
      await homeSubmissionList(subjectId, homeworkId).then(response => {
        setSubmissions([...response.data['submissions']]);
      })
    })()
  };

  const viewHomework = (id) => {
    history.push(`/student-homework/${subjectId}/${homeworkId}?id=${id}`, {date: homework.due_date});
  };

  return (
    <div style={{marginTop: 70}}>
      <h4 className="mt-2 mb-3 pull-left light-green ml-2">Homework</h4>
      {load ?
        <Paper className={classes.root} elevation={0}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.eventBasic}>
              <h5>{homework.title}<br/>
                <span style={{fontSize: 14}}
                      className="ml-1">posted on: {new Date(homework.due_date).toDateString()}</span><br/>
                <span style={{fontSize: 14}}
                      className="ml-1">Total marks: {homework?.total_marks}</span>
              </h5>

              <Card>
                <Grid item xs={12} style={{marginTop: 15}}>
                  <h6>Homework content</h6>
                  <Typography>
                    {parse(homework.description || '')}
                  </Typography>
                </Grid>
                <Grid container>
                  {homework.photos_attributes && homework.photos_attributes.map(image => (
                    <Grid item xs={12} md={4} key={image?.id}>
                      <a href={image?.original}>
                        <img alt={''} className={classes.eventImg}
                             src={image?.large}/>
                      </a>
                    </Grid>
                  ))}
                </Grid>
                <Grid container>
                  {homework.attachments_attributes && homework.attachments_attributes.map(file => (
                    <Grid item xs={12} md={12} key={file?.id} className="mt-3 mb-3">
                      Documents:
                      <a href={file?.file_url} target="_blank" rel="noopener noreferrer">
                        {file?.filename}
                      </a>
                    </Grid>
                  ))}
                </Grid>
                {homework.videos_attributes && homework.videos_attributes.map(video => (
                  <div className={classes.wrapper} key={video?.id}>
                    <ReactPlayer
                      url={video?.clip_url}
                      className={classes.player}
                      controls={true}
                      youtubeConfig={{playerVars: {showinfo: 1}}}
                      width='100%'
                      height='100%'
                    />
                  </div>
                ))}
              </Card>
              <Card className="p-2 mt-2" style={{backgroundColor: '#03a9fc'}}>
                <Typography>
                  Submission Date: {new Date(homework.due_date).toDateString()}
                </Typography>
              </Card>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Tabs
                    value={0}
                    //onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Submitted Homeworks"/>
                  </Tabs>
                  <div>
                    <TableContainer className={classes.TableContainer}>
                      <Table stickyHeader className={classes.table} aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell/>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {submissions && submissions.length > 0 ? submissions.map((student, index) => (
                            <StyledTableRow hover className="hand-cursor" key={index}>
                              <StyledTableCell>{student?.user?.profile_attributes?.contact_person_attributes?.first_name}
                                {' '}{student?.user?.profile_attributes?.contact_person_attributes?.last_name}</StyledTableCell>
                              <StyledTableCell>submitted</StyledTableCell>
                              <StyledTableCell>
                                <Button variant="contained" className={classes.button}
                                        onClick={() => viewHomework(student?.id)}>View
                                  Detail</Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          )) : <StyledTableRow>
                            <StyledTableCell>
                              <Typography align={"center"} variant={"h6"}
                                          component={"div"}>
                                No data to display
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper> : <div align={'center'}><CircularProgress/></div>}
    </div>
  );
}
