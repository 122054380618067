import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {DropzoneArea} from "material-ui-dropzone";
import Button from "@material-ui/core/Button";


export const AssignHomework = () => {
    return (
        <div>
            <Card>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <TextField variant="outlined" label="Homework Title" className="mt-3"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField variant="outlined" label="Due Date" className="mt-3"/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={7}>
                        <TextareaAutosize aria-label="minimum height" rowsMin={5} placeholder="Homework contents"
                                          className="full-width mt-3 pull-left ml-5 mb-4"/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5} className="ml-5">
                        <DropzoneArea
                            maxFileSize={5000000}
                            filesLimit={4}
                            showPreviews={false}
                            showPreviewsInDropzone={true}
                            dropzoneClass="dropZone"
                            dropzoneText="Upload image"
                            acceptedFiles={['image/*']}
                            dropzoneParagraphClass="dropZoneDes"
                            className="dropZone"
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="ml-4">
                        <DropzoneArea
                            maxFileSize={5000000}
                            filesLimit={4}
                            showPreviews={false}
                            showPreviewsInDropzone={true}
                            dropzoneClass="dropZone"
                            dropzoneText="Upload video"
                            acceptedFiles={['video/*']}
                            dropzoneParagraphClass="dropZoneDes"
                            className="dropZone"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} className="mt-3 mb-3">
                        <Button variant="contained" style={{borderRadius: 20, backgroundColor: 'green'}} className="pull-left ml-5 text-capitalize text-white">Assign</Button>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
};
