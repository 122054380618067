import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select, TextField} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useEffect} from "react";
import useStyles from "../../styles";
import {ErrorMessage, useFormikContext} from "formik";
import {UserPicker} from "./user-picker";
import {FormHelperText} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const StudentForm = () => {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    //const [grades, setGrades] = useState([]);
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    const formik = useFormikContext();
    useEffect(() => {
        /*(async function () {
            await fetchGrades();
        })();*/
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    /*const fetchGrades = async () => {
        const {data: {grades: response}} = await classList();
        setGrades(response);
    };*/

    return (
        <>
            {/*<h4>Enter your Class and Section</h4>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel ref={inputLabel}>Class</InputLabel>
                        <Select labelWidth={labelWidth}
                                name="profile_attributes.class">
                            {grades.map((cls, index) =>
                                <MenuItem key={index} value={cls}>{cls.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                   /* <pre>
                        {JSON.stringify(formik.values.profile_attributes)}
                    </pre>
                    formik.values.profile_attributes.class &&
                    <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" className={classes.fullWidth}>
                            <InputLabel ref={inputLabel}>Section</InputLabel>
                            <Select labelWidth={labelWidth}
                                    name="profile_attributes.section_id">
                                {formik.values.profile_attributes.class.sections_attributes
                                    .map((section, index) =>
                                        <MenuItem key={index} value={section.id}>{section.name}</MenuItem>)}

                            </Select>
                        </FormControl>
                    </Grid>

            </Grid>*/}
            {/*<h4>Student Details</h4>*/}
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel ref={inputLabel}>Salutation</InputLabel>
                        <Select labelWidth={labelWidth}
                                name="profile_attributes.contact_person_attributes.salutation">
                            <MenuItem value="mr">Mr.</MenuItem>
                            <MenuItem value="ms">Ms.</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField id="first_name" name="profile_attributes.contact_person_attributes.first_name"
                               label="First Name" variant="outlined"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField id="last_name" name="profile_attributes.contact_person_attributes.last_name"
                               label="Last Name" variant="outlined"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="student_id" name="profile_attributes.contact_person_attributes.student_id"
                               label="Student ID" variant="outlined"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="cid" name="profile_attributes.contact_person_attributes.cid" label="Citizenship ID"
                               variant="outlined" type="number"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="phone" name="profile_attributes.contact_person_attributes.phone" label="Phone Number"
                               variant="outlined" type="number"
                               className={classes.fullWidth}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" type="password" label="Password"
                               variant="outlined"
                               name="password" className={classes.fullWidth}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic" type={values.showPassword ? 'text' : 'password'}
                               label="Confirmed Password" variant="outlined"
                               name="password_confirmation" className={classes.fullWidth}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           onMouseDown={handleMouseDownPassword}
                                       >
                                           {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                       </IconButton>
                                   </InputAdornment>,
                               }}/>
                </Grid>
            </Grid>
        </>)
};

export const GuardianDetails = () => {

    const classes = useStyles();
    const formik = useFormikContext();
    React.useEffect(() => {
    }, []);

    return (<div className="mt-2">
        {/* {JSON.stringify(formik.errors)}*/}
        {/*<h4>Select parent or guardian</h4>*/}
        <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
                <UserPicker value={formik.values['parent']}/>
                <ErrorMessage name='parent_id' component={FormHelperText} error={true}/>
            </Grid>
        </Grid>
        {/*<FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox color="primary" value="invite"/>}
                    label="Invite parent/guardian to the portal"/>
            </FormGroup>
        </FormControl>*/}
    </div>)
};