import React from 'react';
import {CircularProgress} from '@material-ui/core';
import './loader.scss';

export default function Loader() {
    return (
        <div className="preloader-wrapper">
            <CircularProgress className="preloader"/>
            {/*<Offline><h3 className="offline-text">You're offline right now. Check your connection.</h3></Offline>*/}
        </div>
    );
}
