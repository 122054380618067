import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {TextField} from "formik-material-ui";
import {DropzoneArea} from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {Editor} from '@tinymce/tinymce-react';
import {Form, Formik} from "formik";
import {DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import {cloneDeep} from "lodash-es";
import {objectToFormData as o2formdata} from "object-to-formdata";
import {
    assignmentSubmission,
    assignSubmissionDetail,
    assignSubmissionUpdate,
    homeSubmissionDetail,
    homeSubmissionUpdate,
    homeworkSubmission
} from "../../../../utils/assignApi";
import Loader from "../../../shared/Loader";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export const AnswerForm = (props) => {
    const dispatch = useContext(DispatchContext);
    const classes = useStyles();
    const history = useHistory();
    const [load, onLoad] = useState(false);
    const search = new URLSearchParams(props.location.search);
    const userId = search.get('userId');
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const task = params.get('task');
    const edit = params.get('edit');
    const [editVal, setEditVal] = useState({});
    const {subId: subjectId, id: assignmentId} = useParams();
    const [show, setShow] = useState(false);

    const handleEditorChange = (content, editor, formikBag) => {
        formikBag.setFieldValue(`description`, content);
    };

    useEffect(() => {
        if (edit) {
            fetchingTask();
        } else {
            setShow(true);
        }
    }, []);
    const fetchingTask = () => {
        if (task === "assignment" && edit) {
            (async function fetchData() {
                await assignSubmissionDetail(subjectId, assignmentId, id).then(response => {
                    setEditVal(response.data.submission);
                    setShow(true);
                }).catch(error => {
                    setShow(true);
                })
            })()
        } else if (task === "home_work" && edit) {
            (async function fetchData() {
                await homeSubmissionDetail(subjectId, assignmentId, id).then(response => {
                    setEditVal(response.data.submission);
                    setShow(true);
                }).catch(error => {
                    setShow(true);
                })
            })()
        }
    };


    const onSubmit = async (values, formikBag) => {
        onLoad(true);
        const data = cloneDeep(values);
        const formData = o2formdata(data, {indices: true}, null, `${task}[submissions_attributes][0]`);
        if (task === "assignment") {
            await assignmentSubmission(subjectId, assignmentId, formData).then(response => {
                onLoad(false);
                history.push(`/assignment/${subjectId}`);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Assignment submitted successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        } else if (task === "home_work") {
            await homeworkSubmission(subjectId, assignmentId, formData).then(response => {
                onLoad(false);
                history.push(`/assignment/${subjectId}`);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Homework submitted successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const onUpdate = async (values, formikBag) => {
        onLoad(true);
        const data = cloneDeep(values);
        const formData = o2formdata(data, {indices: false}, null, `submission`);
        if (task === "assignment") {
            await assignSubmissionUpdate(subjectId, assignmentId, id, formData).then(response => {
                onLoad(false);
                history.push(`/assignment/${subjectId}`);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Assignment Updated successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        } else if (task === "home_work") {
            await homeSubmissionUpdate(subjectId, assignmentId, id, formData).then(response => {
                onLoad(false);
                history.push(`/assignment/${subjectId}`);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Homework update successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const fileImageVideoUpload = (files, single, type, formikBag) => {
        const existingImage = formikBag?.values?.photos_attributes?.findIndex(photo => photo.default);
        const existingFile = formikBag?.values?.attachments_attributes?.findIndex(file => file.default);
        const existingVideo = formikBag?.values?.videos_attributes?.findIndex(video => video.default);
        if (single) {
            if (existingImage >= 0 || existingFile >= 0 || existingVideo >= 0) {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes[${existingImage}].image`, files[0]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes[${existingVideo}].clip`, files[0]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes[${existingFile}].file`, files[0]);
                }
            } else {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes`, [...formikBag.values.photos_attributes, {
                        image: files[0],
                        default: true
                    }]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes`, [...formikBag.values.videos_attributes, {
                        clip: files[0],
                        default: true
                    }]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes`, [...formikBag.values.attachments_attributes, {
                        file: files[0],
                        default: true
                    }]);
                }
            }
        } else {
            if (type === 'image') {
                formikBag.setFieldValue(`photos_attributes`, [...files.map(file => ({image: file}))]);
            } else if (type === 'video') {
                formikBag.setFieldValue(`videos_attributes`, [...files.map(file => ({clip: file}))]);
            } else if (type === 'file') {
                formikBag.setFieldValue(`attachments_attributes`, [...files.map(file => ({file: file}))]);
            }

            if (existingImage >= 0 || existingFile >= 0 || existingVideo >= 0) {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes[${existingImage}]`, formikBag.values.photos_attributes[existingImage]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes[${existingVideo}]`, formikBag.values.videos_attributes[existingVideo]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes[${existingFile}]`, formikBag.values.attachments_attributes[existingFile]);
                }

            }
        }
    };

    return (
        <div className={classes.root} style={{marginTop: 70}}>
            {show &&
            <Formik initialValues={edit ? editVal : {
                title: '',
                photos_attributes: [],
                description: '',
                attachments_attributes: []
            }}
                    onSubmit={edit ? onUpdate : onSubmit}
                    validateOnBlur={true}
            >
                {({isSubmitting, values, touched, handleSubmit, errors, ...formik}) => (
                    <Form>
                        <Grid container>
                            <Grid item xs={12} md={4} className="mt-3 ml-2">
                                <TextField name="title" variant="outlined" label="Title"
                                           className="full-width pull-left"/>
                            </Grid>
                            <Grid item xs={12} md={11} className="mt-3 ml-2">
                                <Card>
                                    <Editor
                                        apiKey="ih03pokstaq7qpwlunorzqjmy81fcacgdrl1b2a8mzvq5ued"
                                        initialValue={values.description}
                                        init={{
                                            height: 320,
                                            width: '100%',
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={(content, editor) => handleEditorChange(content, editor, formik)}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={5} className="ml-2">
                                <DropzoneArea
                                    maxFileSize={5000000}
                                    filesLimit={4}
                                    showPreviews={false}
                                    //initialFiles={[...values?.photos_attributes?.map(val => val?.large)]}
                                    showAlerts={false}
                                    showPreviewsInDropzone={true}
                                    dropzoneClass="dropZone"
                                    dropzoneText="Upload image"
                                    acceptedFiles={['image/*']}
                                    dropzoneParagraphClass="dropZoneDes"
                                    className="dropZone"
                                    onChange={(files) => fileImageVideoUpload(files, false, 'image', formik)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5} className="ml-2">
                                <DropzoneArea
                                    maxFileSize={5000000}
                                    filesLimit={4}
                                    showPreviews={false}
                                    showAlerts={false}
                                    useChipsForPreview={true}
                                    dropzoneClass="dropZone"
                                    dropzoneText="Upload file"
                                    dropzoneParagraphClass="dropZoneDes"
                                    className="dropZone"
                                    onChange={(files) => fileImageVideoUpload(files, false, 'file', formik)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className="mt-3 mb-3">
                                <Button variant="contained" type="submit" color="primary"
                                        style={{borderRadius: 20, width: 200}} disabled={isSubmitting}
                                        className="pull-left text-capitalize">
                                    {edit ? 'Update' : 'Submit'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>}
            {load && <Loader/>}
        </div>
    )
};
