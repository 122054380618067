import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

const ConfirmDialog = ({open, handleAction}) => {
    return (<Dialog
        open={open}
        onClose={(e) => handleAction(false, e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
    >
        <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={(e) => handleAction(true, e)} color="primary" variant={"contained"}>
                Yes
            </Button>
            <Button onClick={(e) => handleAction(false, e)} color="primary" variant={"outlined"} autoFocus>
                No
            </Button>
        </DialogActions>
    </Dialog>)
};

export default ConfirmDialog;