import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";
import {deleteHomework, homeworkList} from "../../../../utils/assignApi";
import {CircularProgress, Typography} from "@material-ui/core";
import {DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import SharedDialog from "../../../shared/dialog";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    button: {
        backgroundColor: '#D5F934',
        textTransform: 'capitalize',
        borderRadius: 25,
    }
}));

export default function HomeworkList() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const grade = params.get('grade');
    const section = params.get('grade');
    const {id: subjectId} = useParams();
    const [load, setLoad] = useState(false);
    const [homeworks, setHomeworks] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [ids, setIds] = useState('');

    useEffect(() => {
        fetchHomework();
    }, []);

    const fetchHomework = () => {
        (async function fetchData() {
            await homeworkList(subjectId, {filter: 'current'}).then(response => {
                setLoad(true);
                setHomeworks([...response.data['home_works']]);
            }).catch(err => {
                setLoad(true);
            })
        })()
    };

    const handleDelete = async (result) => {
        setConfirmationOpen(false);
        if (result) {
            await deleteHomework(subjectId, ids).then(response => {
                fetchHomework();
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Homework deleted successfully'}});
            }).catch(err => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const onClickDelete = (id) => {
        setIds(id);
        setConfirmationOpen(true);
    };

    const viewHomework = (id) => {
        history.push(`/current-homework/${subjectId}/${id}`)
    };
    const addHomework = () => {
        history.push(`/assigning-task/${subjectId}?task=home_work`)
    };

    const handleEdit = (id) => {
        history.push(`/assigning-task/${subjectId}/${id}?task=home_work&&edit=${true}`)
    };

    return (
        <div>
            {load ?
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button variant="contained" className="mr-2 mb-2 text-capitalize pull-right"
                                    style={{borderRadius: 30}}
                                    onClick={addHomework}>Add New</Button>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Homework Title</StyledTableCell>
                                    <StyledTableCell>Submission Date</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>
                                        Actions
                                    </StyledTableCell>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {homeworks && homeworks.length > 0 ? homeworks.map(homework => (
                                    new Date(homework.due_date) > new Date() &&
                                    <StyledTableRow hover className="hand-cursor" key={homework.id}>
                                        <StyledTableCell>{homework.title}</StyledTableCell>
                                        <StyledTableCell>{new Date(homework.due_date).toDateString()}</StyledTableCell>
                                        <StyledTableCell onClick={() => handleEdit(homework.id)}>
                                            <Tooltip title="Edit">
                                                <EditIcon/>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => onClickDelete(homework.id)}>
                                            <Tooltip title="Delete">
                                                <DeleteIcon/>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button variant="contained" className={classes.button}
                                                    onClick={() => viewHomework(homework.id)}>View Detail</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : <StyledTableRow>
                                    <StyledTableCell>
                                        <Typography align={"center"} variant={"h6"} component={"div"}>
                                            No data to display
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>
                </> : <div align={'center'}><CircularProgress/></div>}
            {/*<Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>*/}
        </div>
    )
}
