import React, {useContext, useEffect, useState} from "react";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router";
import {AppContext} from "../../../../../store";
import {homeSubmissionList, homeworkDetail} from "../../../../../utils/assignApi";
import {CircularProgress} from "@material-ui/core";
import ReactPlayer from "react-player";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useHistory} from "react-router-dom";
import Moment from "react-moment";
import parse from "html-react-parser";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        paddingTop: '56.25%',
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));

export const StudentHomeworkDetail = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const {user} = useContext(AppContext);
    const [load, setLoad] = useState(false);
    const [homework, setHomework] = useState([]);
    const {id: subjectId, homeId: homeworkId} = useParams();
    const [date, setDate] = useState({});

    useEffect(() => {
        fetchHomework();
    }, []);

    const fetchHomework = () => {
        (async function fetchData() {
            await homeSubmissionList(subjectId, homeworkId).then(response => {
                //setDate(response.data['home_work']);
                setHomework([...response.data['submissions']]);
                setLoad(true);
            })
        })()
    };

    return (
        <div style={{marginTop:70}}>
            {load ?
                <>
                    {homework && homework.length > 0 ? homework.map(work => (
                        work.user_id === user.id &&
                        <div key={work.id}>
                            <Grid item lg={2} style={{marginTop: 30}} className="ml-0">
                                <Button>
                                    <Tooltip title="Back">
                                        <ArrowBackIcon onClick={() => history.goBack()}/>
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid container>
                                <Grid item xs={7}>
                                    <Typography className="mt-2 mb-2 " component={'div'}>
                                        <h6 style={{float:'left'}}>{work.title}
                                        </h6>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <span
                                          style={{fontSize: 14, opacity: 0.7, float:'left'}}>Posted: <Moment format="LLl" withTitle>{work?.created_at}</Moment></span>
                                </Grid>
                            </Grid>
                            <Card className="card pt-2 pb-2">
                                <h6 className="pull-left">{work.title}</h6>
                                <Typography>
                                    {parse(work.description || '')}
                                </Typography>
                                <Grid container>
                                    {work.attachments_attributes.map(files => (
                                        <Grid item xs={12} md={2} key={files.id}>
                                            Document:
                                            <a href={files.file_url} target="_blank" rel="noopener moreferrer">
                                                {files?.filename}
                                            </a>
                                        </Grid>
                                    ))}
                                    {work.photos_attributes.map(image => (
                                        <Grid item xs={12} md={2} key={image.id}>
                                            <a href={image.original} target="_blank" rel="noopener noreferrer">
                                                <img src={image.large} alt="" style={{width: 150, height: 150}}/>
                                            </a>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container>
                                    {work.videos_attributes.map(video => (
                                        <Grid item xs={12} md={6} className="p-3" key={video.id}>
                                            <div className={classes.wrapper} data-vimeo-url={video.clip_url}>
                                                <ReactPlayer
                                                    className={classes.player}
                                                    url={video.clip_url}
                                                    controls={true}
                                                    youtubeConfig={{playerVars: {showinfo: 1}}}
                                                    //playing
                                                    width='100%'
                                                    height='100%'
                                                />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                            {/*<Card className="mt-2 p-2" style={{backgroundColor: '#03a9fc'}}>
                                <Typography className="pull-left">Submission
                                    Date: {new Date(date.due_date).toDateString()}</Typography>
                            </Card>*/}
                        </div>
                    )) : <div align={'center'}><h1>No Data To Display</h1></div>}
                </> : <div align={'center'}><CircularProgress/></div>}
        </div>
    )
};
export default StudentHomeworkDetail