import React, {useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {TextField} from 'formik-material-ui';
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {createEventCategory} from "../../utils/api";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export default function CreateCategory(props) {
    const {handleClose, open} = props;
    const initialValues = {name: '', description: ''};
    const onSubmit = async (values, formikBag) => {
        const newCategory = await createEventCategory(undefined, {event_category: values});
        handleClose(newCategory);
    };

    const validationSchema = Yup.object().shape({name: Yup.string().min(3, 'Too short').required('Enter a category name!')});

    useEffect(() => {

    }, [open]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({isSubmitting, touched, values, setFieldValue, errors, ...formik}) => {
                    return (<Form>
                        <Dialog keepMounted={false}
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={open} fullScreen={fullScreen}
                        >
                            <DialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Add new category
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Category name"
                                                   name="name" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Description"
                                                   name="description" variant="outlined"/>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" variant="contained" onClick={formik.submitForm}
                                        disabled={isSubmitting}>
                                    SAVE
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>)
                }}
            </Formik>
        </div>
    );
}
