export default {
    API_URL: process.env['REACT_APP_API_URL'],
    roles: [
        'teacher',
        'student',
        'staff',
        'parent',
        'admin'
    ],

    invite_roles: [
        'staff',
        'student',
        'parent'
    ]
}