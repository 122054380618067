import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {TextField} from "formik-material-ui";
import {Form, Formik} from "formik";
import {roleCreate, roleShow, roleUpdate} from "../../../utils/api";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";
import * as Yup from 'yup';
import {useHistory} from "react-router-dom";

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Field is required')
});

export const Role = (props) => {
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const urlParams = new URLSearchParams(props.location.search);
    const editId = '' + urlParams.get('edit');
    const [role, setRole] = useState({});
    const [show, setShow] = useState(false);
    useEffect(() => {
        (async function fetchData() {
            if (editId !== 'null') {
                await fetchRole();
            } else {
                setShow(true);
            }
        })()
    }, []);

    const fetchRole = async () => {
        await roleShow(parseInt(editId)).then(response => {
            setRole(response.data.role);
            setShow(true)
        }).catch(err => {
            setShow(true)
        })
    };

    const onUpdate = async (values) => {
        await roleUpdate({role: {name: values.name}}, parseInt(editId)).then(response => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Role Updated successfully'}});
            history.push(`/preferences?id=1`);
        }).catch(errors => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: errors, severity: 'error', duration: 5000}
            });
        })
    };

    const onSubmit = async (values) => {
        await roleCreate({role: {name: values.name}}).then(response => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Role Created successfully'}});
            history.push(`/preferences?id=1`);
        }).catch(errors => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: errors, severity: 'error', duration: 5000}
            });
        })
    };

    return (
        <div style={{marginTop: 70}}>
            {show &&
            <Formik initialValues={editId ? {name: role.name} : {name: ''}}
                    onSubmit={editId !== 'null' ? onUpdate : onSubmit}
                    validationSchema={ValidationSchema}>
                {({isSubmitting, touched, errors, value}) => (
                    <Form>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <TextField name="name" label="Role Name" className="full-width" variant="outlined"
                                           style={{float: 'left'}}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button variant="contained" type="submit" style={{borderRadius: 20, width: 130}}
                                        color="primary" disabled={isSubmitting}
                                        className="background-header text-capitalize mt-3">
                                    {editId !== 'null' ? 'Update' : 'Create'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            }
        </div>
    );
};

export default Role
