import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Current from "./current";
import AssignmentPost from "../../posted-work/assignment";
import Upcoming from "./upcoming";
import AssignmentList from "./assignment-list";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    current: {
        borderRadius: 30,
        margin: 5,
        backgroundColor: 'yellow'
    },
    closed: {
        borderRadius: 30,
        margin: 5,
        backgroundColor: 'green',
        color: 'white'
    },
    upcoming: {
        borderRadius: 30,
        margin: 5,
        backgroundColor: 'blue',
        color: 'white'
    },
}));

export const TeacherTab = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Current" {...a11yProps(0)}/>
                    <Tab label="Closed" {...a11yProps(1)}/>
                    {/*<Tab label="Upcoming" {...a11yProps(2)} className={classes.upcoming}/>*/}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <AssignmentList/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <AssignmentPost/>
                </TabPanel>
                {/*<TabPanel value={value} index={2} dir={theme.direction}>
                    <Upcoming/>
                </TabPanel>*/}
            </SwipeableViews>
        </div>
    );
};
export default TeacherTab
