import {createMuiTheme} from '@material-ui/core/styles';
import green from "@material-ui/core/colors/green";
import shadows from "@material-ui/core/styles/shadows";

export const CustomTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#2584af',
            dark: '#00577f',
            light: '#62b4e1',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#a32188',
            dark: '#71005b',
            light: '#d758b8',
            contrastText: '#ffffff',
            red: "red"
        }
    },
    status: {
        warning: 'orange',
        success: 'green'
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            root: {
                padding: '8px 24px',
            },
            // Name of the rule
            contained: {
                textTransform: 'none',
                boxShadow: shadows[0],
                '&:active': {
                    boxShadow: shadows[0]
                },
                '&:focused': {
                    boxShadow: shadows[0]
                },
                '&:hover': {
                    boxShadow: shadows[0]
                }
            },
        },
    }
});
export default CustomTheme;

/*Theme generator link https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=2584af&secondary.color=a32188&secondary.text.color=FFFFFF&primary.text.color=ffffff*/