import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {AppContext} from "../store";

const NavHeader = ({collapsed}) => {
    const {user: {email, role_ids, profile_attributes: {contact_person_attributes, ...profile}}} = useContext(AppContext);
    return (
        <>
            <div className="nav-background" style={{padding: collapsed ? 8 : 16, transition: "0.3s"}}>
                <Avatar
                    style={{
                        width: collapsed ? 48 : 60,
                        height: collapsed ? 48 : 60,
                        transition: "0.3s",
                        margin: 'auto',
                        backgroundColor: "white"
                    }}
                    src={(profile.photo_attributes && profile.photo_attributes.medium) || 'https://i.pravatar.cc/60'}
                />
                <div style={{paddingBottom: 16}}/>
                {!collapsed && <>
                    <Typography variant={"h6"} style={{color: "black", fontSize: 15}} noWrap>
                        {contact_person_attributes?.first_name} {contact_person_attributes.last_name}
                    </Typography>
                    <Typography color={"textSecondary"} style={{color: "black", fontSize: 12}} noWrap gutterBottom>
                        {email}
                    </Typography>
                    <p className="mb-2">{role_ids?.[0]?.name}</p>
                </>
                }
            </div>
            <Divider/>
        </>
    )
};

NavHeader.propTypes = {
    collapsed: PropTypes.bool
};
NavHeader.defaultProps = {
    collapsed: false
};

export default NavHeader;
