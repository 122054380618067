import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select, TextField} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import {ErrorMessage} from "formik";
import {FormHelperText} from "@material-ui/core";
import useStyles from "../../styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const BasicInfo = () => {

    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (

        <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel}>Salutation</InputLabel>
                    <Select labelWidth={labelWidth} name="profile_attributes.contact_person_attributes.salutation">
                        <MenuItem value="mr">Mr.</MenuItem>
                        <MenuItem value="ms">Ms.</MenuItem>
                        <MenuItem value="mrs">Mrs.</MenuItem>
                    </Select>
                    <ErrorMessage name="profile_attributes.contact_person_attributes.salutation"
                                  component={FormHelperText}
                                  error={true}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField name="profile_attributes.contact_person_attributes.first_name" label="First Name"
                           variant="outlined"
                           className={classes.fullWidth}/>
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField label="Last Name" variant="outlined"
                           className={classes.fullWidth}
                           name="profile_attributes.contact_person_attributes.last_name"/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" label="Designation" variant="outlined"
                           className={classes.fullWidth}
                           name="profile_attributes.contact_person_attributes.designation"/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" label="Citizenship ID" variant="outlined" type="number"
                           className={classes.fullWidth}
                           name="profile_attributes.contact_person_attributes.cid"/>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" label="Email ID" variant="outlined"
                           name="email"
                           className={classes.fullWidth}/>
            </Grid>*/}
            <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" label="Phone Number" variant="outlined" type="number"
                           className={classes.fullWidth}
                           name="profile_attributes.contact_person_attributes.phone"/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" type="password" label="Password"
                           variant="outlined"
                           name="password" className={classes.fullWidth}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField id="outlined-basic" type={values.showPassword ? 'text' : 'password'}
                           label="Confirmed Password" variant="outlined"
                           name="password_confirmation" className={classes.fullWidth}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">
                                   <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={handleClickShowPassword}
                                       onMouseDown={handleMouseDownPassword}
                                   >
                                       {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                   </IconButton>
                               </InputAdornment>,
                           }}/>
            </Grid>
        </Grid>)
};