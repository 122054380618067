import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import {useHistory} from "react-router-dom";
import {sectionList} from "../../../../utils/api";
import {CircularProgress} from "@material-ui/core";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    Button: {
        backgroundColor: '$success-color'
    }
}));

export default function SubjectTeacherList(props) {
    const classes = useStyles();
    const history = useHistory();
    const gradeId = props && props.list && props.list['section_grade_id'];
    const sectionId = props && props.list && props.list['section_id'];
    const [load, setLoad] = useState(false);
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        fetchClassList();
    }, []);

    const fetchClassList = () => {
        (async function fetchData() {
            await sectionList(gradeId, sectionId).then(response => {
                setLoad(true);
                setTeachers([...response.data.section['section_subjects']]);
            });
        })()
    };

    return (
        <div>
            {load ?
                <TableContainer className={classes.root}>
                    {teachers && teachers.length > 0 ?
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Id</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Subject</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teachers.map(lists => (
                                    <StyledTableRow hover className="hand-cursor" key={lists.id}>
                                        <StyledTableCell>{lists.id}</StyledTableCell>
                                        <StyledTableCell>{lists.teachers?.[0]?.profile_attributes?.contact_person_attributes?.first_name || 'No'}{' '}
                                            {lists.teachers?.[0]?.profile_attributes?.contact_person_attributes?.last_name || 'Name'}
                                        </StyledTableCell>
                                        <StyledTableCell>{lists.subject.name}</StyledTableCell>
                                        {/*<StyledTableCell>
                                    <Button variant="outlined" color="default">Edit</Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button variant="outlined" color="secondary">Delete</Button>
                                </StyledTableCell>*/}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        : <div align={'center'}><h1>No Data To display</h1></div>}
                </TableContainer>
                : <div align={'center'}><CircularProgress/></div>}
        </div>
    )
}

