import React, {useContext, useEffect, useState} from "react";
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../../store";
import {eventParticipants, participantsActions} from "../../../utils/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";

export const JoinButton = ({event, callback}) => {
    const [booked, setBooked] = useState(false);
    const {user} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const dispatch = useContext(DispatchContext);
    const [participant, setParticipant] = useState(null);
    useEffect(() => {
        const handler = setTimeout(fetchParticipants, 1000);
        return () => {
            clearTimeout(handler)
        }
    }, []);

    const fetchParticipants = () => {
        (async function fetchData() {
            const {participants} = await eventParticipants(event.id);
            setParticipant(prevState => {
                const foundParticipant = participants.find(participant => participant.user_id === user.id);
                setBooked(!!foundParticipant);
                return foundParticipant;
            });

        })()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        try {
            setOpen(false);
            const {participant: result} = await participantsActions(event.id, {
                id: participant && participant.id,
                participant: {user_id: user.id}
            });
            setParticipant(_ => !booked ? result : null);
            setBooked(!booked);

            dispatch({type: SNACKBAR_OPEN, payload: {message: !booked ? 'Joined event' : 'Cancelled booking'}});
            callback(true);
        } catch ({errors}) {
            setOpen(false);
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: (errors && errors[0]) || 'We are facing an issue!', severity: 'error'}
            });
            callback(false);
        }
    };

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant={"outlined"} autoFocus>
                    No
                </Button>
                <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
        {booked !== null ?
            <Button variant="contained" color="primary" className="background-header" fullWidth={true}
                    onClick={() => setOpen(true)}>
                {booked ? 'Cancel booking' : 'Join event'}
            </Button> :
            <Grid container justify={"center"}><CircularProgress size={20}/></Grid>}
    </>);
};
