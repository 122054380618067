import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {homeSubmissionList, homeworkDetail} from "../../../../utils/assignApi";
import {CircularProgress} from "@material-ui/core";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    button: {
        backgroundColor: '#D5F934',
        textTransform: 'capitalize',
        borderRadius: 25,
    }
}));

export default function ClosedHomework(props) {
    const classes = useStyles();
    const history = useHistory();
    const search = new URLSearchParams(props.location.search);
    const dueDate = props?.location?.state?.date;
    const homeworkId = search.get('homeId');
    const [load, setLoad] = useState(false);
    const [homework, setHomework] = useState([]);
    const {id: subjectId} = useParams();

    useEffect(() => {
        fetchHomework();
    }, []);

    const fetchHomework = () => {
        (async function fetchData() {
            await homeSubmissionList(subjectId, homeworkId).then(response => {
                setHomework([...response.data['submissions']]);
                setLoad(true);
            })
        })()
    };

    const viewHomework = (id) => {
        history.push(`/student-homework/${subjectId}/${homeworkId}?id=${id}`, {date: dueDate});
    };

    return (
        <div style={{marginTop:70}}>
            {load ?
                <TableContainer className={classes.root}>
                    <Table stickyHeader className={classes.table} aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {homework && homework.map(lists => (
                                <StyledTableRow hover className="hand-cursor" key={lists.id}>
                                    <StyledTableCell>{lists.user?.profile_attributes?.contact_person_attributes?.first_name}
                                        {lists.user?.profile_attributes?.contact_person_attributes?.last_name}</StyledTableCell>
                                    <StyledTableCell>Submitted</StyledTableCell>
                                    <StyledTableCell>
                                        <Button variant="contained" className={classes.button}
                                                onClick={() => viewHomework(lists.id)}>View</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <div align={'center'}><CircularProgress/></div>}
            {/*<Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>*/}
        </div>
    )
}
