import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Form, Formik} from "formik";
import Grid from "@material-ui/core/Grid";
import {TextField} from "formik-material-ui";
import {useTheme} from "@material-ui/core/styles/index";
import useMediaQuery from "@material-ui/core/useMediaQuery/index";
import {profileUpdate} from "../../../utils/api";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export default function EditProfile(props) {
    const {handleClose, open, type, data} = props;
    const [editProfile, setEditProfile] = useState({});

    useEffect(() => {
        if (data.profile) {
            setEditProfile({profile: data.profile, pm: data.pm_address, pr: data.pr_address, email: data.email});
        }
    }, []);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onSubmit = async (values) => {
        await profileUpdate({user: {profile_attributes: {contact_person_attributes: {...values}}}}).then(res => {
            handleClose();
        })
    };

    return (
        <div>
            {open && type === 'profile' &&
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} fullScreen={fullScreen}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Edit Profile
                </DialogTitle>
                <DialogContent dividers>
                    <Formik initialValues={editProfile.profile}
                            validateOnBlur={true}
                            onSubmit={onSubmit}
                    >
                        {({isSubmitting, touched, errors, ...formik}) => (
                            <Form autoComplete="off" noValidate>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="First name"
                                                   name="first_name" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Last name"
                                                   name="last_name" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="CID"
                                                   name="cid" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Phone Number"
                                                   name="phone" type="number" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Designation"
                                                   name="designation" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField fullWidth label="Email Address"
                                                   name="email" variant="outlined"/>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Button type="submit"
                                            color="primary"
                                            variant="contained"
                                            className="background-header"
                                    >
                                        UPDATE
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            }
        </div>
    );
}
