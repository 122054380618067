import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import FileIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {Link, useHistory} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router";
import {homeworkDetail} from "../../../../../utils/assignApi";
import ReactPlayer from "react-player";
import {makeStyles} from "@material-ui/core/styles";
import RenderAuthorized from "../../../../../components/render_authorized";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import parse from "html-react-parser";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const CurrentStudentHomework = () => {
  const classes = useStyles();
  const history = useHistory();
  const {id: subjectId, homeId: homeworkId} = useParams();
  const [homework, setHomework] = useState({});
  const [load, setLoad] = useState(false);
  const [subId, setSubId] = useState('');

  useEffect(() => {
    fetchHomeworks();
  }, []);

  const fetchHomeworks = () => {
    (async function fetchData() {
      await homeworkDetail(subjectId, homeworkId).then(response => {
        setLoad(true);
        setSubId(response.data.home_work.submissions?.[0]?.id);
        setHomework(response.data['home_work']);
      })
    })()
  };

  const answerForm = () => {
    history.push(`/answer/${subjectId}/${homeworkId}?task=home_work`)
  };

  return (
    <div style={{marginTop: 70}}>
      {load ?
        <div>
          <Grid item lg={2} style={{marginTop: 30}} className="ml-0">
            <Button>
              <Tooltip title="Back">
                <ArrowBackIcon onClick={() => history.goBack()}/>
              </Tooltip>
            </Button>
          </Grid>
          <Grid container>
            <Grid item xs={7}>
              <Typography className="mt-2 mb-2">
                <h6 style={{float: 'left'}}>{homework.title}
                </h6>
              </Typography>
              <Grid item xs={7}>
                            <span className="ml-0 mb-2"
                                  style={{fontSize: 14, opacity: 0.7, float: 'left'}}>Posted: <Moment format="LLL"
                                                                                                      withTitle>{homework?.created_at}</Moment></span>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Typography className="mb-2 ml-0 pull-left">
                <h6> Total marks: {homework.total_marks}</h6>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} md={6}>
              <Card className="card pt-2 pb-2">
                <h6 className="pull-left">{homework.title}</h6>
                <Typography>
                  {parse(homework?.description || '')}
                </Typography>
                <Grid container>
                  {homework.attachments_attributes && homework.attachments_attributes.map(files => (
                    <Grid item xs={12} md={12} key={files.id}>
                      Documents:
                      <a href={files.file_url} target='_blank' rel="noopener noreferrer"
                      >
                        {files?.filename}
                      </a>
                    </Grid>
                  ))}
                  {homework.photos_attributes && homework.photos_attributes.map(image => (
                    <Grid item xs={12} md={2} key={image.id}>
                      <a href={image.original}
                         target='_blank'
                         rel="noopener">
                        <img src={image.large} alt="" style={{width: 150, height: 150}}/>
                      </a>
                    </Grid>
                  ))}
                </Grid>
                <Grid container>
                  {homework.videos_attributes && homework.videos_attributes.map(videos => (
                    <Grid item xs={12} md={6} key={videos.id}>
                      <div className={classes.wrapper} data-vimeo-url={videos.clip_url}>
                        <ReactPlayer
                          className={classes.player}
                          url={videos.clip_url}
                          controls={true}
                          //youtubeConfig={{playerVars: {showinfo: 1}}}
                          //playing
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12} md={6}>
              <Card className="card pt-2 pb-2">
                <Typography>
                  <h5>My Submission</h5>
                </Typography>
                <Button color="primary" className="edit-btn"
                        component={Link}
                        to={{pathname: `/answer/${subjectId}/${homeworkId}?task=home_work&&edit=${true}&&id=${subId}`}}>
                  <EditIcon/> Edit
                </Button>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={12}>
                    <h6 style={{textTransform: 'capitalize'}}>
                      <b>{homework?.submissions?.[0]?.title}</b></h6>
                    <Typography style={{textAlign: "left"}} className="p-3">
                      {parse(homework?.submissions?.[0]?.description || '')}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12} className="p-2">
                    {homework?.submissions?.[0]?.attachments_attributes && homework?.submissions?.[0]?.attachments_attributes.map(files => (
                      <Grid item xs={12} md={12} key={files.id}>
                        Document:
                        <a href={files.file_url} target="_blank" rel="noopener noreferrer"
                        >
                          {files?.filename}
                        </a>
                      </Grid>
                    ))}
                    {homework?.submissions?.[0]?.photos_attributes && homework?.submissions?.[0]?.photos_attributes.map(images => (
                      <Grid item xs={12} md={2} key={images.id}>
                        <a href={images.original}
                           target="_blank"
                           rel="noopener noreferrer">
                          <img src={images.large} style={{width: 150, height: 150}}
                               alt=""/>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container>
                    {homework?.submissions?.[0]?.videos_attributes && homework?.submissions?.[0]?.videos_attributes.map(videos => (
                      <Grid item xs={12} md={6} key={videos.id}>
                        <div className={classes.wrapper}
                             data-vimeo-url={videos.clip_url}>
                          <ReactPlayer
                            className={classes.player}
                            url={videos.clip_url}
                            controls={true}
                            //youtubeConfig={{playerVars: {showinfo: 1}}}
                            //playing
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Card className="mt-2 p-2" style={{backgroundColor: '#03a9fc'}}>
            <Typography className="pull-left">Submission
              Date: {new Date(homework.due_date).toDateString()}</Typography>
          </Card>
          <RenderAuthorized authorized={['Student']}>
            <div className="mt-3 mb-3 p-4">
              <Button variant="contained" style={{backgroundColor: 'green'}}
                      className="text-capitalize pull-left"
                      onClick={answerForm}>Answer</Button>
            </div>
          </RenderAuthorized>
        </div> : <div align={'center'}><CircularProgress/></div>}
    </div>
  )
};
