import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {assignmentList} from "../../../../utils/assignApi";
import {Typography} from "@material-ui/core";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    }
}));

export default function PastAssignment() {
    const classes = useStyles();
    const history = useHistory();
    const {id: subjectId} = useParams();
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        fetchAssignments();
    }, []);

    const fetchAssignments = () => {
        (async function fetchData() {
            await assignmentList(subjectId, {filter: 'closed'}).then(response => {
                setAssignments([...response.data['assignments']]);
            })
        })()
    };

    const studentAssignment = (id) => {
        history.push(`/student-assignment/${subjectId}/${id}`);
    };

    return (
        <div>
            <TableContainer className={classes.root}>
                <Table stickyHeader className={classes.table} aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell>Submitted Date</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Score</StyledTableCell>
                            <StyledTableCell>Remarks</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assignments && assignments.length > 0 ? assignments.map(lists => (
                            <StyledTableRow hover className="hand-cursor" onClick={() => studentAssignment(lists.id)}
                                            key={lists.id}>
                                <StyledTableCell>{lists.id}</StyledTableCell>
                                <StyledTableCell>
                                    {lists.title}
                                </StyledTableCell>
                                <StyledTableCell>{new Date(lists.due_date).toDateString()}</StyledTableCell>
                                <StyledTableCell>Submitted</StyledTableCell>
                                <StyledTableCell>{lists?.submissions?.[0]?.score || '---'}/{lists?.total_marks}</StyledTableCell>
                                <StyledTableCell>{lists?.submissions?.[0]?.comments}</StyledTableCell>

                            </StyledTableRow>
                        )) : <StyledTableRow>
                            <StyledTableCell>
                                <Typography align={"center"} variant={"h6"} component={"div"}>
                                    No data to display
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

