import React from "react";
import useStyles from "../../styles";
import {BasicInfo} from "./basic_info";

/*
export const ParentTypeSelector = () => {
    return (<>
        <h4>Select parent or guardian</h4>
        <FormControl component="fieldset">
            <RadioGroup row name="guardian_type">
                <FormControlLabel value="parent" control={<Radio color="primary"/>} label="Parent"/>
                <FormControlLabel value="guardian" control={<Radio color="primary"/>} label="Guardian"/>
            </RadioGroup>
        </FormControl></>)
};
*/


export const ParentForm = () => {
    const classes = useStyles();
    return (
        <>
            <BasicInfo/>
        </>)
};