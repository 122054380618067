import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function SharedDialog({open, handleAction}) {

    return (
        <div className="full-width">
            <Dialog
                open={open}
                onClose={(e) => handleAction(false, e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="full-width"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure want to proceed?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All data will be lost?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleAction(true, e)}
                            color="primary" variant="contained" className="mr-3">
                        Yes
                    </Button>
                    <Button onClick={(e) => handleAction(false, e)}
                            color="default" variant="contained" className="mr-3" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}