import React, {useContext, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import './subject/subjects.scss'
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {AppContext, DispatchContext} from "../../../store";
import {gradeList, profile} from "../../../utils/api";
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: 'orange',
    },
}));

export const Class = () => {
    const classes = useStyles();
    const dispatch = useContext(DispatchContext);
    const {user} = useContext(AppContext);
    const [load, setLoad] = useState(false);
    const {section_subjects: section_subj} = user;
    const [classlist, setClasslist] = useState([]);

    useEffect(() => {
        fetchClasses();
        profile(dispatch).then();
    }, []);

    const fetchClasses = () => {
        (async function fetchData() {
            await gradeList({per_page: 15}).then(response => {
                setClasslist([...response.data['grades']]);
                setLoad(true);
            })
        })()
    };

    const history = useHistory();
    const navigateAssignments = (id, grade_id, sect_id) => {
        history.push(`/assign-detail/${id}?grade=${grade_id}&&section=${sect_id}`)
    };
    return (
        <div style={{marginTop: 70}}>
            <h4 className="mt-4 ml-2" style={{float: "left"}}>My Classes</h4>
            {load ?
                <Grid container>
                    {section_subj && section_subj.map((lists, index) => (
                        classlist.map(values => (
                            values.id === lists.section.grade_id &&
                            <Grid item xs={12} md={4} key={lists.id}>
                                <Card className="card-class mt-4 hand-cursor"
                                      onClick={() => navigateAssignments(lists.id, lists.section?.grade_id, lists?.section?.id)}>
                                    <CardHeader
                                        title={`${values.name} '${lists?.section?.name}'`}
                                        style={{backgroundColor: "#b8b8b8",color: "white"}}
                                    />
                                    <CardContent style={{padding: 3}}>
                                        <Typography align={'center'} gutterBottom variant="h5" component="h2">
                                            {lists?.subject?.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    ))}
                </Grid> : <div align={'center'}><CircularProgress/></div>}
        </div>
    );
};
