import {PEOPLE_LIST, PEOPLE_LIST_FAILURE, PEOPLE_LIST_FETCHING, PEOPLE_LIST_SUCCESS, ROLE_LIST} from "../store";
import httpClient, {apiClient} from "./apiClient";

export const login = (payload, dispatch) => {
    return httpClient(`/users/sign_in`, 'post', null, payload).then(response => {
        if (response.data) {
            const token = response.headers['authorization'];
            const storage = payload.user.remember_me ? localStorage : sessionStorage;
            storage.setItem("user", JSON.stringify(response.data['user']));
            storage.setItem("token", JSON.stringify(token));
            dispatch({type: 'LOGIN', payload: response.data.user})
        }
    });
};
export const invitationAccept = (payload, dispatch) => {
    return httpClient(`/users/invitation`, 'put', null, payload).then(res => {
        if (res.data) {
            const token = res.headers['authorization'];
            const storage = localStorage;
            storage.setItem("user", JSON.stringify(res.data['user']));
            storage.setItem("token", JSON.stringify(token));
            dispatch({type: 'LOGIN', payload: res.data.user})
        }
    });
};

export const peopleList = (filters = {roles: [], page: 1, per_page: 10}) => {
    apiClient.dispatch({type: PEOPLE_LIST_FETCHING});
    return httpClient(`/users`, 'get', {...filters})
        .then(response => {
            apiClient.dispatch({
                type: PEOPLE_LIST,
                payload: {data: response.data['users'], meta: response.data['meta']}
            });
            apiClient.dispatch({type: PEOPLE_LIST_SUCCESS});
            return response.data;
        }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                apiClient.dispatch({
                    type: PEOPLE_LIST_FAILURE,
                    error: {...error.response.data, status: error.response.status}
                })
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
};
/*role api start*/
export const roleCreate = (data) => httpClient(`/roles`, 'post', null, {...data});
export const roleList = (dispatch) => {
    return httpClient('/roles', 'get').then(response => {
        apiClient.dispatch({type: ROLE_LIST, payload: response.data['roles']});
        return response;
    });
};
export const roleShow = (id) => {
    return httpClient(`/roles/${id}`, 'get').then(response => {
        return response;
    })
};
export const roleUpdate = (data, id) => httpClient(`/roles/${id}`, 'put', null, {...data});
export const roleDelete = (id) => httpClient(`/roles/${id}`, 'delete');
/*role api end*/

//department api start
export const createDepartment = (data) => httpClient('/departments', 'post', null, {...data});
export const departmentList = () => {
    return httpClient('/departments', 'get').then(response => response)
};
export const departmentDetail = (id) => httpClient(`/departments/${id}`, 'get').then(response => {
    return response;
});
export const departmentUpdate = (data, id) => httpClient(`/departments/${id}`, 'put', null, {...data});
export const deleteDepartment = (id) => httpClient(`/departments/${id}`, 'delete');
//department api end

//People api start
export const peopleInvite = (data) => httpClient('/users/invitation', 'post', null, data);
export const showPeople = (id) => httpClient(`/users/${id}`, 'get').then(response => {
    return response.data['user'];
});
export const deletePeople = (id) => httpClient(`/users/${id}`, 'delete');
//export const invitationAcception = (data) => httpClient('/users/invitation', 'post', null, data);
//people api end

/*Event api start*/
export const eventList = (method, data, params) => httpClient('/events', method, params, data);
export const eventDetails = (method, eventId, data) => httpClient(`/events/${eventId}`, method, data).then(response => response.data);
export const eventUpdate = (eventId, data) => httpClient(`/events/${eventId}`, 'put', null, data);
export const eventCategories = () => httpClient(`/event_categories`).then(response => response.data['event_categories']);
export const createEventCategory = (method = 'post', data) => httpClient(`/event_categories`, method, null, data).then(response => response.data['event_category']);
export const eventParticipants = (eventId, params) => httpClient(`/events/${eventId}/participants`, 'get', params).then(response => response.data);
export const participantsActions = (eventId, payload) => httpClient(`/events/${eventId}/participants/${payload.id || ''}`, payload.id ? 'delete' : 'post', null, payload).then(response => response.data);
export const profileUpdate = (data, params) => httpClient('/users/', 'put', params, data);
export const profile = (dispatch) => httpClient('/users/profile').then(response => {
    localStorage.setItem("user", JSON.stringify(response.data['user']));
    dispatch({type: 'LOGIN', payload: response.data.user})
    return response.data['user'];
});
export const classList = () => httpClient('/grades', 'get', {page: 1, per_page: 11});
export const userActivation = (data) => httpClient(`/users/${data.id}/status`, 'put', null,
    {user: {active: data.status}});
/*Event api end*/

/*Grade api start*/
export const gradeCreate = (method, data) => httpClient('/grades', method, null, data);
export const gradeList = (data) => httpClient('/grades', 'get', {...data});
export const gradeDetail = (id) => httpClient(`/grades/${id}`, 'get');
export const gradeUpdate = (id, data) => httpClient(`/grades/${id}`, 'put', null, {...data});
export const gradeDelete = (id) => httpClient(`/grades/${id}`, 'delete');
/*End grade api*/

/*section api start*/
export const sectionList = (grade_id, sec_id) => httpClient(`/grades/${grade_id}/sections/${sec_id}`, 'get').then(response => {
    return response
});
export const sectionListAdmin = (grade_id) => httpClient(`/grades/${grade_id}/sections`, 'get').then(response => {
    return response
});
export const assigningSubjectTeacher = (sec_sub_id, data) => httpClient(`/section_subjects/${sec_sub_id}`, 'put', null, {...data});

/*Assign subject api start*/
export const assignSubjectUpdate = (id, data) => httpClient(`/grades/${id}/sections/${data.section.section_ids}`, 'put', null, {...data});
/*Assign subject api end*/

/*subject api start*/
export const subjectCreate = (data) => httpClient('/subjects', 'post', null, {...data});
export const subjectList = (param) => httpClient('/subjects', 'get', {...param}).then(response => {
    return response;
});
export const subjectDetail = (id) => httpClient(`/subjects/${id}`, 'get').then(response => {
    return response;
});
export const subjectUpdate = (data, id) => httpClient(`/subjects/${id}`, 'put', null, {...data});
export const subjectDelete = (id) => httpClient(`/subjects/${id}`, 'delete');
/*Subject api end*/

export const userList = (filters = {roles: [], page: 1, per_page: 10}) => {
    return httpClient('/users', null, {...filters}).then(response => response.data['users'])

};
export const logout = (dispatch) => {
    return httpClient('/users/sign_out', 'delete').then(response => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({type: 'LOGOUT'});
    });
};
