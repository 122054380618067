import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from 'formik-material-ui';
import FormGroup from '@material-ui/core/FormGroup';
import {Field, Form, Formik} from "formik";
import ChipInput from 'material-ui-chip-input'
import Button from "@material-ui/core/Button";
import {gradeCreate, gradeDetail, gradeUpdate} from "../../../utils/api";
import * as Yup from 'yup';
import {useHistory} from "react-router-dom";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";
import Card from "@material-ui/core/Card";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Field is required')
});
export const CreateClass = (props) => {
    const initialValues = {
        name: '',
        sections_attributes: [],
    };
    const dispatch = useContext(DispatchContext);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const edit = params.get('edit');
    const id = params.get('id');
    const [open, setOpen] = useState(false);
    const [editVal, setEditVal] = useState();
    const [editInitial, setEditInitialVal] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (edit) {
            gradeDetail(id).then(response => {
                setEditVal(response.data.grade);
                setEditInitialVal({
                    name: response.data.grade.name,
                    sections_attributes: [...response.data.grade.sections_attributes.map((list) => (list))]
                });
                setOpen(true);
            }).catch(errors => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors, severity: 'error', duration: 5000}
                });
            })
        } else {
            setOpen(true);
        }
    }, []);
    const handleChange = (values, formikBag) => {
        formikBag.setFieldValue(`sections_attributes`, prepareSectionAttributes(values));
    };

    const prepareSectionAttributes = (values) => {
        let result = [];
        values.map((val, i) => {
            let id = null;
            editInitial['sections_attributes'] && editInitial['sections_attributes'].map(obj => {
                if (val === obj.name) {
                    id = obj.id;
                } else if (i === 0 && !values.includes(obj.name)) {
                    result.push({name: obj.name, id: obj.id, _destroy: 1});
                }
            });
            result.push({name: val, id: id, _destroy: 0});
        });
        return result;
    };

    const classCreate = async (grade) => {
        await gradeCreate('post', {grade}).then(response => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Class created successfully'}});
            history.push('/grade');
        }).catch(err => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: err.errors.join(', '), severity: 'error', duration: 5000}
            });
        })
    };
    const classUpdate = async (grade) => {
        await gradeUpdate(id, {grade}).then(res => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Class updated successfully'}});
            history.push('/grade');
        }).catch(errors => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: errors.errors.join(', '), severity: 'error', duration: 5000}
            });
        })
    };
    return (
        <div style={{marginTop: 70}}>
            <Card>
                <h3 style={{fontSize: 30, textAlign: 'left', marginTop: 20, marginLeft: 20}}>Create Class</h3>
                {open ?
                    <Formik initialValues={edit ? editInitial : initialValues}
                            onSubmit={edit ? classUpdate : classCreate}
                            validationSchema={validationSchema}
                            validateOnBlur={true}>
                        {({isSubmitting, touched, errors, ...formik}) => (
                            <Form style={{marginTop: 20}}>
                                <FormGroup row>
                                    <Grid container style={{marginLeft: 20, marginRight: 20}}>
                                        <Grid item md={4} xs={12}>
                                            <TextField name="name" variant="outlined"
                                                       label="Class Name" fullWidth/>
                                        </Grid>
                                    </Grid>
                                    {/*<h4 style={{marginTop: 50, marginLeft: 10}}>Add Sections</h4> <br/>*/}
                                    <Grid container style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                                        <Grid item xs={12} md={12} style={{textAlign: 'left'}}>
                                            <Field variant="outlined" newChipKeys={[',']} className="text-uppercase"
                                                   component={ChipInput} label="Add Section"
                                                   name="sections_attributes"
                                                   defaultValue={edit ? editVal.sections_attributes.map((val) => (
                                                       val.name
                                                   )) : ['A']}
                                                   onChange={(chips) => handleChange(chips, formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Button variant="contained" color='primary' type="submit"
                                                    disabled={isSubmitting}
                                                    className="background-header"
                                                    style={{marginTop: 40, marginBottom: 20, float: "left"}}>
                                                {edit ? 'Save' : 'Create'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik> : <></>

                }
            </Card>
        </div>
    );
};



