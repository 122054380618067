import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router-dom";
import {assignmentList} from "../../../../utils/assignApi";
import {CircularProgress, Typography} from "@material-ui/core";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    button: {
        backgroundColor: '#D5F934',
        textTransform: 'capitalize',
        borderRadius: 25,
    }
}));

export default function StudentAssignmentList() {
    const classes = useStyles();
    const {id: subjectId} = useParams();
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        fetchAssignments();
    }, []);

    const fetchAssignments = () => {
        (async function fetchData() {
            await assignmentList(subjectId, {filter: 'current'}).then(response => {
                setLoad(true);
                setAssignments([...response.data['assignments']]);
            })
        })()
    };

    const viewAssignmentDetail = (id) => {
        history.push(`/current-student-assignment/${subjectId}/${id}`)
    };

    return (
        <div>
            {load ?
                <TableContainer className={classes.root}>
                    <Table stickyHeader className={classes.table} aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Assignment Title</StyledTableCell>
                                <StyledTableCell>Submission Date</StyledTableCell>
                                <StyledTableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignments && assignments.length > 0 ? assignments.map(assignment => (
                                <StyledTableRow hover className="hand-cursor" key={assignment.id}>
                                    <StyledTableCell>{assignment.title}</StyledTableCell>
                                    <StyledTableCell>{new Date(assignment.due_date).toDateString()}</StyledTableCell>
                                    <StyledTableCell>
                                        <Button variant="contained" className={classes.button}
                                                onClick={() => viewAssignmentDetail(assignment.id)}>View Detail</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : <StyledTableRow>
                                <StyledTableCell>
                                    <Typography align={"center"} variant={"h6"} component={"div"}>
                                        No data to display
                                    </Typography>
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <div align={'center'}><CircularProgress/></div>}
            {/*<Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>*/}
        </div>
    )
}
