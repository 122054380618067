import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TuneIcon from '@material-ui/icons/Tune';
import IconButton from '@material-ui/core/IconButton';
import {CancelOutlined as RemoveIcon} from '@material-ui/icons';

const useStyles = makeStyles({
    search: {
        width: '100%',
        background: 'white',
        height: 40
    },
    searchWrapper: {
        position: 'relative'
    },
    searchFilterBox: {
        position: 'absolute',
        left: 0,
        top: '60px',
        width: '100%',
        background: 'white',
        boxShadow: '0px 1px 6px -2px rgba(0, 0, 0, 0.4)',
        borderRadius: '4px',
        padding: '16px',
        zIndex: '1'
    },
    searchHide: {
        visibility: 'hidden'
    },
    subGroupPadding: {
        paddingLeft: '30px'
    },
    fullWidth: {
        width: '100%'
    },
    buttonMargin: {
        marginTop: '24px'
    }
});

export const SearchFilter = ({callback}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [expanded, setExpanded] = useState(false);
    const inputLabel = React.useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searching, setSearching] = useState(false);


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };


    React.useEffect(() => {
        const abortController = new AbortController();
        if (expanded) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }

        return () => {
            abortController.abort();
        }
    }, [searchQuery]);


    const search = (query) => {
        setSearchQuery(query);
        callback(query);
    };

    return (
        <form className={classes.root} noValidate autoComplete='off'>
            <div className={classes.searchWrapper}>
                <OutlinedInput
                    className={classes.search}
                    id='outlined-adornment-search'
                    onChange={(event) => search(event.target.value)}
                    startAdornment={
                        <InputAdornment position='start'>
                            <SearchIcon/>
                        </InputAdornment>
                    }
                    endAdornment={ searchQuery ?
                        <InputAdornment position='end' onClick={() => search('')}>
                            <IconButton aria-label='toggle search filter'>
                                <RemoveIcon/>
                            </IconButton>
                        </InputAdornment> : <></>
                    }
                />
                {/* {expanded && <>
                    <div className={classes.searchFilterBox}>
                        <Grid container direction='row'>
                            <Grid item xs={12}>
                                Filters that will use only checkbox
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" value="Roles"/>}
                                            label="Roles"/>
                                        <FormGroup row className={classes.subGroupPadding}>
                                            Sub checkbox Filters if any which will be in loop
                                            <FormControlLabel control={<Checkbox color="primary" value="Admin"/>}
                                                              label="Admin"/>
                                            <FormControlLabel control={<Checkbox color="primary" value="Student"/>}
                                                              label="Student"/>
                                            <FormControlLabel control={<Checkbox color="primary" value="Staff"/>}
                                                              label="Staff"/>
                                            End of Sub filters
                                        </FormGroup>
                                    </FormGroup>
                                </FormControl>
                                End of checkbox filters
                            </Grid>
                            <Grid item xs={12}>
                                Filters that will use checkbox and radio
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox color="primary" value="Class"/>}
                                                          label="Class"/>
                                        <RadioGroup row className={classes.subGroupPadding}>
                                            Sub radio Filters if any which will be in loop
                                            <FormControlLabel value="PP" control={<Radio color="primary"/>} label="PP"/>
                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="2"/>
                                            End of radio filters
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                                End of Filters that will use checkbox and radio
                            </Grid>
                            Show dropdown menu if needed for filters
                            <Grid item xs={6}>
                                <FormControl variant="outlined" className={classes.fullWidth}>
                                    <InputLabel ref={inputLabel}>Age</InputLabel>
                                    <Select labelWidth={labelWidth}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            End of Dropdown Menu
                        </Grid>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            Filter buttons
                            <Button variant="outlined" color="primary" className={classes.buttonMargin}>
                                Clear Filter
                            </Button>
                            <Button variant="contained" color="primary" className={classes.buttonMargin}>
                                Apply Filter
                            </Button>
                            End of filter buttons
                        </Grid>
                    </div>
                </>}*/}
            </div>
        </form>
    );
};

export default SearchFilter;