import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'

const useStyles = makeStyles(theme => ({
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export const Leader = () => {
    const classes = useStyles();

    return (
        <div className="overview-types" style={{padding: 20}}>
            <h4 className="mt-2 mb-1 light-green anthem-title" style={{fontSize: 27}}>Druk School Leaders</h4>
            <Grid container>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <img src={require('../../../../../assets/images/sherig-logo.png')} className={classes.image} alt=""/>
                </Grid>
            </Grid>
        </div>
    )
};

