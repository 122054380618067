import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from "@material-ui/core/Icon";
import './layout.scss'
import '../assets/stylesheets/_utility.scss'
import Grid from '@material-ui/core/Grid'
import ExitIcon from '@material-ui/icons/ExitToApp'
import Link from '@material-ui/core/Link'
import AppBar from "@material-ui/core/AppBar";
import SearchFilter from "../pages/shared/search";
import Divider from "@material-ui/core/Divider";
import {RouteUser} from "../utils/userRoutes";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    root: {
        display: 'flex',
    },

    list: {
        width: 250,
        marginTop: 50,
    },
    applist: {
        listStyleType: 'none',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    content: {
        flexGrow: 1,
    },
});

export default function Layout(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [side]: open});
    };


    const sideList = side => (
        <div
            className={classes.list}
            style={{backgroundColor: 'pink'}}
            role="presentation"
            onClick={toggleDrawer(side, true)}
            onKeyDown={toggleDrawer(side, false)}
        >
            {RouteUser.filter(item => item.visible).map(({primaryText, icon, path}, i) => (
                <a href={path} className="decoration-none list-mb" key={primaryText}>
                    <ListItem className="list-mb" key={primaryText} button>
                        <ListItemIcon>
                            <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={primaryText}
                            primaryTypographyProps={{noWrap: true}}
                        />
                    </ListItem>
                </a>
            ))}
            <Link to='/login'>
                <List>
                    {['Log In'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon> <ExitIcon/></ListItemIcon>
                            <ListItemText primary={text}/>
                        </ListItem>
                    ))}
                </List>
            </Link>
        </div>
    );
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <div>
                <AppBar className="header" color="primary">
                    <Grid container className="school-header">
                        <Grid item xs={1}>
                            <img src={require('../assets/images/druk-logo.jpg')} alt="" className="drukschool-logo"/>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className="text-uppercase pull-left ml-5 pt-3 text-bold school-name"
                                style={{color: 'blue'}}>Druk School</h2>
                        </Grid>
                        <Grid item xs={4} className="menu-button">
                            <Button
                                color="inherit"
                                aria-label="open"
                                onClick={toggleDrawer('right', true)}
                                className="mt-2 pull-right"
                            >
                                <MenuIcon/>
                            </Button>
                        </Grid>
                        <Grid item xs={4} className="mt-4 pt-2 school-details">
                            <Typography style={{textAlign: 'left', color: 'orange', fontSize: 20}}>
                                For admission: adm08@drukschool.com
                            </Typography>
                            <Typography className="mt-2" style={{textAlign: 'left', color: 'orange', fontSize: 20}}>
                                Fax: +975-2-325922
                            </Typography>
                        </Grid>

                    </Grid>
                    <Divider/>
                    <Grid container direction="row">
                        <Grid item sm={8} className="inline-block nav-header-list">
                            <ul className="header-list list-style-none text-white pull-left pt-1 hand-cursor">
                                <Button className="btn">
                                    <li className="list-style-none">
                                        <a href="#" className="nav-list">Home </a>
                                    </li>

                                </Button>
                                <Button className="btn ml-4">
                                    <li className="list-style-none"><a href="#" className="nav-list">Departments</a>
                                    </li>
                                </Button>
                                <Button className="btn ml-4">
                                    <li><a href="#" className="nav-list">Faculty</a>
                                    </li>
                                </Button>
                                <Button className="btn ml-4">
                                    <li><a href="#" className="nav-list">About</a></li>
                                </Button>
                                <Button className="btn ml-4">
                                    <li><a href="#" className="nav-list">Contact Us</a></li>
                                </Button>
                            </ul>
                        </Grid>
                        <Grid item sm={4} className="hand-cursor collapse-mb">
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                    <Button className="btn pull-right mr-2">
                                        <li className="sign-in list-style-none">
                                            <a href="#" style={{textDecoration: 'none'}} className="nav-list">Log
                                                In</a>
                                        </li>
                                    </Button>
                                </Grid>
                                {/*<Grid item md={4} xs={12}>
                                    <Button variant="contained" className="mt-3 pt-1 pb-1 pull-left mr-3"
                                            style={{
                                                textTransform: 'textUppercase',
                                                fontWeight: 'bold',
                                                fontSize: 13,
                                                backgroundColor: 'red',
                                                color: 'white'
                                            }}>Get started
                                    </Button>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
            </div>
            <Drawer
                anchor="right"
                className="responsive-drawer"
                open={state.right}
                onOpen={toggleDrawer('right', true)}
                onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>

            <main className={classes.content}>
                {props.children}
            </main>
        </div>

    );
}
