import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "center",
        marginTop: 30,
        fontSize: 18,
    },
    list: {
        textAlign: 'left',
    },
}));

const Board = () => {
    const classes = useStyles();

    return (
        <div className="overview-board">
            <Grid container>
                <Grid item xs={12} md={12}>
                    <h4 className="mt-2 mb-1 light-green pull-left" style={{fontSize: 27}}>Board and Coordinators,
                        2020</h4>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <h6 className="board-head">Board, 2020</h6>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Management Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Chairperson, Ganglingkha Group of Companies Pvt. Ltd</li>
                            <li> CEO, Ganglingkha Group of Companies Pvt. Ltd</li>
                            <li>General Manager, Ganglingkha Group of Companies Pvt. Ltd</li>
                            <li>Principal, Druk School</li>
                            <li>Deputy Principals, Druk School</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Human Resource Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li> CEO, Ganglingkha Group of Companies Pvt. Ltd</li>
                            <li>Human Resource Officer, Ganglingkha Group of Companies Pvt.Ltd</li>
                            <li>Principal</li>
                            <li>Deputy Principals</li>
                            <li>HODs (Defacto )</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Academic Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Chairperson: Principal</li>
                            <li>Deputy Principals</li>
                            <li>HODs</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Examination Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Chairperson: Lopen Jaganath Dungana</li>
                            <li>Deputy Principals</li>
                            <li>HODs</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Administrative Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Principal</li>
                            <li>Deputy Principals</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Scholarship Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>CEO, Galingkha Group of Companies</li>
                            <li>Principal</li>
                            <li>Deputy Principals</li>
                        </ol>
                    </p>
                </Grid>

                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">In-service training and scholarship Board for staff:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Coordinator: Gayatri Bhandari</li>
                            <li>HRO</li>
                            <li>General Manager</li>
                            <li>Principal</li>
                            <li>Deputy Principals</li>
                            <li>HODs</li>
                        </ol>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <h6 className="board">Discipline Board:</h6>
                    <p>
                        <ol type="1" className={classes.list}>
                            <li>Chairperson: Lopen Chado</li>
                            <li>All Dzongkha Lopens</li>
                            <li>Deputy Principals</li>
                            <li>Class Teachers (Defacto)</li>
                        </ol>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default Board;