import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import '../overview.scss'

const useStyles = makeStyles(theme => ({
    typography: {
        textAlign: "center",
        marginTop: 30,
        fontStyle: 'italic',
        fontSize: 18,
    },
}));

const Anthem = () => {
    const classes = useStyles();

    return (
        <div className="overview-types">
            <h4 className="mt-2 mb-1 light-green anthem-title" style={{fontSize: 27}}>Druk School Anthem</h4>
            <Grid container>
                <Grid item xs={12} md={12} className="mb-3 mt-2">
                    <p className={classes.typography}>
                        There’s a place in the world for us, <br/><br/>
                        A place where I like to be, oh.... Druk School <br/><br/>
                        Cause the faces are friendly here, <br/><br/>
                        Smiling face n happy face, <br/><br/>
                        Giggling all around <br/><br/>
                        Oh, what fun we’ll have this way, <br/><br/>
                        In a happy school today <br/><br/>
                        <p className="text-bold" style={{fontStyle: "italic"}}>
                            Chorus <br/><br/>
                            It’s the feeling inside that fills us with pride; <br/><br/>
                            Growing together side by side, in a perfect harmony,<br/><br/>
                            I give to you, you give to me. (2)<br/><br/>
                        </p>
                        We are the world of tomorrow <br/><br/>
                        A pillar of a rising nation! Oh..... Druk School<br/><br/>
                        Our learning comes to life every day<br/><br/>
                        Leads us on to better ways,<br/><br/>
                        Teachers, parents, friends are there<br/><br/>
                        When there’s a worry or two<br/><br/>
                        There’s someone who cares.<br/><br/>
                        <p className="text-bold" style={{fontStyle: "italic"}}>
                            Chorus <br/><br/>
                            It’s the feeling inside that fills us with pride;<br/><br/>
                            Growing together side by side, in a perfect harmony,<br/><br/>
                            I give to you, you give to me. (2)<br/><br/>
                        </p>

                        We like to come to Druk School <br/><br/>
                        Our School is such a happy place; oh.... Druk School<br/><br/>
                        We like to come to Druk School<br/><br/>
                        As we learn, we begin to see;<br/><br/>
                        Druk School is the place for me<br/><br/>
                        When it’s time to move on, I know<br/><br/>
                        I will miss you Druk School.<br/><br/>
                        <p className="text-bold" style={{fontStyle: "italic"}}>
                            Chorus <br/><br/>
                            It’s the feeling inside that fills us with pride;<br/><br/>
                            Growing together side by side, in a perfect harmony,<br/><br/>
                            I give to you, you give to me. (3)
                        </p>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
};

export default Anthem;