import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";
import EditProfile from './edit-profile/edit-profile';
import {profile as profileApi, profileUpdate, showPeople} from "../../utils/api";
import {objectToFormData as o2f} from "object-to-formdata";
import RenderAuthorized from "../../components/render_authorized";
import {DispatchContext} from "../../store";

const useStyles = makeStyles(({palette}) => ({
    avatar: {
        width: 100,
        height: 100,
        margin: 'auto',
        cursor: 'pointer',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
    },
    subheader: {
        fontSize: 14,
        color: palette.grey[500],
        marginBottom: '0.875em',
    },
    cameraAvatar: {
        width: 28,
        height: 28,
        background: '#dbdbdb',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: 4,
        margin: '-35px 0 0 69px',
        position: 'relative',
        zIndex: 1000
    },
    profilePage: {
        padding: '20px 0',
    },
    editIcon: {
        width: 20,
        height: 20,
        verticalAlign: 'middle',
        marginLeft: 20,
        cursor: 'pointer'
    },
    profileHeader: {
        textAlign: 'center',
    },
    profileContent: {
        padding: 20,
        margin: '20px 0'
    },
    input: {
        display: 'none',
    },
    gridContent: {
        paddingTop: 12,
        textAlign: 'left'
    },
    mt0: {
        marginTop: 0
    }
}));

const Profile = (props) => {
    const styles = useStyles();
    const dispatch = useContext(DispatchContext);
    const uid = props.computedMatch && props.computedMatch.params.id;
    const [open, setOpen] = React.useState(false);
    const [profiles, setProfile] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        fetchProfile();
        setOpen(false);
    };

    const handleProfileUpload = async ({target: {files: [file]}}) => {
        await profileUpdate(o2f({profile_attributes: {photo_attributes: {image: file}}}, null, null, 'user')).then(res => {
            fetchProfile();
        });
    };

    useEffect(() => {
        if (uid) {
            userDetaile()
        } else {
            fetchProfile();
        }
    }, []);

    const fetchProfile = () => {
        (async function fetchData() {
            await profileApi(dispatch).then(response => {
                setProfile({
                    email: response.email,
                    grade: response.profile_attributes,
                    profile: response.profile_attributes.contact_person_attributes,
                    photo: response.profile_attributes.photo_attributes,
                    pm_address: response.permanent_address_attributes,
                    pr_address: response.present_address_attributes,
                    ec_contact: response.emergency_contact_attributes,
                    edu_attributes: [...response.qualifications_attributes],
                });
            });
        })()
    };

    const userDetaile = () => {
        (async function fetchData() {
            await showPeople(parseInt(uid)).then(response => {
                setProfile({
                    email: response.email,
                    grade: response.profile_attributes,
                    profile: response.profile_attributes.contact_person_attributes,
                    photo: response.profile_attributes.photo_attributes,
                    pm_address: response.permanent_address_attributes,
                    pr_address: response.present_address_attributes,
                    ec_contact: response.emergency_contact_attributes,
                    edu_attributes: [...response.qualifications_attributes],
                });
            });
        })()
    };


    return (
        <div className={styles.profilePage} style={{marginTop: 70}}>
            <div className={styles.profileHeader}>
                <Badge overlap="circle" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                    {!uid && <input accept="image/*" className={styles.input} id="icon-button-file" type="file"
                                    onChange={handleProfileUpload}/>}
                    <label htmlFor="icon-button-file">
                        <Avatar alt="Travis Howard"
                                src={(profiles.photo && profiles.photo.small) || "https://i.pravatar.cc/100"}
                                className={styles.avatar} color="primary" aria-label="upload picture" component="span"/>
                        {!uid && <CameraAltIcon fontSize="small" htmlFor="icon-button-file"
                                                className={styles.cameraAvatar}/>}
                    </label>
                </Badge>
                <h3 className={styles.heading}>{profiles.profile && profiles.profile.first_name} {profiles.profile &&
                profiles.profile.last_name}</h3>
                <span className={styles.subheader}>{profiles.email}</span>
            </div>
            {profiles.profile &&
            <Paper className={styles.profileContent}>
                <h4 className={styles.mt0}>Personal Details {!uid &&
                <EditIcon fontSize="small" className={styles.editIcon}
                          onClick={handleClickOpen}/>}
                </h4>
                <Divider/>
                <Grid container className={styles.gridContent}>
                    <Grid item xs={12} lg={4}>
                        Name: {profiles.profile.first_name} {profiles.profile.last_name}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <RenderAuthorized authorized={['Student']}>
                            Standard: {profiles.grade && profiles.grade['section_grade_name']}
                        </RenderAuthorized>
                        <RenderAuthorized authorized={['Teacher']}>
                            Class Teacher Of: {profiles.grade && profiles.grade['section_grade_name']}
                        </RenderAuthorized>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Phone Number: {profiles.profile.phone}
                    </Grid>
                    {profiles.pr_address && <Grid item xs={12} lg={4}>
                        Current
                        Address: {profiles.pr_address.village},
                        {profiles.pr_address.gewog}, {profiles.pr_address.dungkhag}, {profiles.pr_address.dzongkhag}
                    </Grid>
                    }
                    {profiles.pm_address && <Grid item xs={12} lg={4}>
                        Permanent
                        Address: {profiles.pm_address.village}, {profiles.pm_address.gewog}, {profiles.pm_address.dungkhag},
                        {profiles.pm_address.dzongkhag}
                    </Grid>
                    }
                </Grid>
                <EditProfile handleClose={handleClose} type={`profile`} data={profiles} open={open}/>
            </Paper>
            }
            {profiles.ec_contact &&
            <Paper className={styles.profileContent}>
                <h4 className={styles.mt0}>
                    Emergency Contact
                    {/*<EditIcon fontSize="small" className={styles.editIcon} onClick={handleClickOpen}/>*/}
                </h4>
                <Divider/>
                <Grid container className={styles.gridContent}>
                    <Grid item xs={12} lg={4}>
                        Name: {profiles.ec_contact.first_name} {profiles.ec_contact.last_name}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Phone: {profiles.ec_contact.phone}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Relation: {profiles.ec_contact.relationship}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Designation: {profiles.ec_contact.designation}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        Email Id: {profiles.ec_contact.email}
                    </Grid>
                </Grid>
                <EditProfile handleClose={handleClose} type={`emergency`} data={profiles.ec_contact} open={open}/>
            </Paper>
            }
            {profiles.edu_attributes && profiles.edu_attributes.length > 0 &&
            <Paper className={styles.profileContent}>
                <h4 className={styles.mt0}>
                    Education Details
                    {/*<EditIcon fontSize="small" className={styles.editIcon} onClick={handleClickOpen}/>*/}
                </h4>
                <Divider/>
                {profiles.edu_attributes.map(edu => (
                    <Grid container className={styles.gridContent} key={edu.id}>
                        <Grid item xs={12} lg={3}>
                            Qualification: {edu.grade}
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            Mark: {edu.mark}
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            Year of Completion: {new Date(edu.year).toDateString()}
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            Institute: {edu.institute}
                        </Grid>
                    </Grid>
                ))}
                <EditProfile handleClose={handleClose} type={`education`} data={profiles.edu_attributes} open={open}/>
            </Paper>
            }
        </div>
    )
};

export default Profile;
