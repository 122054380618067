import React, {useContext, useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import {eventDetails, eventParticipants} from "../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import {useParams} from "react-router";
import Spots from "../components/spots";
import {Link, useHistory} from "react-router-dom";
import ConfirmDialog from "../../../components/confirm-dialog";
import FormattedTime from "../components/formatted-time";
import RenderAuthorized from "../../../components/render_authorized";
import {JoinButton} from "./joinButton";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";


const useStyles = makeStyles(({palette}) =>
    createStyles({
        root: {
            flexGrow: 1,
            textAlign: 'left',
            padding: '16px'
        },
        eventDetail: {
            paddingBottom: '12px'
        },
        container: {
            maxHeight: 440,
        },
        eventImg: {
            maxHeight: '100%',
            maxWidth: '100%'
        },
        actionBtn: {
            float: 'right'
        },
        eventBasic: {
            borderRight: '1px solid gray'
        },
        icon: {
            verticalAlign: 'middle',
            paddingRight: 3
        },
        otherImages: {
            overflowY: 'scroll',
            maxHeight: '215px',
            minHeight: '120px',
        },
        margin0: {
            margin: '0'
        },
        Table: {
            maxHeight: '440px'
        },
        fullWidth: {
            width: '100%',
        },
        ParticipantModal: {
            padding: '16px'

        },
        participantList: {
            minHeight: '64px',
            maxHeight: '300px',
            overflowY: 'scroll',
            marginTop: '12px'

        },

        Participant: {
            marginTop: '10px'
        }


    }),
);

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function createData(name, status, action) {
    return {name, status, action};
}

export
interface
SimpleDialogProps
{
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

export default function EventDetail({location}) {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const [value, setValue] = React.useState(0);
    const [event, setEvent] = useState(location.state);
    const {id: eventId} = useParams();
    const [participants, setParticipants] = useState([]);
    const [meta, setMeta] = useState(0);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        !event && eventDetails(null, eventId).then(response => setEvent(response.data['event']));
        fetchParticipants();

    }, [location.pathname, rowsPerPage, page]);


    const fetchParticipants = async () => {
        const {participants, meta} = await eventParticipants(eventId, {per_page: rowsPerPage, page: page});
        //setBooked(participants.find(participant => participant.user_id === user.id));
        setParticipants(participants);
        setMeta(parseInt(meta.total));

    };

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    function handleChangeIndex(index: number) {
        setValue(index);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };


    /*const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
    };


    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };*/


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const deleteEvent = async (result) => {
        if (result) {
            await eventDetails('delete', eventId);
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Event deleted'}});
            history.push(`/`)
        }
        setIsDialogOpen(false);
    };

    const toggleDialog = () => {
        setIsDialogOpen(true);
    };

    const eventJoinCallback = async (result, eventId) => {
        if (result) {
            const {event: updatedEvent} = await eventDetails(null, eventId);
            fetchParticipants();
        }

    };

    const profileRoute = (id) => {
        history.push(`/profile/${id}`);
    };

    return (
        <Paper className={classes.root} elevation={0} style={{marginTop: 70}}>
            <Typography component="div" style={{width: 200}}>
                <ConfirmDialog open={isDialogOpen} handleAction={deleteEvent}/>
            </Typography>
            {!event ? <CircularProgress/> : <><Grid container spacing={2}>
                <Grid item xs={7}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Typography variant="h6">{event.title}</Typography>
                            <Typography component="div">
                                <Spots event={event}/>
                            </Typography>
                            <Typography component="div" style={{width: 200}}>
                                <JoinButton event={event} callback={(result) => eventJoinCallback(result, event.id)}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <RenderAuthorized authorized={['Admin']}>
                    <Grid item xs={5}>
                        <Button color="primary" className={classes.actionBtn} component={Link}
                                to={{pathname: `/events/edit/${event.id}`}}>
                            <EditIcon/> Edit
                        </Button>
                        <Button color="primary" className={classes.actionBtn} onClick={toggleDialog}>
                            <DeleteIcon/> Delete
                        </Button>
                    </Grid>
                </RenderAuthorized>
            </Grid>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12} className={classes.eventBasic}>
                        <img alt={event.title} className={classes.eventImg} src={event.photos_attributes[0].large}/>
                        <div>
                            <Chip label={event.event_category.name} color="primary" variant="outlined"/>
                        </div>
                        <Grid item xs={12} style={{marginTop: 15}}>
                            <div><span className={classes.icon}><AccessTimeIcon/></span><FormattedTime {...event}/>
                            </div>
                            <div><span className={classes.icon}><LocationOnIcon/></span>{event.location}</div>
                        </Grid>
                        <div>
                            <div><Typography variant="h6">
                                Other Images </Typography></div>
                            <Grid container spacing={3} className={classes.otherImages}>
                                {
                                    event.photos_attributes.map((photo, index) => (
                                        <Grid item xs={6} md={3} key={index}>
                                            <img alt={event.title} className={classes.eventImg}
                                                 src={photo.small}/></Grid>
                                    ))
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container>
                            {/*<Grid item xs={12}>
                                <Button variant="outlined" color="primary" onClick={handleClickOpen}
                                        className={classes.actionBtn}>

                                    <AddIcon/> Invite Participant
                                </Button>
                                <SimpleDialog open={open} onClose={handleClose}/>
                            </Grid>*/}
                            <Grid item xs={12}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs"
                                >
                                    <Tab label="Event Details" {...a11yProps(0)} />
                                    <Tab label="Participant" {...a11yProps(1)} />

                                </Tabs>

                                <div>
                                    <TabPanel value={value} index={0}>
                                        <p>
                                            {event.description}
                                        </p>
                                        <Typography variant="h6" display={"block"} paragraph={true}>
                                            Attachments </Typography>
                                        {
                                            event.attachments_attributes.map((attachment, index) => (
                                                <div className="hand-cursor" key={index}>
                                                    <a href={attachment.file_url}
                                                       style={{textDecoration: 'none'}}>
                                                        <Chip
                                                            label={attachment.filename}
                                                            //onDelete={handleDelete}
                                                            className="hand-cursor"
                                                            color="primary" variant="outlined"/>
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </TabPanel>


                                    <TabPanel value={value} index={1}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    {/*<TableCell align="right">Action</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {participants.map(({
                                                                       user: {
                                                                           id,
                                                                           profile_attributes: {
                                                                               contact_person_attributes: {
                                                                                   first_name,
                                                                                   last_name
                                                                               }
                                                                           }
                                                                       }
                                                                   }, index) => (
                                                    <TableRow key={index} onClick={() => profileRoute(id)}
                                                              className="hand-cursor">
                                                        <TableCell component="th" scope="row">
                                                            {first_name} {last_name}
                                                        </TableCell>
                                                        {/*<TableCell align="right">
                                                            <span><IconButton><DeleteIcon/></IconButton></span>
                                                        </TableCell>*/}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            component="div"
                                            count={meta || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page - 1}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TabPanel>

                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </>
            }
        </Paper>
    );
}
