import React, {useContext, useEffect, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useHistory} from 'react-router-dom';
import useStyles from '../../styles';
import {gradeDetail, gradeList, peopleInvite, roleList, userList} from "../../../../utils/api";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {FieldArray, Form, Formik} from "formik";
import {AppContext, DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import cloneDeep from "lodash-es/cloneDeep";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from "@material-ui/core/InputLabel";
import {TextField} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import uuid from "uuid";
import * as Yup from "yup";
import {ListItemText} from "@material-ui/core";
import Loader from "../../../shared/Loader";
import './invitation.scss';


const validationSchema = Yup.object().shape({
    user: Yup.array().of(Yup.object().shape({
        email: Yup.string().required('Field is required').email('Invalid email'),
    }))
});

export const InvitationAll = (props) => {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const urlParams = new URLSearchParams(props.location.search);
    const roleFromParam = '' + urlParams.get('role');
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const {roles = [], user} = useContext(AppContext);
    const [grades, setGrade] = useState([]);
    const [sections, setSection] = useState([]);
    const [inviteRole, setInviteRoles] = useState([]);
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [load, setLoad] = useState(false);
    //const formik = useFormikContext();

    useEffect(() => {
        fetchGrade();
        fetchRole();
        inputLabel && inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const fetchGrade = () => {
        (async function fetchData() {
            await gradeList({per_page: 16}).then(response => {
                setGrade(response.data.grades);
            }).catch(err => {
                console.log(err);
            })
        })()
    };

    useEffect(() => {
        fetchParents();
    }, [query]);

    const fetchRole = () => {
        (async function fetchData() {
            await roleList().then(response => {
                setInviteRoles([...response.data['roles']]);
            }).catch(err => {
                console.log(err);
            })
        })()
    };

    const fetchParents = () => {
        (async function fetchUsers() {
            if (roleFromParam === 'Student') {
                await userList({q: query, roles: [6]}).then(response => {
                    setOptions([...response]);
                })
            } else {
                await userList({q: query, roles: [3]}).then(response => {
                    setOptions([...response]);
                })
            }
        })()
    };

    const fetchSection = (event, index, formikBag) => {
        (async function fetchData() {
            await gradeDetail(event.target.value).then(response => {
                let copy = [...sections];
                copy[index] = [...response.data.grade.sections_attributes.map((lists) => (lists))];
                setSection(copy);
                formikBag.setFieldValue(`user[${index}].grade`, event.target.value);
            }).catch(err => {
                console.log(err);
            })
        })()
    };

    const renderLabel = (user) => {
        if (!user) return '';
        const {profile_attributes: person} = user;
        return `${person && person.contact_person_attributes && person.contact_person_attributes.first_name} 
        ${person && person.contact_person_attributes && person.contact_person_attributes.last_name}`;
    };

    const gradeSection = (event, index, formikBag) => {
        formikBag.setFieldValue(`user[${index}].section_id`, event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onRole = (event, values, index, formikBag) => {
        values.map((roles, ind) => (
            formikBag.setFieldValue(`user[${index}].role_ids[${ind}]`, roles.id, true)
        ))
    };

    const onSubmit = async (values, formikBag) => {
        const {setSubmitting} = formikBag;
        const formData = cloneDeep(values);
        setLoad(true);
        if (roleFromParam === 'Student' || roleFromParam === 'Parent') {
            const userRole = roles.find(role => role.name === roleFromParam);
            const section = user.profile_attributes['section_id'];
            if (!userRole) return;  //bail out
            formData.role_ids = [userRole.id];
            formData.section_id = section;
        }

        formData.user.map(lists => (
            peopleInvite({
                user: {
                    email: lists.email,
                    role_ids: roleFromParam === 'Student' ? formData.role_ids :
                        roleFromParam === 'Parent' ? formData.role_ids : lists.role_ids,
                    parent_ids: roleFromParam === 'Student' ? [...lists.parent_ids] : '',
                    student_ids: roleFromParam === 'Parent' ? [...lists.student_ids] : [...lists.student_ids],
                    profile_attributes: {section_id: roleFromParam === 'Student' ? formData.section_id : lists.section_id}
                }
            }).then(_ => {
                setLoad(false);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'User invited successfully'}});
                if (roleFromParam === 'Student' || roleFromParam === 'Parent') {
                    history.push('/sections');
                } else {
                    history.push('/people');
                }
            })
                .catch(({errors}) => {
                    setLoad(false);
                    formikBag.setFieldError('general', errors);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        payload: {message: errors, severity: 'error', duration: 5000}
                    });
                })
        ));

    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const addInvitations = (arrayHelpers => {
        arrayHelpers.push({
            "uid": uuid.v4(),
            "role_ids": [''],
            "email": "",
            "parent_ids": [''],
            "grade": "",
            "section_id": "",
            "_destroy": 0
        });
    });

    const deleteInvitations = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    const handleOptionChange = (e, value, index, formikBag) => {
        value.map((lists, ind) => (
            roleFromParam === 'Student' ? formikBag.setFieldValue(`user[${index}].parent_ids[${ind}]`, (lists && lists.id) || '', false)
                : formikBag.setFieldValue(`user[${index}].student_ids[${ind}]`, (lists && lists.id) || '', false)
        ));
    };

    return (
        <div className={classes.root} style={{marginTop: 70}}>
            <Grid container>
                <Grid item xs={12}>
                    <h5 onClick={history.goBack}>
                        <IconButton>
                            <ArrowBackIcon className={classes.title}/>
                        </IconButton>
                        Back {/*{roleFromParam.toUpperCase()}*/}
                    </h5>
                </Grid>
            </Grid>
            <Paper elevation={0} className={classes.peopleCreate}>
                <Formik
                    initialValues={{user: [{default: true, uid: uuid.v4(), parent_ids: [], student_ids: []}]}}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={true}
                >
                    {({isSubmitting, touched, values, ...formik}) => {
                        return (
                            <>
                                <Form>
                                    <>
                                        <div>
                                            <div>
                                                <h4 className="ml-4 pt-3">User Information</h4><br/>
                                                <FieldArray name="user"
                                                            render={(arrayHelpers) => (
                                                                <div>
                                                                    {
                                                                        values.user.map((invitations, index) => (
                                                                            <Grid container direction="row" spacing={1}
                                                                                  key={index}>
                                                                                <Grid item xs={12} sm={4}
                                                                                      className="user-info-form">
                                                                                    <TextField id="email" label="Email"
                                                                                               variant="outlined"
                                                                                               className={classes.fullWidth}
                                                                                               name={`user[${index}].email`}
                                                                                    />
                                                                                </Grid>
                                                                                {roleFromParam === 'null' &&
                                                                                <Grid item xs={12} sm={2}
                                                                                      className="user-info-form">
                                                                                    <Autocomplete
                                                                                        multiple
                                                                                        id="checkboxes-tags-demo"
                                                                                        options={inviteRole}
                                                                                        //disableCloseOnSelect
                                                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        renderOption={({name}) =>
                                                                                            name !== 'Student' &&
                                                                                            (<ListItemText
                                                                                                primary={`${name}`}
                                                                                            />)}
                                                                                        onChange={(e, value) => onRole(e, value, index, formik)}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                onChange={handleChange}
                                                                                                {...params}
                                                                                                name="role_ids"
                                                                                                variant="outlined"
                                                                                                label="Select Role"
                                                                                                //placeholder="Select Parents"
                                                                                                InputProps={{
                                                                                                    ...params.InputProps,
                                                                                                    endAdornment: (
                                                                                                        <>
                                                                                                            {params.InputProps.endAdornment}
                                                                                                        </>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                }
                                                                                {roleFromParam === 'Student' &&
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Autocomplete
                                                                                        multiple
                                                                                        id="checkboxes-tags-demo"
                                                                                        options={options}
                                                                                        //disableCloseOnSelect
                                                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                                                        getOptionLabel={renderLabel}
                                                                                        renderOption={({
                                                                                                           profile_attributes: person,
                                                                                                           email
                                                                                                       }) =>
                                                                                            (<ListItemText
                                                                                                primary={`${person && person.contact_person_attributes && person.contact_person_attributes.first_name} 
                                                                                            ${person && person.contact_person_attributes && person.contact_person_attributes.last_name}`}
                                                                                                secondary={email}/>)}
                                                                                        onInputChange={handleChange}
                                                                                        onChange={(e, value) => handleOptionChange(e, value, index, formik)}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                onChange={handleChange}
                                                                                                {...params}
                                                                                                name="parent_ids"
                                                                                                variant="outlined"
                                                                                                label="Select Parent"
                                                                                                //placeholder="Select Parents"
                                                                                                InputProps={{
                                                                                                    ...params.InputProps,
                                                                                                    endAdornment: (
                                                                                                        <>
                                                                                                            {params.InputProps.endAdornment}
                                                                                                        </>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                }
                                                                                {roleFromParam === 'Parent' &&
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Autocomplete
                                                                                        multiple
                                                                                        id="checkboxes-tags-demo"
                                                                                        options={options}
                                                                                        //disableCloseOnSelect
                                                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                                                        getOptionLabel={renderLabel}
                                                                                        renderOption={({
                                                                                                           profile_attributes: person,
                                                                                                           email
                                                                                                       }) =>
                                                                                            (<ListItemText
                                                                                                primary={`${person && person.contact_person_attributes && person.contact_person_attributes.first_name} 
                                                                                            ${person && person.contact_person_attributes && person.contact_person_attributes.last_name}`}
                                                                                                secondary={email}/>)}
                                                                                        onInputChange={handleChange}
                                                                                        onChange={(e, value) => handleOptionChange(e, value, index, formik)}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                onChange={handleChange}
                                                                                                {...params}
                                                                                                name="student_ids"
                                                                                                variant="outlined"
                                                                                                label="Select Student"
                                                                                                //placeholder="Select Parents"
                                                                                                InputProps={{
                                                                                                    ...params.InputProps,
                                                                                                    endAdornment: (
                                                                                                        <>
                                                                                                            {params.InputProps.endAdornment}
                                                                                                        </>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                }
                                                                                {
                                                                                    values.user[index].role_ids && values.user[index].role_ids.map(lists => (lists === 6 &&
                                                                                        <>
                                                                                            <Grid item xs={12} sm={6}
                                                                                                  key={lists}>
                                                                                                <Autocomplete
                                                                                                    multiple
                                                                                                    id="checkboxes-tags-demo"
                                                                                                    options={options}
                                                                                                    //disableCloseOnSelect
                                                                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                                                                    getOptionLabel={renderLabel}
                                                                                                    renderOption={({
                                                                                                                       profile_attributes: person,
                                                                                                                       email
                                                                                                                   }) =>
                                                                                                        (<ListItemText
                                                                                                            primary={`${person && person.contact_person_attributes && person.contact_person_attributes.first_name} 
                                                                                            ${person && person.contact_person_attributes && person.contact_person_attributes.last_name}`}
                                                                                                            secondary={email}/>)}
                                                                                                    onInputChange={handleChange}
                                                                                                    onChange={(e, value) => handleOptionChange(e, value, index, formik)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField
                                                                                                            onChange={handleChange}
                                                                                                            {...params}
                                                                                                            name="student_ids"
                                                                                                            variant="outlined"
                                                                                                            label="Select Student"
                                                                                                            //placeholder="Select Parents"
                                                                                                            InputProps={{
                                                                                                                ...params.InputProps,
                                                                                                                endAdornment: (
                                                                                                                    <>
                                                                                                                        {params.InputProps.endAdornment}
                                                                                                                    </>
                                                                                                                ),
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </Grid>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    values.user[index].role_ids && values.user[index].role_ids.map(lists => (lists === 2 &&
                                                                                        <>
                                                                                            <Grid item xs={12} sm={2}
                                                                                                  key={lists}>
                                                                                                <FormControl
                                                                                                    variant="outlined"
                                                                                                    className={classes.fullWidth}>
                                                                                                    <InputLabel
                                                                                                        ref={inputLabel}>Class</InputLabel>
                                                                                                    <Select
                                                                                                        label="Class"
                                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                                        id="demo-simple-select-outlined"
                                                                                                        //name={`user[${index}].grade`}
                                                                                                        onChange={(e) => fetchSection(e, index, formik)}>

                                                                                                        {grades.map((qu, index) =>
                                                                                                            <MenuItem
                                                                                                                key={index}
                                                                                                                value={qu.id}>
                                                                                                                {qu.name}
                                                                                                            </MenuItem>)}
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                            {
                                                                                                values.user[index].grade &&
                                                                                                <Grid item xs={12}
                                                                                                      sm={2}>
                                                                                                    <FormControl
                                                                                                        variant="outlined"
                                                                                                        className={classes.fullWidth}>
                                                                                                        <InputLabel
                                                                                                            ref={inputLabel}>Section</InputLabel>
                                                                                                        <Select
                                                                                                            label="Section"
                                                                                                            //name={`user[${index}].section_id`}
                                                                                                            onChange={(e) => gradeSection(e, index, formik)}>
                                                                                                            {sections[index].map((qu) =>
                                                                                                                <MenuItem
                                                                                                                    key={qu.id}
                                                                                                                    value={qu.id}>{qu.name}
                                                                                                                </MenuItem>
                                                                                                            )}
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                </Grid>
                                                                                            }
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                                <Grid item xs={12} sm={1}>
                                                                                    {1 &&
                                                                                    <Button aria-label='delete'
                                                                                            className="mt-2 button-spacing ml-3"
                                                                                            color='secondary'
                                                                                            variant="outlined"
                                                                                            onClick={() => deleteInvitations(index, arrayHelpers)}>
                                                                                        <DeleteIcon/>Remove
                                                                                    </Button>}
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                    <Button variant='outlined' color='primary'
                                                                            className="{classes.addQualification} button-spacing mt-3 ml-3"
                                                                            onClick={() => addInvitations(arrayHelpers)}>
                                                                        <AddIcon/> Add more
                                                                    </Button>
                                                                </div>
                                                            )}>
                                                </FieldArray>
                                                <Button disabled={isSubmitting}
                                                        variant='contained'
                                                        color='primary'
                                                        type="submit"
                                                        className="{classes.btnMargin} background-header mt-3 ml-3 mb-3"
                                                >Invite</Button>
                                            </div>
                                        </div>
                                    </>
                                </Form>
                            </>)
                    }}
                </Formik>
            </Paper>
            {load && <Loader/>}
        </div>
    );
};

export default InvitationAll;