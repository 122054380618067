import React, {useContext} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {AppContext, DispatchContext} from "../store";
import {logout} from "../utils/api";
import EventList from "../pages/event/event-list/event-list";
import {PeopleList} from "../pages/people/list";
import {isAuthorized} from "../services/authorized";
import Schedules from "../pages/new-schedule/schedules";
import {ClassList} from "../pages/grade/class-list";
import {Class} from "../pages/people/teacher/class";
import {Subjects} from "../pages/people/student/subject/subject";
import {ClassSection} from "../pages/people/teacher/section";
import Preferences from "../pages/preferences/preferences";
import {Parent} from "../pages/people/parent/parent";

const styles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        display: 'inline-flex',
        color: 'inherit'
    },
    active: {
        backgroundColor: '#db6517'
    }
}));

export const navItems = [
    {
        primaryText: "Calendar",
        icon: "schedule",
        path: '/',
        visible: true,
        authorized: ['Admin', 'Student', 'Teacher', 'Staff', 'Principal', 'Parent'],
        component: Schedules
    },
    /*{
        primaryText: "Schedule",
        icon: "test",
        path: '/my-schedule',
        visible: true,
        authorized: ['Admin', 'Student', 'Teacher', 'Staff'],
        component: Schedule
    },*/
    {
        primaryText: "News",
        icon: "rss_feed",
        path: '/news'
    },
    {
        primaryText: "Events",
        icon: "event",
        path: '/events',
        visible: false,
        authorized: ['Admin', 'Student', 'Teacher', 'Staff', 'Parent', 'Principal'],
        component: EventList
    },
    {
        primaryText: "Lessons",
        icon: "school",
        path: '/lessons'
    },
    {
        primaryText: "Messages",
        icon: "message",
        path: '/messages'
    },
    {
        primaryText: "People",
        icon: "people",
        path: '/people',
        visible: true,
        authorized: ['Admin', 'Principal'],
        component: PeopleList
    },
    {
        primaryText: "Classes",
        icon: "classroom",
        path: '/grade',
        visible: true,
        authorized: ['Admin', 'Principal'],
        component: ClassList
    },
    {
        primaryText: "My Classes",
        icon: "classroom",
        path: '/class',
        visible: true,
        authorized: ['Teacher'],
        component: Class
    },
    {
        primaryText: "Home Room",
        icon: "classroom",
        path: '/sections',
        visible: false,
        authorized: ['Teacher'],
        component: ClassSection
    },
    {
        primaryText: "Subjects",
        icon: "assignment",
        path: '/subjects',
        visible: true,
        authorized: ['Student'],
        component: Subjects
    },
    {
        primaryText: "Preferences",
        icon: "assignment",
        path: '/preferences',
        visible: true,
        authorized: ['Admin', 'Principal'],
        component: Preferences
    },
    {
        primaryText: "Kindred",
        icon: "people",
        path: '/parent',
        visible: true,
        authorized: ['Parent'],
        component: Parent
    },

];
const NavContent = () => {
    //const classes = styles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const {user: {role_ids, profile_attributes}} = useContext(AppContext);
    const isActiveRoute = (path) => {
        return location.pathname === path;
    };

    const logoutUser = () => {
        logout(dispatch);
        history.push('/login');
    };
    return (
        <List className="nav-content">
            {navItems.filter(item => item.visible && isAuthorized(role_ids, item.authorized)).map(({
                                                                                                       primaryText,
                                                                                                       icon,
                                                                                                       path
                                                                                                   }, i) => (
                <ListItem key={primaryText} selected={isActiveRoute(path)} button component={NavLink} to={path}
                          className="mt-2 nav-hover data-container"
                    // style={{backgroundColor: isActiveRoute(path) ? 'white' : 'white'}}
                >
                    <ListItemIcon>
                        <Icon className="nav-ico">{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{noWrap: true}}
                        style={{color: "black"}}
                    />
                </ListItem>
            ))}
            {profile_attributes.section_id && role_ids[0].name === 'Teacher' &&
            <ListItem button component={NavLink} to="/sections" className="mt-2">
                <ListItemIcon>
                    <Icon>classroom</Icon>
                </ListItemIcon>
                <ListItemText
                    primary={"Home Room"}
                    primaryTypographyProps={{noWrap: true}}
                />
            </ListItem>
            }
            <Divider/>
            <ListItem button onClick={logoutUser}
            >
                <ListItemIcon>
                    <Icon>logout</Icon>
                </ListItemIcon>
                <ListItemText
                    primary={"Logout"}
                    primaryTypographyProps={{noWrap: true}}
                />
            </ListItem>
        </List>
    )
};

NavContent.propTypes = {};
NavContent.defaultProps = {};

export default NavContent;
