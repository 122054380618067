export const userReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {authenticated: true, ...action.payload};
        case 'LOGOUT':
            return {...defaultUser, authenticated: false,};
        default:
            return state;
    }
};

export const userStatusReducer = (state, action) => {
    switch (action.type) {
        case 'LOGGING_IN':
            return 'LOGGING_IN';
        case 'LOGIN_SUCCESS':
            return 'LOGIN_SUCCESS';
        case 'LOGIN_FAILURE':
            return 'LOGIN_FAILURE';
        case 'LOGGED_OUT':
            return 'LOGGED_OUT';
        default:
            return state;
    }
};
let defaultUser = {
    authenticated: false,
    id: 0,
    profile: {
        contact_person: {
            first_name: 'Guest',
            last_name: 'User',
        }
    },
    token: JSON.parse(localStorage.getItem('token')) || ''
};
defaultUser.authenticated = !!defaultUser.token;
const storedUser = (JSON.parse(localStorage.getItem('user')) || defaultUser);
defaultUser = {...defaultUser, ...storedUser};

export {defaultUser};