import React from "react";
import Button from "@material-ui/core/Button";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

export const TeachersHomeworkDetail = () => {

    return (
        <div>
            <Grid container>
                <Grid item xs={12} style={{backgroundColor: '#03a9fc'}} className="mt-2 mb-2">
                    <Typography className="mt-2 mb-2 pull-left">
                        <h6>Chapter 3 Homework
                            <span className="ml-2"
                                  style={{fontSize: 10, opacity: 0.7}}>Posted: Today, Fri, 27 Mar</span>
                        </h6>
                    </Typography>
                </Grid>
            </Grid>
            <Card className="card pt-2 pb-2">
                <h6 className="pull-left">Read "Unknown Women" by Zala Rinzin Norbu</h6>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, delectus
                    dolores eaque expedita facilis harum id in molestiae natus nesciunt nisi, porro
                    tempora ut! Cumque eos nesciunt obcaecati perferendis vero!
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aut autem consequatur
                    cum dignissimos dolorum eaque, earum et, eum expedita harum in iusto nesciunt officiis,
                    placeat quaerat recusandae sequi voluptatum.
                </Typography>
                <FileIcon fontSize="large" className="hand-cursor" style={{color: 'blue'}}/>
            </Card>
            <Card className="mt-2 p-2" style={{backgroundColor: '#03a9fc'}}>
                <Typography className="pull-left">Submission Date: Sat, 19th Mar.</Typography>
            </Card>
            <div className="mt-3 mb-3 p-2">
                <Button variant="contained" style={{backgroundColor: 'green'}} className="text-capitalize">Submit
                    Answer</Button>
            </div>
        </div>
    )
}
export default TeachersHomeworkDetail