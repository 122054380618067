import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {TextField} from "formik-material-ui";
import {DropzoneArea} from "material-ui-dropzone";
import {Form, Formik,Field} from "formik";
import Button from "@material-ui/core/Button";
import DateFnsUtils from '@date-io/date-fns';
import DateFnsAdapter from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {objectToFormData as o2formdata} from "object-to-formdata";
import {cloneDeep} from "lodash-es";
import {DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import {KeyboardDateTimePicker} from 'formik-material-ui-pickers';
import {Editor} from '@tinymce/tinymce-react';
import {
    assignmentCreate,
    assignmentDetail,
    assignmentUpdate,
    homeworkCreate,
    homeworkDetail,
    homeworkUpdate
} from "../../../../utils/assignApi";
import Loader from "../../../shared/Loader";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import * as Yup from 'yup';

const dateFns = new DateFnsAdapter();
const validationSchema = Yup.object().shape({
    title: Yup.string().required('Field is required'),
    due_date: Yup.date().min(new Date()).required('Field is required')
});

export const AssignmentTeacher = (props) => {
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const [load, onLoad] = useState(false);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const {id: subjectId, assignId: assignedId} = useParams();
    const task = params.get('task');
    const edit = params.get('edit');
    const [editVal, setEditVal] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (edit) {
            fetchingTask();
        } else {
            setShow(true);
        }
    }, []);
    const fetchingTask = () => {
        if (task === "assignment" && edit) {
            (async function fetchData() {
                await assignmentDetail(subjectId, assignedId).then(response => {
                    setEditVal({
                        due_date: response.data['assignment'].due_date,
                        title: response.data['assignment'].title,
                        description: response.data['assignment'].description,
                        photos_attributes: [],
                        videos_attributes: [],
                        attachments_attributes: [],
                        total_marks:response.data['assignment'].total_marks
                    });
                    setShow(true);
                }).catch(error => {
                    setShow(true);
                })
            })()
        } else if (task === "home_work" && edit) {
            (async function fetchData() {
                await homeworkDetail(subjectId, assignedId).then(response => {
                    setEditVal({
                        due_date: response.data['home_work'].due_date,
                        title: response.data['home_work'].title,
                        description: response.data['home_work'].description,
                        photos_attributes: [],
                        videos_attributes: [],
                        attachments_attributes: [],
                        total_marks:response.data['home_work'].total_marks
                    });
                    setShow(true);
                }).catch(error => {
                    setShow(true);
                })
            })()
        }
    };

    const handleEditorChange = (content, editor, formikBag) => {
        formikBag.setFieldValue(`description`, content);
    };

    const onUpdate = async (values, formikBag) => {
        onLoad(true);
        const data = cloneDeep(values);
        const formData = o2formdata(data, {indices: true}, null, `${task}`);
        if (task === "assignment") {
            await assignmentUpdate(subjectId, assignedId, formData).then(response => {
                history.goBack();
                onLoad(false);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Assignment Updated successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        } else if (task === "home_work") {
            await homeworkUpdate(subjectId, assignedId, formData).then(response => {
                history.goBack();
                onLoad(false);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Homework upload successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const onSubmit = async (values, formikBag) => {
        onLoad(true);
        const data = cloneDeep(values);
        const formData = o2formdata(data, {indices: true}, null, `${task}`);
        if (task === "assignment") {
            await assignmentCreate(subjectId, formData).then(response => {
                history.goBack();
                onLoad(false);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Assignment upload successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        } else if (task === "home_work") {
            await homeworkCreate(subjectId, formData).then(response => {
                history.goBack();
                onLoad(false);
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Homework upload successfully'}});
            }).catch(errors => {
                onLoad(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };

    const fileImageVideoUpload = (files, single, type, formikBag) => {
        const existingImage = formikBag?.values?.photos_attributes?.findIndex(photo => photo.default);
        const existingFile = formikBag?.values?.attachments_attributes?.findIndex(file => file.default);
        const existingVideo = formikBag?.values?.videos_attributes?.findIndex(video => video.default);
        if (single) {
            if (existingImage >= 0 || existingFile >= 0 || existingVideo >= 0) {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes[${existingImage}].image`, files[0]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes[${existingVideo}].clip`, files[0]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes[${existingFile}].file`, files[0]);
                }
            } else {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes`, [...formikBag.values.photos_attributes, {
                        image: files[0],
                        default: true
                    }]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes`, [...formikBag.values.videos_attributes, {
                        clip: files[0],
                        default: true
                    }]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes`, [...formikBag.values.attachments_attributes, {
                        file: files[0],
                        default: true
                    }]);
                }
            }
        } else {
            if (type === 'image') {
                formikBag.setFieldValue(`photos_attributes`, [...files.map(file => ({image: file}))]);
            } else if (type === 'video') {
                formikBag.setFieldValue(`videos_attributes`, [...files.map(file => ({clip: file}))]);
            } else if (type === 'file') {
                formikBag.setFieldValue(`attachments_attributes`, [...files.map(file => ({file: file}))]);
            }

            if (existingImage >= 0 || existingFile >= 0 || existingVideo >= 0) {
                if (type === 'image') {
                    formikBag.setFieldValue(`photos_attributes[${existingImage}]`, formikBag.values.photos_attributes[existingImage]);
                } else if (type === 'video') {
                    formikBag.setFieldValue(`videos_attributes[${existingVideo}]`, formikBag.values.videos_attributes[existingVideo]);
                } else if (type === 'file') {
                    formikBag.setFieldValue(`attachments_attributes[${existingFile}]`, formikBag.values.attachments_attributes[existingFile]);
                }

            }
        }
    };

    return (
        <div style={{marginTop:70}}>
            {show ?
                <>
                    <Card>
                        <h5 className="pull-left light-green m-2">Assignment</h5>
                        <Formik initialValues={edit ? editVal : {
                            title: '',
                            due_date: dateFns.addDays(new Date(), 1),
                            photos_attributes: [],
                            description: '',
                            videos_attributes: [],
                            attachments_attributes: [],
                            total_marks:''
                        }}
                                onSubmit={edit ? onUpdate : onSubmit}
                                validationSchema={validationSchema}
                                validateOnBlur={true}
                        >
                            {({isSubmitting, values, touched, handleSubmit, errors, ...formik}) => (
                                <Form>
                                    <Grid container>
                                        <Grid item xs={12} md={8}>
                                            <TextField variant="outlined"
                                                       label={task === 'assignment' ? 'Assignment Title' : 'Homework Title'}
                                                       name="title"
                                                       className="half-width pull-left ml-2 mt-3 mb-3"/>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                disablePast
                                                margin="normal"
                                                className="half-width pull-left ml-2"
                                                format="dd-MM-yyyy pp"
                                                id="date-picker-inline"
                                                label="Due Date"
                                                name="due_date"
                                                inputVariant="outlined"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField variant="outlined"
                                                   label={'Total Marks'}
                                                   name="total_marks"
                                                   className="half-width pull-left ml-2 mt-3 mb-3"/>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} md={11}>
                                            <Card className="m-2">
                                                <Editor
                                                    initialValue={values.description}
                                                    apiKey="ih03pokstaq7qpwlunorzqjmy81fcacgdrl1b2a8mzvq5ued"
                                                    init={{
                                                        height: 320,
                                                        width: '100%',
                                                        menubar: true,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                                    }}
                                                    onEditorChange={(content, editor) => handleEditorChange(content, editor, formik)}
                                                    //outputFormat='text'
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} md={5} className="ml-2 mr-3">
                                            <DropzoneArea
                                                maxFileSize={5000000}
                                                filesLimit={4}
                                                showPreviews={false}
                                                showAlerts={false}
                                                showPreviewsInDropzone={true}
                                                dropzoneClass="dropZone"
                                                dropzoneText="Upload image"
                                                acceptedFiles={['image/*']}
                                                dropzoneParagraphClass="dropZoneDes"
                                                className="dropZone"
                                                onChange={(files) => fileImageVideoUpload(files, false, 'image', formik)}
                                            />
                                        </Grid>
                                        {/*<Grid item xs={12} md={5} className="ml-2 mr-3">*/}
                                        {/*    <DropzoneArea*/}
                                        {/*        maxFileSize={5000000}*/}
                                        {/*        filesLimit={1}*/}
                                        {/*        showPreviews={false}*/}
                                        {/*        showAlerts={false}*/}
                                        {/*        showPreviewsInDropzone={true}*/}
                                        {/*        dropzoneClass="dropZone"*/}
                                        {/*        dropzoneText="Upload video"*/}
                                        {/*        acceptedFiles={['video/*']}*/}
                                        {/*        dropzoneParagraphClass="dropZoneDes"*/}
                                        {/*        className="dropZone"*/}
                                        {/*        onChange={(files) => fileImageVideoUpload(files, false, 'video', formik)}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={12} md={5} className="ml-2 mr-3">
                                            <DropzoneArea
                                                maxFileSize={5000000}
                                                filesLimit={4}
                                                showPreviews={false}
                                                showAlerts={false}
                                                useChipsForPreview={true}
                                                dropzoneClass="dropZone"
                                                dropzoneText="Upload file"
                                                dropzoneParagraphClass="dropZoneDes"
                                                className="dropZone"
                                                onChange={(files) => fileImageVideoUpload(files, false, 'file', formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} className="mt-3 mb-3">
                                            <Button type="submit" variant="contained" disabled={isSubmitting}
                                                    style={{borderRadius: 20, backgroundColor: 'green', width: 200}}
                                                    className="text-white pull-left ml-2 text-capitalize">
                                                {task === 'assignment' && edit ? 'Update' : 'Assign'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        {load && <Loader/>}
                    </Card>
                </> : <div align={'center'}><CircularProgress/></div>}
        </div>
    )
};
