import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {NavLink, useHistory} from "react-router-dom";
import AddIcon from '@material-ui/icons/AddCircle'
import {deleteDepartment, departmentList} from "../../../utils/api";
import SharedDialog from "../../shared/dialog";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";
import {CircularProgress, Typography} from "@material-ui/core";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    },
    year: {
        float: 'left',
        marginLeft: 25,
        fontSize: 25,
    },
}));

export const Department = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);
    const [departments, setDepartments] = useState([]);
    const [load, onLoad] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        (async function fetchData() {
            await fetchDepartment();
        })()
    }, []);

    const fetchDepartment = () => {
        departmentList().then(response => {
            onLoad(true);
            setDepartments([...response.data['departments']]);
        })
    };
    const createDepartment = () => {
        history.push('/create')
    };

    const handleDelete = async (result, event) => {
        setConfirmationOpen(false);
        if (result) {
            await deleteDepartment(id).then(response => {
                fetchDepartment();
                dispatch({type: SNACKBAR_OPEN, payload: {message: 'Department deleted successfully'}});
            }).catch(err => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: err.errors.join(','), severity: 'error', duration: 5000}
                });
            })
        }
    };
    const setDelete = (id) => {
        setId(id);
        setConfirmationOpen(true);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Button variant="contained" color="primary"
                            className="mb-2 background-header text-capitalize pull-right mr-2"
                            onClick={createDepartment}>
                        <AddIcon className="mr-2"/>
                        Add
                    </Button>
                </Grid>
            </Grid>
            {load ?
                <>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader className={classes.table} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>ID</StyledTableCell>
                                    <StyledTableCell>Department Name</StyledTableCell>
                                    <StyledTableCell/>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {departments && departments.length > 0 ? departments.map(lists => (
                                    <StyledTableRow key={lists.id}>
                                        <StyledTableCell>{lists.id}</StyledTableCell>
                                        <StyledTableCell>{lists.name}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={'Edit'}>
                                                <Button className="text-capitalize" variant="contained"
                                                        component={NavLink}
                                                        to={`/create?edit=${lists.id}`}>Edit</Button>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={'Delete'}>
                                                <Button className="text-capitalize" variant="outlined"
                                                        color="secondary"
                                                        onClick={() => setDelete(lists.id)}>Delete</Button>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : (<StyledTableRow>
                                    <StyledTableCell>
                                        <Typography align={"center"} variant={"h6"} component="div">
                                            No data to display
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SharedDialog open={confirmationOpen} handleAction={handleDelete}/>
                </> : <CircularProgress color="secondary"/>}
        </div>
    );
};

export default Department

