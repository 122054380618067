import PrincipalDesk from "../containers/landing/category/principals-desk/principals-desk";
import Announcement from "../containers/landing/category/announcement/announcement";
import HomeCarousel from "../containers/landing/home-carousel/home-carousel";
import OurHistory from "../containers/landing/category/overview/history/our-history";
import Mission from "../containers/landing/category/overview/mission/mission";
import Anthem from "../containers/landing/category/overview/anthem/anthem";
import Board from "../containers/landing/category/overview/board/board";
import {Leader} from "../containers/landing/category/overview/leaders/leaders";
import {Achievement} from "../containers/landing/category/overview/achievements/achievements";

export const RouteUser = [
    {
        primaryText: "Home",
        icon: "home",
        path: '/layout',
        visible: true,
        authorized: [],
        component: HomeCarousel
    },
    {
        primaryText: "Departments",
        icon: "classroom",
        path: '/department',
        visible: true,
        authorized: [],
        component: HomeCarousel
    },
    {
        primaryText: "Faculty",
        icon: "people",
        path: '/faculty',
        visible: true,
        authorized: [],
        component: HomeCarousel
    },
    {
        primaryText: "Contact Us",
        icon: "event",
        path: '/contact',
        visible: true,
        authorized: [],
        component: HomeCarousel
    },
    {
        primaryText: "PrincipalsDesk",
        icon: "assignment",
        path: '/principal-desk',
        visible: false,
        authorized: [''],
        component: PrincipalDesk
    },
    {
        primaryText: "Announcement",
        icon: "assignment",
        path: '/announcement',
        visible: false,
        authorized: [],
        component: Announcement
    },
    {
        primaryText: "OurHistory",
        icon: "assignment",
        path: '/history',
        visible: false,
        authorized: [],
        component: OurHistory
    },
    {
        primaryText: "Mission",
        icon: "assignment",
        path: '/mission',
        visible: false,
        authorized: [],
        component: Mission
    },
    {
        primaryText: "Anthem",
        icon: "classroom",
        path: '/anthem',
        visible: false,
        authorized: [],
        component: Anthem
    },
    {
        primaryText: "Board",
        icon: "classroom",
        path: '/board',
        visible: false,
        authorized: [],
        component: Board
    },
    {
        primaryText: "Leader",
        icon: "classroom",
        path: '/leader',
        visible: false,
        authorized: [],
        component: Leader
    },
    {
        primaryText: "Achievement",
        icon: "classroom",
        path: '/achievements',
        visible: false,
        authorized: [],
        component: Achievement
    },
];
