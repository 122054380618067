import {
    CollapseBtn,
    CollapseIcon,
    Content,
    fixedLayoutPreset,
    Footer,
    Header,
    Root,
    Sidebar,
    SidebarTrigger,
    SidebarTriggerIcon
} from "@mui-treasury/layout";
import {Container, makeStyles, Paper, Toolbar} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NavHeader from "./nav-header";
import NavContent from "./nav-content";
import React, {useContext, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {withStyles} from '@material-ui/core/styles';
import {AppContext, DispatchContext, SNACKBAR_CLOSED} from "../store";
import AlertTitle from "@material-ui/lab/AlertTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import './nav-content.scss'
import {useHistory} from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from '@material-ui/icons/Face';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {logout} from "../utils/api";
import Card from "@material-ui/core/Card";
import './root-layout.scss';

const styles = makeStyles({
    header: {
        fontWeight: 900,
        minWidth: 0,
        fontSize: 18,
        color: 'black',
    },
    content: {
        backgroundColor: '#f9f9f9',
        border: 0,
    },
    container: {
        minHeight: `calc(100vh - 135px)`,
    }
});

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.common.white,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


const RootLayout = (props) => {
    const classes = styles();
    const [data, setData] = useState({
        header: true,
        nav: true,
        content: true,
        footer: true
    });
    const history = useHistory();
    const {snackbar} = useContext(AppContext);
    const dispatch = useContext(DispatchContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        history.push('/profile');
        setAnchorEl(null);
    };

    const logoutUser = () => {
        logout(dispatch);
        history.push('/login');
        setAnchorEl(null);
    };

    const {user: {profile_attributes: {contact_person_attributes, ...profile}}} = useContext(AppContext);

    return (
        <Root config={fixedLayoutPreset}>
            {({sidebarStyles, headerStyles}) => (
                <>
                    <Card className="card-color">
                        <Header className="header-background">
                            <Toolbar>
                                {/*<CollapseBtn*/}
                                {/*    component={IconButton}*/}
                                {/*    className={headerStyles.leftTrigger}>*/}
                                {/*    <CollapseIcon style={{color: 'white'}}/>*/}
                                {/*</CollapseBtn>*/}

                                <div>
                                    <SidebarTrigger className={headerStyles.leftTrigger}>
                                        <SidebarTriggerIcon/>
                                    </SidebarTrigger>
                                </div>
                                <Typography noWrap color={"textSecondary"} className={classes.header}>
                                    e-Sherig
                                </Typography>
                                <Avatar
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginLeft: 'auto',
                                        float: 'right',
                                        backgroundColor: 'white'
                                    }}
                                    className="hand-cursor"
                                    src={(profile.photo_attributes && profile.photo_attributes.small) || 'https://i.pravatar.cc/60'}
                                    onClick={handleMenu}
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                />

                                <StyledMenu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <StyledMenuItem>
                                        <ListItemIcon>
                                            <FaceIcon fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Profile" onClick={handleProfile}/>
                                    </StyledMenuItem>
                                    <StyledMenuItem>
                                        <ListItemIcon>
                                            <ExitToAppIcon fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Log Out" onClick={logoutUser}/>
                                    </StyledMenuItem>
                                </StyledMenu>
                            </Toolbar>
                            {snackbar.open && <Snackbar style={{width: "100%"}}
                                                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                                        key={`top,center`}
                                                        open={snackbar.open}
                                                        onClose={() => dispatch({
                                                            type: SNACKBAR_CLOSED,
                                                            payload: {open: false}
                                                        })}
                                                        autoHideDuration={snackbar.duration}
                            >
                                <Alert severity={snackbar.severity} variant="filled" elevation={6}
                                       onClose={() => dispatch({type: SNACKBAR_CLOSED, payload: {open: false}})}>
                                    {snackbar.message.length > 1 &&
                                    <AlertTitle>{snackbar.severity.toUpperCase()}</AlertTitle>}
                                    {snackbar.message.length > 1 ?
                                        <List dense={true}>
                                            {snackbar.message.map((msg, index) =>
                                                <ListItem key={index}>
                                                    <ListItemIcon style={{minWidth: 5}}>
                                                        <SvgIcon>
                                                            <svg style={{width: '24px', height: '24px'}}
                                                                 viewBox="0 0 24 24">
                                                                <path fill="currentColor"
                                                                      d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"/>
                                                            </svg>
                                                        </SvgIcon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={msg}/>
                                                </ListItem>
                                            )}
                                        </List> : <>{snackbar.message[0]}</>}
                                </Alert>
                            </Snackbar>}
                        </Header>
                    </Card>
                    <Sidebar>
                        {({collapsed}) => (
                            <div>
                                <NavHeader collapsed={collapsed}/>
                                <div className={sidebarStyles.container}>
                                    {data.nav && <NavContent/>}
                                </div>
                                <div>
                                    <CollapseBtn className={sidebarStyles.collapseBtn}>
                                        <CollapseIcon/>
                                    </CollapseBtn>
                                </div>
                            </div>
                        )}
                    </Sidebar>
                    <Content component={Paper} square={true} className="content pb-2 pt-2">
                        <Container maxWidth="xl" className={classes.container}>
                            {props.children}
                        </Container>
                    </Content>
                    <Footer>&copy; Copyright Dragon
                        Coders {new Date().getFullYear()}</Footer>
                </>
            )}
        </Root>);
};

export default RootLayout;