import httpClient from "./apiClient";

//assignment api start
export const assignmentCreate = (id, data) =>
  httpClient(`/section_subjects/${id}/assignments`, 'post', null, data);
export const assignmentList = (id, param) => {
  return httpClient(`/section_subjects/${id}/assignments`, 'get', {...param}).then(response => response)
};
export const assignmentDetail = (subId, assignId) =>
  httpClient(`/section_subjects/${subId}/assignments/${assignId}`, 'get').then(response => {
    return response;
  });
export const assignmentUpdate = (subId, id, data) =>
  httpClient(`/section_subjects/${subId}/assignments/${id}`, 'put', null, data);

export const assignmentSubmission = (id, assignId, data) =>
  httpClient(`/section_subjects/${id}/assignments/${assignId}`, 'put', null, data);

export const deleteAssignment = (subId, id) =>
  httpClient(`/section_subjects/${subId}/assignments/${id}`, 'delete');
//assignment api end

//homework api start
export const homeworkCreate = (id, data) =>
  httpClient(`/section_subjects/${id}/home_works`, 'post', null, data);

export const homeworkList = (id, params) => {
  return httpClient(`/section_subjects/${id}/home_works`, 'get',
    {...params}).then(response => response)
};
export const homeworkDetail = (subId, homew_id) =>
  httpClient(`/section_subjects/${subId}/home_works/${homew_id}`, 'get').then(response => {
    return response;
  });
export const homeworkUpdate = (subId, id, data) =>
  httpClient(`/section_subjects/${subId}/home_works/${id}`, 'put', null, data);

export const homeworkSubmission = (id, assignId, data) =>
  httpClient(`/section_subjects/${id}/home_works/${assignId}`, 'put', null, data);

export const deleteHomework = (subId, id) =>
  httpClient(`/section_subjects/${subId}/home_works/${id}`, 'delete');
//homework api end

//Submission Assignment API Start
export const assignSubmissionCreate = (subId, assignId, data) =>
  httpClient(`/section_subjects/${subId}/assignments/${assignId}/submissions`,
    'post', null, data);

export const assignSubmissionList = (subId, assignId) => {
  return httpClient(`/section_subjects/${subId}/assignments/${assignId}/submissions`,
    'get').then(response => response)
};
export const assignSubmissionDetail = (subId, assignId, id) =>
  httpClient(`/section_subjects/${subId}/assignments/${assignId}/submissions/${id}`, 'get').then(response => {
    return response;
  });
export const assignSubmissionUpdate = (subId, assignId, id, data) => {
  return httpClient(`/section_subjects/${subId}/assignments/${assignId}/submissions/${id}`,
    'put', null, data).then(res => {
    return res;
  })
}
export const deleteAssignSubmission = (subId, assignId, id) =>
  httpClient(`/section_subjects/${subId}/assignments/${assignId}/submissions/${id}`, 'delete');
//Submission Assignment API END

//Submission Home Work API Start
export const homeSubmissionCreate = (subId, homeId, data) =>
  httpClient(`/section_subjects/${subId}/home_works/${homeId}/submissions`,
    'post', null, data);

export const homeSubmissionList = (subId, homeId) => {
  return httpClient(`/section_subjects/${subId}/home_works/${homeId}/submissions`,
    'get').then(response => response)
};

export const homeSubmissionDetail = (subId, homew_id, id) =>
  httpClient(`/section_subjects/${subId}/home_works/${homew_id}/submissions/${id}`,
    'get').then(response => {
    return response;
  });

export const homeSubmissionUpdate = (subId, homeId, id, data) =>
  httpClient(`/section_subjects/${subId}/home_works/${homeId}/submissions/${id}`,
    'put', null, data).then(res =>{
      return res;
  });

export const deleteHomeSubmission = (subId, homeId, id) =>
  httpClient(`/section_subjects/${subId}/home_works/${homeId}/submissions/${id}`,
    'delete');
//Submission Home Work API END
