import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import Grid from "@material-ui/core/Grid";
import './sections.scss'
import {useParams} from "react-router";
import {sectionList, sectionListAdmin} from "../../../utils/api";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {CircularProgress} from "@material-ui/core";

export const SectionSubjects = (props) => {
    const {id: gradeId} = useParams();
    const urlParams = new URLSearchParams(props.location.search);
    const secId = '' + urlParams.get('id');
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [sectionId, setSectionId] = useState(secId);
    const [sectionIndex, setSectionIndex] = useState(0);
    const [sections, setSections] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = () => {
        (async function fetchData() {
            await sectionListAdmin(gradeId).then(response => {
                setLoad(true);
                setSections([...response.data['sections']]);
            })
        })()
    };

    useEffect(() => {
        fetchSubject()
    }, [sectionId]);

    const fetchSubject = () => {
        (async function fetchData() {
            await sectionList(gradeId, sectionId).then(response => {
                setSubjects([...response.data['section']['section_subjects']]);
            })
        })()
    };

    const handleClick = () => {
        console.info(`You clicked ${sections && sections[sectionIndex]}`);
    };

    const handleMenuItemClick = (event, sectId, index) => {
        setSectionIndex(index);
        setSectionId(sectId);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            {load ?
                <>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='split button'
                                         className="pull-left mt-4 ml-2">
                                <Button
                                    onClick={handleClick}>
                                    {sections && sections[sectionIndex] && sections[sectionIndex].name}
                                </Button>
                                <Button
                                    color='primary'
                                    size='small'
                                    aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label='select merge strategy'
                                    aria-haspopup='menu'
                                    onClick={handleToggle}
                                >
                                    <ArrowDropDownIcon/>
                                </Button>
                            </ButtonGroup>
                            <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose}>
                                {sections && sections.map((option, index) => (
                                    <MenuItem
                                        key={option.name}
                                        disabled={index === sectionIndex}
                                        selected={index === sectionIndex}
                                        onClick={event => handleMenuItemClick(event, option.id, index)}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                    <div>
                        <h4 className="mt-4">Subjects</h4>
                    </div>
                    <Grid container>
                        {subjects && subjects.length > 0 ? subjects.map(subject => (
                            <Grid item xs={12} md={4} key={subject.id}>
                                <Card className="assignment-card mt-4 hand-cursor">
                                    <CardHeader
                                        title={subject?.subject?.name}
                                        className="card-head"
                                    />
                                    <CardContent className="card-content">
                                        <h6 style={{textAlign: 'justify'}}>Subject
                                            Teacher: {subject?.teachers?.length > 0 ? subject?.teachers?.[0]?.name : 'No name'}</h6>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="share">
                                            <FolderIcon/>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )) : <div align={'center'}><h5>No data to display</h5></div>}
                    </Grid>
                </> : <div align={'center'}><CircularProgress/></div>}
        </div>
    );
};
