import {createStyles, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(({palette}) =>
    createStyles({
        root: {
            textAlign: 'left',
        },

        Paper: {
            marginTop: '12px',
            padding: '16px 16px 24px 24px'
        },
        media: {
            height: '200px'
        },
        pullRight: {
            float: 'right'
        },
        verMiddle: {
            verticalAlign: 'middle'
        },
        fullWidth: {
            width: '100%'
        },
        title: {
            verticalAlign: 'sub',
            marginRight: '4px',
            marginBottom: 6,
        },
        search: {
            background: 'white'
        },
        card: {}
    }),
);

export default useStyles;