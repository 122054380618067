import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import FileIcon from '@material-ui/icons/Description';
import Collapse from "@material-ui/core/Collapse";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {assignSubmissionUpdate, homeSubmissionDetail, homeSubmissionUpdate} from "../../../../utils/assignApi";
import ReactPlayer from "react-player";
import {CircularProgress} from "@material-ui/core";
import {Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {cloneDeep} from "lodash-es";
import {DispatchContext, SNACKBAR_OPEN} from "../../../../store";
import Moment from "react-moment";
import parse from "html-react-parser";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    content: {
        flexGrow: 1,
    },
    wrapper: {
        position: 'relative',
        paddingTop: '56.25%',
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    images: {
        width: 150,
        height: 150,
        marginTop: 17,
    },
}));

export const StudentHomeworkList = (props) => {
    const dispatch = useContext(DispatchContext);
    const classes = useStyles();
    const history = useHistory();
    const search = new URLSearchParams(props.location.search);
    const dueDate = props?.location?.state?.date;
    const id = search.get('id');
    const [load, setLoad] = useState(false);
    const [homework, setHomework] = useState({});
    const {id: subjectId, homeId: homeworkId} = useParams();

    const initialValues = {
        score: '',
        comments: '',
    };

    useEffect(() => {
        fetchHomework();
    }, []);

    const fetchHomework = () => {
        (async function fetchData() {
            await homeSubmissionDetail(subjectId, homeworkId, id).then(response => {
                setHomework(response.data['submission']);
                setLoad(true);
            })
        })()
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onSubmit = async (values, formikBag) => {
        debugger
        const data = cloneDeep(values);
        await homeSubmissionUpdate(subjectId, homeworkId, id, {submission: {...values}}).then(response => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'correction completed'}});
        }).catch(errors => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: errors.errors.join(','), severity: 'error', duration: 5000}
            });
        })
    };

    return (
        <div style={{marginTop:70}}>
            {load ?
                <>
                    <div>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <h4 className="pull-left">{homework.user?.profile_attributes?.contact_person_attributes?.first_name}
                                    {homework.user?.profile_attributes?.contact_person_attributes?.last_name}</h4>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <h5 className="pull-left">{homework?.title}</h5>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography className="pull-left">Submission Date: <Moment format="LLL" withTitle>{homework?.created_at}</Moment></Typography>
                            </Grid>
                        </Grid>
                        <Card className="card mt-3">
                            <Grid container>
                                <Grid item md={12} xs={12} className="mt-4 mr-2">
                                    <Typography>
                                        {parse(homework?.description || '')}
                                    </Typography>
                                    {/*<Button
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    variant="contained"
                                    style={{
                                        float: 'right',
                                        color: 'blue',
                                        marginTop: 20,
                                        borderRadius: 25,
                                        backgroundColor: '#B5E147',
                                        width: 120,
                                        textTransform: "capitalize"
                                    }}
                                >
                                    Read More
                                </Button>*/}
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit
                                              style={{textAlign: 'justify'}}>
                                        <Typography style={{textAlign: 'justify'}} className="mb-4 p-3">
                                            Big tour operators, considering the volume of the guests they entertain,
                                            they have access to hotel rooms
                                            at half the price of what small and medium tour operators have to pay.
                                            Why can’t small and medium
                                            tour operators come together under one roof and negotiate with hotels to
                                            get a better rate from
                                            hotels? Bookyul is here to represent all interested tour operators and
                                            get better discounts from hotels.
                                            The best hotel deal is guaranteed with Bookyul. <br/> <br/>
                                            It takes a few days to get room availability information from hotels and
                                            this is not good for tour
                                            operators who like to respond to queries at the earliest. At times, by
                                            the time a tour operator can get
                                            Most hotels close their reservation office after 17 00 hours and in the
                                        </Typography>
                                    </Collapse>
                                </Grid>
                                {homework.attachments_attributes && homework.attachments_attributes.map(file => (
                                    <Grid item xs={12} md={12} className="mt-3" key={file.id}>
                                        Documents:
                                        <a href={file.file_url} target="-_blank" rel="noopener noreferrer">
                                            {file?.filename}
                                        </a>
                                    </Grid>
                                ))}
                                {homework.photos_attributes && homework.photos_attributes.map(image => (
                                    <Grid item xs={12} md={3} key={image.id}>
                                        <a href={image.original} target="_blank" rel="noopener noreferrer">
                                            <img src={image.large} alt="" className={classes.images}/>
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container>
                                {homework.videos_attributes && homework.videos_attributes.map(video => (
                                    <Grid item xs={12} md={6} className="p-3" key={video.id}>
                                        <div className={classes.wrapper} data-vimeo-url={video.clip_url}>
                                            <ReactPlayer
                                                className={classes.player}
                                                url={video.clip_url}
                                                controls={true}
                                                youtubeConfig={{playerVars: {showinfo: 1}}}
                                                //playing
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                            {new Date(dueDate) <= new Date() &&
                            <Formik initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validateOnBlur={true}
                            >
                                {({isSubmitting, touched, handleSubmit, errors, ...formik}) => (
                                  <Form>
                                      <Grid item xs={12} sm={4} lg={6} className=" grades mb-3 ml-3 mr-3">
                                          <TextField id="outlined-basic" label="Total Score"
                                                     name='score'
                                                     style={{width: 300}}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={4} lg={6} className=" grades mb-3 ml-3 mr-3">
                                          <TextField id="outlined-basic" label="Remarks" variant="outlined"
                                                     multiline
                                                     rows="4"
                                                     name='comments'
                                                     style={{width: 300}}
                                          />
                                      </Grid>
                                      <Grid item md={4} xs={12} className="mb-3 grades ml-3">
                                          <Button
                                            variant="outlined"
                                            type="submit"
                                            className="background-header"
                                            disabled={isSubmitting}
                                          >
                                              Submit
                                          </Button>
                                      </Grid>
                                  </Form>
                                )}
                            </Formik>}
                        </Card>
                    </div>
                </> : <div align={'center'}><CircularProgress/></div>}
        </div>
    )
}


