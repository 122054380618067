import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from 'formik-material-ui';
import {Form, Formik} from "formik";
import Button from "@material-ui/core/Button";
import {assignSubjectUpdate, gradeDetail, subjectList} from "../../../utils/api";
import * as Yup from 'yup';
import {useHistory} from "react-router-dom";
import {DispatchContext, SNACKBAR_OPEN} from "../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ListItemText} from "@material-ui/core";

const validationSchema = Yup.object().shape({
    section_ids: Yup.string().required('Field is required'),
    subject_ids: Yup.string().required('Field is required')
});
export const AssignSubject = (props) => {
    const dispatch = useContext(DispatchContext);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const [options, setOptions] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [grade, setGrade] = useState({});
    const [searched, setSearch] = useState('');
    const history = useHistory();

    useEffect(() => {
        fetchGradeDetail();
        fetchSubjectData();
    }, [searched]);

    const fetchGradeDetail = () => {
        (async function fetchData() {
            await gradeDetail(id).then(response => {
                setGrade(response.data.grade);
                setOptions([...response.data.grade.sections_attributes])
            }).catch(errors => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors, severity: 'error', duration: 5000}
                })
            });
        })()
    };
    const fetchSubjectData = () => {
        (async function fetchSubject() {
            await subjectList({qu: searched, per_page: 30}).then(response => {
                setSubjects([...response.data.subjects]);
            }).catch(errors => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    payload: {message: errors, severity: 'error', duration: 5000}
                })
            });
        })()
    };

    const handleOptionChange = (e, value, formikBag) => {
        formikBag.setFieldValue(`section_ids`, (value && value.id) || '', false)
    };

    const handleSubjectAssign = (e, values, formikBag) => {
        values.map((subject, index) => (
            formikBag.setFieldValue(`subject_ids[${index}]`, (values && values[index].id) || '', false)
        ));
    };

    const handleChangeSubject = (event) => {
        setSearch(event.target.value);
    };

    const assignSubject = async (section) => {
        await assignSubjectUpdate(id, {section}).then(res => {
            dispatch({type: SNACKBAR_OPEN, payload: {message: 'Subject Assign success fully'}});
            history.push('/grade');
        }).catch(errors => {
            dispatch({
                type: SNACKBAR_OPEN,
                payload: {message: errors.errors.join(', '), severity: 'error', duration: 5000}
            });
        })
    };
    return (
        <div style={{marginTop: 70}}>
            <h3 style={{fontSize: 30, textAlign: 'left', marginTop: 50, marginBottom: 50}}>
                Assign Subject To Class {grade && grade['name']}
            </h3>
            <Formik initialValues={{subject_ids: [], section_ids: []}}
                    onSubmit={assignSubject}
                    validationSchema={validationSchema}
                    validateOnBlur={true}>
                {({isSubmitting, touched, errors, ...formik}) => (
                    <Form style={{marginTop: 50}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2}>
                                <Autocomplete
                                    id="checkboxes-tags-demo"
                                    options={options}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={({name}) =>
                                        (<ListItemText
                                            primary={name}
                                        />)}
                                    onChange={(e, value) => handleOptionChange(e, value, formik)}
                                    renderInput={(params) => (
                                        <TextField
                                            //onChange={handleChange}
                                            {...params}
                                            name="section_ids"
                                            variant="outlined"
                                            label="Select Section"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    autoComplete={true}
                                    options={subjects}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option => option.name)}
                                    renderOption={({name}) =>
                                        (<ListItemText
                                            primary={name}
                                        />)}
                                    onInputChange={handleChangeSubject}
                                    onChange={(e, value) => handleSubjectAssign(e, value, formik)}
                                    renderInput={(params) => (
                                        <TextField
                                            onChange={(event) => handleChangeSubject(event)}
                                            {...params}
                                            name="subject_ids"
                                            variant="outlined"
                                            label="Select Subject"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Button variant="contained" color='primary' type="submit"
                                        disabled={isSubmitting}
                                        className="mt-2 background-header"
                                        style={{float: "left"}}
                                >
                                    Assign
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
