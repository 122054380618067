import {
    PEOPLE_LIST,
    PEOPLE_LIST_FAILURE,
    PEOPLE_LIST_FETCHING,
    PEOPLE_LIST_SUCCESS,
    PERSON_UPDATE
} from "./action-types";

export const peopleReducer = (state, action) => {
    switch (action.type) {
        case 'PEOPLE_ADD':
            return {...state, ...action.payload};
        case 'PEOPLE_DELETE':
            return [];
        case PEOPLE_LIST:
            return {...action.payload};

        case PERSON_UPDATE:
            return state.map((user) => {
                if (user.id === action.payload.id) {
                    return ({...user, ...action.payload})
                }
                return user;
            });
        default:
            return state;
    }
};


export const peopleStatusReducer = (state, action) => {
    switch (action.type) {
        case PEOPLE_LIST_SUCCESS:
            return PEOPLE_LIST_SUCCESS;
        case PEOPLE_LIST_FAILURE:
            return PEOPLE_LIST_FAILURE;
        case PEOPLE_LIST_FETCHING:
            return PEOPLE_LIST_FETCHING;
        default:
            return state;
    }
};

export const defaultPeople = [];