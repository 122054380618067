import axios from "axios";
import config from "./config";
import {history} from "./history";
import {SNACKBAR_OPEN} from "../store";

axios.defaults.baseURL = config.API_URL;
axios.defaults.timeout = 0;

export const apiClient = {axios: axios.create({baseURL: config.API_URL, timeout: 0}), dispatch: null};
// Add a request interceptor
apiClient.axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = JSON.parse(localStorage.getItem('token'));
    if (token != null) {
        config.headers.Authorization = token;
    }

    /*if (/\bsign_in\b/.test(config.url)) {
        config.withCredentials = true;
    }*/
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

apiClient.axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (!error.response) {
        apiClient.dispatch({
            type: SNACKBAR_OPEN,
            payload: {severity: 'error', message: error.message || 'We are facing some issue.'}
        });
        return Promise.reject(error.message);
    }
    if (401 === error.response.status) {
        sessionStorage.clear();
        localStorage.clear();
        apiClient.dispatch({type: 'LOGOUT'});
        history.push('/login');
    } else {
        return Promise.reject(error.response && error.response.data);
    }
    return Promise.reject(error.response && error.response.data);
});

export default (url, method, params, data) => apiClient.axios.request({url, method, params, data});