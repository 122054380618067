import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import {useHistory} from "react-router-dom";
import {assignmentList} from "../../../../utils/assignApi";
import {CircularProgress, Typography} from "@material-ui/core";
import {useParams} from "react-router";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        minWidth: 700,
    },
    Button: {
        backgroundColor: '$success-color'
    }
}));

export default function AssignmentPost() {
    const classes = useStyles();
    const history = useHistory();
    const [load, onLoad] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const {id: subjectId} = useParams();

    useEffect(() => {
        fetchAssignments();
    }, []);

    const fetchAssignments = () => {
        (async function fetchData() {
            await assignmentList(subjectId, {filter: 'closed'}).then(response => {
                onLoad(true);
                setAssignments([...response.data['assignments']]);
            })
        })()
    };

    const viewDetail = (id, date) => {
        history.push(`/assigned-assignment/${subjectId}?assignId=${id}`, {date: date})
    };

    return (
        <div>
            <h5 className="mt-3 pull-left">List of Assignments</h5>
            <TableContainer className={classes.root}>
                <Table stickyHeader className={classes.table} aria-label="sticky table">
                    <TableHead>
                        {load ?
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Due Date</StyledTableCell>
                            </TableRow>
                            : <TableRow><StyledTableCell align={"center"}>
                                <CircularProgress/></StyledTableCell></TableRow>}
                    </TableHead>
                    <TableBody>
                        {assignments && assignments.length > 0 ? assignments.map((lists, index) => (
                            <StyledTableRow hover className="hand-cursor" onClick={() => viewDetail(lists.id, lists.due_date)}
                                            key={lists.id}>
                                <StyledTableCell>{index + 1}</StyledTableCell>
                                <StyledTableCell>
                                    {lists.title}
                                </StyledTableCell>
                                <StyledTableCell>{new Date(lists.due_date).toDateString()}</StyledTableCell>
                            </StyledTableRow>
                        )) : <StyledTableRow>
                            <StyledTableCell>
                                <Typography align={"center"} variant={"h6"} component={"div"}>
                                    No data to display
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

