import {StaffForm} from "./staff_form";
import {StudentForm} from "./student_form";
import {ParentForm} from "./parent_form";
import * as Yup from "yup";
import React, {useContext, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import classes from '../../styles';
import useStyles from '../../styles';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Select, TextField} from 'formik-material-ui';
import {FieldArray, useFormikContext} from "formik";
import uuid from 'uuid';
import {AppContext} from "../../../../store";

export const StepOne = ({role}) => {
    const formik = useFormikContext();
    const {roles} = useContext(AppContext);

    useEffect(() => {
        formik.setFieldValue('username', uuid.v4());
    }, []);

    return (<>
        <div>
            {/*This section is for Adding Staff*/
                ['Staff', 'Teacher', 'Admin', 'Principal'].includes(role) &&
                (
                    <StaffForm roles={roles}/>
                )}
            {/*End of adding staff*/}

            {/*This section is for adding student*/
                role === 'Student' &&
                (<StudentForm/>)
            }
            {/*End of adding student*/}

            {/*This section is for adding parent*/
                role === 'Parent' &&
                (<ParentForm/>)
            }
            {/*End of adding parent*/}
        </div>
    </>);
};
StepOne.initialValues = {
    password: '',
    password_confirmation: '',
    username: '',
    profile_attributes: {
        department_id: '',
        contact_person_attributes: {
            first_name: '',
            last_name: '',
            cid: '',
            phone: '',
            designation: '',
            salutation: 'mr'
        }
    }
};
StepOne.validationSchema = () => Yup.object().shape({
    password: Yup.string().required("This field is required").min(6),
    password_confirmation: Yup.string().required("This field is required").when("password", {
        is: val => (!!(val && val.length > 0)),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password Mismatch"
        )
    }),
    profile_attributes: Yup.object().shape({
        //department_id: Yup.number().min(0, "Select a department"),
        contact_person_attributes: Yup.object().shape({
            first_name: Yup.string().required('Enter first name'),
            last_name: Yup.string().required('Enter last name'),
            phone: Yup.string().required('Enter a phone no.'),
            salutation: Yup.string().required('Select a salutation'),
        })
    })
});
StepOne.label = 'Personal Information';

export const StepTwo = ({role, ...rest}) => {

    const classes = useStyles();
    const inputLabel = React.useRef(null);

    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        inputLabel && inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const {values} = useFormikContext();


    const addQualification = (arrayHelpers => {
        arrayHelpers.push({
            "uid": uuid.v4(),
            "grade": "",
            "mark": "",
            "graduation_year": "",
            "institute": "",
            "_destroy": 0
        });
    });

    const deleteQualification = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };


    const qualifications = [{value: 'x', label: 'X'}, {label: 'XII', value: 'xii'},
        {label: "Certificate", value: 'certificate'}, {label: 'Diploma', value: 'diploma'},
        {label: 'Degree', value: 'degree'},
        {value: 'master', label: 'Masters'}, {label: "Phd.", value: 'phd'}];


    return (<>
        <div>
            {/*This section is for Adding Staff  and parent/guardian*/
                role !== 'Student' && role !== 'Parent' && <>
                    <h4 className={classes.marginTopBottom}>Educational Details</h4><br/>
                    <FieldArray name="qualifications_attributes"
                                render={(arrayHelpers) => (
                                    <div>
                                        {
                                            values.qualifications_attributes.map((qualification, index) => (
                                                <Grid container direction="row" spacing={3} key={index}>
                                                    <Grid item xs={12} sm={2}>
                                                        {/* <TextField id="grade" label="Qualification" variant="outlined"
                                                           className={classes.fullWidth}
                                                           name={`qualifications_attributes[${index}].grade`}/>*/}
                                                        <FormControl variant="outlined" className={classes.fullWidth}>
                                                            <InputLabel ref={inputLabel}>Qualification</InputLabel>
                                                            <Select labelWidth={labelWidth}
                                                                    name={`qualifications_attributes[${index}].grade`}>

                                                                {qualifications.map((qu, index) =>
                                                                    <MenuItem key={index}
                                                                              value={qu.value}>{qu.label}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <TextField id="mark" label="Marks/Percentage" variant="outlined"
                                                                   className={classes.fullWidth}
                                                                   name={`qualifications_attributes[${index}].mark`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField id="year" label="Graduation Year" variant="outlined"
                                                                   className={classes.fullWidth}
                                                                   name={`qualifications_attributes[${index}].graduation_year`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField id="institute" label="Institute" variant="outlined"
                                                                   className={classes.fullWidth}
                                                                   name={`qualifications_attributes[${index}].institute`}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={1}>
                                                        {1 &&
                                                        <Button aria-label='delete' color='secondary' variant="outlined"
                                                                className="mt-2"
                                                                onClick={() => deleteQualification(index, arrayHelpers)}>
                                                            <DeleteIcon/>Remove
                                                        </Button>}
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        <Button variant='outlined' color='primary' className={classes.addQualification}
                                                onClick={() => addQualification(arrayHelpers)}>
                                            <AddIcon/> Add more
                                        </Button>
                                    </div>
                                )}>
                    </FieldArray>
                </>
            }
            <h5>Emergency Contact Details</h5>
            <Grid container direction="row" spacing={3} className="mt-4">
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel ref={inputLabel}>Salutation</InputLabel>
                        <Select labelWidth={labelWidth} name="emergency_contact_attributes.salutation">
                            <MenuItem value="mr">Mr.</MenuItem>
                            <MenuItem value="ms">Ms.</MenuItem>
                            <MenuItem value="mrs">Mrs.</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField id="ec_first_name" label="First Name" variant="outlined"
                               className={classes.fullWidth}
                               name="emergency_contact_attributes.first_name"/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField id="ec_last_name" label="Last Name" variant="outlined"
                               className={classes.fullWidth}
                               name="emergency_contact_attributes.last_name"/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField id="ec_phone" label="Phone Number" variant="outlined" type="number"
                               className={classes.fullWidth}
                               name="emergency_contact_attributes.phone"/>
                </Grid>
            </Grid>
        </div>
    </>);
};

StepTwo.label = 'Other Details';

StepTwo.initialValues = {
    guardian_type: '',
    parent_id: '',
    parent: '',
    "qualifications_attributes": [
        {
            "default": true,
            "uid": uuid.v4(),
            "grade": "",
            "mark": "",
            "year": "",
            "institute": "",
            "_destroy": 0
        }
    ],
    "emergency_contact_attributes": {
        "salutation": "mr",
        "first_name": "",
        "last_name": "",
        "phone": "",
    }
};

StepTwo.validationSchema = ({roles}) => Yup.object().shape({
    /*parent_id: Yup.number().when('role_ids', (role_ids, schema) => {
        if (role_ids.length && roles.filter(role => role.name === 'Student').map(role => role.id).includes(parseInt(role_ids[0]))) {
            return schema.required('Select a parent');
        } else {
            return schema
        }
    })*/
    emergency_contact_attributes: Yup.object().shape({
        first_name: Yup.string().required('Enter first name'),
        last_name: Yup.string().required('Enter last name'),
        phone: Yup.string().required('Enter a phone no.'),
    })
});

export const AddressFields = ({type}) => {
    const inputLabel = React.useRef(null);
    const classes = useStyles();

    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        inputLabel && inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const dzongkhags = ['Bumthang', 'Chukha', 'Dagana', 'Gasa', 'Haa',
        'Lhuentse', 'Mongar', 'Paro',
        'Pema Gatshel', 'Punakha', 'Samtse', 'Sarpang', 'Samdrup Jongkhar',
        'Trashigang', 'Thimphu', 'Trongsa', 'Tsirang', 'Trashiyangtse',
        'Wangduephodrang', 'Zhemgang'];

    return (<Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={4}>
            <TextField id="outlined-basic" label="Village" variant="outlined"
                       className={classes.fullWidth}
                       name={`${type}_address_attributes.village`}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField id="outlined-basic" label="Gewog" variant="outlined"
                       className={classes.fullWidth}
                       name={`${type}_address_attributes.gewog`}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField id="outlined-basic" label="Dungkhag" variant="outlined"
                       className={classes.fullWidth}
                       name={`${type}_address_attributes.dungkhag`}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" className={classes.fullWidth}>
                <InputLabel ref={inputLabel}>Dzongkhag</InputLabel>
                <Select labelWidth={labelWidth} name={`${type}_address_attributes.dzongkhag`}>
                    {dzongkhags.map((dzo, index) => <MenuItem key={index} value={dzo}>{dzo}</MenuItem>)}
                </Select>
            </FormControl>
        </Grid>
    </Grid>);
};


export const StepThree = () => {

    const {values, handleChange} = useFormikContext();
    useEffect(() => {
        copyAddress();
    }, [handleChange]);
    const copyAddress = () => {
        if (values.present_address_same) {
            values['present_address_attributes'] = values['permanent_address_attributes']
        }
    };
    return (
        <div>
            {/*This section is for Adding Staff, student and Parent/Guardian*/}
            <h4 className={classes.marginTopBottom}>Permanent Address</h4><br/>
            <AddressFields type="permanent"/>
            <h4 className="mt-2">Present Address</h4>
            <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="present_address_same" defaultChecked={false}
                                           onChange={handleChange}/>}
                        label="Same as above"/>
                </FormGroup>
            </FormControl>
            {!values.present_address_same && (<AddressFields type="present"/>)}
            {/*End of adding staff*/}
        </div>)
};

StepThree.label = "Address";
StepThree.initialValues = {
    "permanent_address_attributes": {
        "dzongkhag": "",
        "dungkhag": "",
        "gewog": "",
        "village": ""
    },
    "present_address_attributes": {
        "dzongkhag": "",
        "dungkhag": "",
        "gewog": "",
        "village": ""
    },
    present_address_same: false
};

StepThree.validationSchema = () => Yup.object().shape({});

export const steps = [StepOne, StepTwo, StepThree];