import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer'
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {assignSubmissionList} from "../../../../../utils/assignApi";
import {CircularProgress, Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";

const StyledTableCell = withStyles(theme => ({
    head: {
        fontSize: 17,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    table: {
        width: '100%'
    },
    button: {
        backgroundColor: '#D5F934',
        textTransform: 'capitalize',
        borderRadius: 25,
    }
}));

export default function ClosedAssignment(props) {
    const classes = useStyles();
    const history = useHistory();
    const search = new URLSearchParams(props.location.search);
    const dueDate = props?.location?.state?.date;
    const assignId = search.get('assignId');
    const [load, setLoad] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const {id: subjectId} = useParams();

    useEffect(() => {
        fetchAssignments();
    }, []);

    const fetchAssignments = () => {
        (async function fetchData() {
            await assignSubmissionList(subjectId, assignId).then(response => {
                setAssignments([...response.data['submissions']]);
                setLoad(true);
            })
        })()
    };


    const viewWork = (id) => {
        history.push(`/student-work/${subjectId}/${assignId}?userId=${id}`, {date: dueDate})
    };

    return (
        <div style={{marginTop:70}}>
            <Grid item lg={2} style={{marginTop: 30}} className="ml-0">
                <Button>
                    <Tooltip title="Back">
                        <ArrowBackIcon onClick={() => history.goBack()}/>
                    </Tooltip>
                </Button>
            </Grid>
            {load ?
                <TableContainer className={classes.root}>
                    <Table stickyHeader className={classes.table} aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignments && assignments.length > 0 ? assignments.map((lists) => (
                                <StyledTableRow hover className="hand-cursor" key={lists.id}>
                                    <StyledTableCell>{lists.user?.profile_attributes?.contact_person_attributes?.first_name}
                                        {lists.user?.profile_attributes?.contact_person_attributes?.last_name}</StyledTableCell>
                                    <StyledTableCell>Submitted</StyledTableCell>
                                    <StyledTableCell>
                                        <Button variant="contained" className={classes.button}
                                                onClick={() => viewWork(lists.id)}>View</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : <StyledTableRow>
                                <StyledTableCell>
                                    <Typography align={"center"} variant={"h6"} component={"div"}>
                                        No data to display
                                    </Typography>
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer> : <div align={'center'}><CircularProgress/></div>}
            {/*<Pagination count={5} variant="outlined" shape="rounded" className="mt-5"/>*/}
        </div>
    )
}
